import {connect} from "react-redux";
import React, {useRef, useState} from "react";
import {TextareaStyled} from "../Styles/CommonCSSProperties";
import _ from "lodash";

let ContractOtherSettingsSection: any = function ContractInstructionsSection(props: any) {
    const settings = props.contract.settings ?? {}
    const instructions = settings.instructions ?? {}

    function instructionsView() {
        return <>
            <div style={{marginTop: "15px"}}>
                <div className="commonLabel">
                    Подсказка при подписании.
                </div>
                <div className="commonSubtitle" style={{marginTop: "5px"}}>
                    Подсказку можно отправлять вместе с ссылкой, чтобы вашему клиенту было проще понять, что нужно сделать с этой ссылкой
                </div>
                <TextareaStyled
                    value={instructions.pre_sign}
                    placeholder="Подсказка при подписании"
                    style={{
                        marginTop: "10px",
                    }}
                    onChange={({target: {value}}: any) => {
                        let updatedSettings = {...settings}
                        _.set(updatedSettings, 'instructions.pre_sign', value)
                        props.updateContractSettings(updatedSettings)
                    }}
                />
            </div>
            <div style={{marginTop: "15px"}}>
                <div className="commonLabel">
                    Инструкции после подписания договора.
                </div>
                <div className="commonSubtitle" style={{marginTop: "5px"}}>
                    Эта инструкция отошлется письмом на электронную почту вашего клиента
                </div>
                <TextareaStyled
                    value={instructions.post_sign_title ?? ""}
                    placeholder="Заголовок письма с инструкцией"
                    style={{
                        marginTop: "10px",
                    }}
                    onChange={({target: {value}}: any) => {
                        let updatedSettings = {...settings}
                        _.set(updatedSettings, 'instructions.post_sign_title', value)
                        props.updateContractSettings(updatedSettings)
                    }}
                />
                <TextareaStyled
                    value={instructions.post_sign}
                    placeholder="Инструкции после подписания"
                    style={{
                        marginTop: "10px",
                    }}
                    onChange={({target: {value}}: any) => {
                        let updatedSettings = {...settings}
                        _.set(updatedSettings, 'instructions.post_sign', value)
                        props.updateContractSettings(updatedSettings)
                    }}
                />
            </div>
        </>
    }

    function signerTitlesView() {
        return <>
            <div style={{marginTop: "15px"}}>
                <div className="commonLabel">
                    Как называть стороны договора в штампе электронной подписи?
                </div>
                <div className="commonSubtitle" style={{marginTop: "5px"}}>
                    По умолчанию стороны называются Исполнитель и Клиент, но вы можете задать свои названия
                </div>
                <div className="commonLabel" style={{marginTop: "15px"}}>
                    Подписант 1
                </div>
                <TextareaStyled
                    value={settings.first_signer_title}
                    placeholder='В родительном падеже, например "Исполнителя"'
                    style={{
                        marginTop: "10px",
                    }}
                    onChange={({target: {value}}: any) => {
                        props.updateContractSettings({...settings, first_signer_title: value})
                    }}
                />
                <div className="commonLabel" style={{marginTop: "15px"}}>
                    Подписант 2
                </div>
                <TextareaStyled
                    value={settings.target_user_title}
                    placeholder='В родительном падеже, например "Клиента"'
                    style={{
                        marginTop: "10px",
                    }}
                    onChange={({target: {value}}: any) => {
                        props.updateContractSettings({...settings, target_user_title: value})
                    }}
                />
            </div>
        </>
    }

    return <>
        <hr className={"newHR"}/>

        {
            instructionsView()
        }

        <hr className={"newHR"}/>

        {
            signerTitlesView()
        }
    </>
}

ContractOtherSettingsSection = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ContractOtherSettingsSection)

export default ContractOtherSettingsSection