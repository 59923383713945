import React, {useEffect} from 'react'

import { CircularProgress } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import classnames from "classnames"

import "styles/components/popup.sass"
import { httpClient } from 'funcs'
import { useRoute } from 'react-router5'

import StoreProps from 'utils/types/storeProps';

interface Props extends StoreProps {
    setPaymentNotification: Function,
    transactionId: string,
    success: any,
}

let PaymentStatus: any = ({setPaymentNotification, transactionId, success}: Props) => {
    const $router = useRoute();

    const [confirmed, setConfirmed] = React.useState<string>("")
    const [balance, setBalance] = React.useState<any>({})

    useEffect(()=> {
        httpClient.get(`get-transaction-info/${transactionId}`).then((res)=> {
            setConfirmed(res.data.status);
            setBalance(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return <div className={classnames('popup')} style={{}} >
        <div className="wrapper">
            <div className="container center" style={{padding: "15px"}}>
                <div style={{marginTop: '30px'}}>
                    {
                        confirmed == "CONFIRMED" &&
                        <CheckCircleOutlineIcon fontSize="large" sx={{color: "#4EBA28", alignSelf: "center", fontSize: 70, margin: '2px'}}/>
                    }
                    {
                        success === "false" &&
                        <ErrorIcon sx={{ fontSize: 70, color: "#E22631", alignSelf: "center" }}/>
                    }
                </div>

                <div className="H3_21" style={{marginTop: '30px'}}>
                    {
                        success === "true" ? "Оплачено" : "Не получилось оплатить"
                    }
                </div>

                {
                    success === "true" &&
                    <div>
                        <div className='confirmation'>
                            {
                                confirmed !== "CONFIRMED" &&
                                <>
                                    <div className="Body_15"> Идёт подтверждение, подождите... </div>
                                    <CircularProgress sx={{color: "#0E1F4D", alignSelf: "center", fontSize: 70}} />
                                </>
                            }

                            {
                                confirmed == "CONFIRMED" && balance &&
                                <div>
                                    <div className="Body_15"> Ваш баланс: {balance.balance} договоров</div>
                                    <hr className="newHR"/>
                                    <div>
                                        <div className="commonLabel">Информация о покупке</div>
                                        <div className="commonSubtitle" style={{marginTop: "15px"}}>Цена: {balance.price/100} рублей</div>

                                        <div className="commonSubtitle">Вы приобрели: {balance.added_amount} договоров</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }

                {
                    success === "false" &&
                    <div className='confirmation'>
                        <div className="Body_15">Не удалось произвести оплату. Попробуйте еще раз или поменяйте способ оплаты</div>
                    </div>

                }

                <button
                    className="new withTitle _wa"
                    style={{marginTop: "30px"}}
                    onClick={() => {setPaymentNotification(false); $router.router.navigate(`profile`)}}
                >
                    Вернуться в профиль
                </button>
            </div>


        </div>
    </div>
}


export default PaymentStatus


