import React, {ReactElement, useEffect, useRef, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {Autocomplete, CircularProgress, useMediaQuery} from "@mui/material";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Popup from "../../../components/Popup";
import notifyUser, {notifyAboutError} from "../../../methods/notifyUser";
import {httpClient, nError} from "../../../funcs";
import {connect} from "react-redux";
import {commonSelect, TextareaStyled, TextFieldStyled} from "../../Styles/CommonCSSProperties";
import {ReactComponent as Copy} from "../../Icons/size24/Copy.svg";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";

type Props = {
    serviceName: string,
    helpView: ReactElement,
    close: Function,
    realtyConfig: any,
    $store: any,
    $commitToStore: Function,
}

enum IntegrationType {
    noIntegration,
    useOneTemplate,
    useManyTemplate
}

let IncomingWebhookIntegrationPopup: any = function IntegrationPopup(props: Props) {
    const [integrationType, setIntegrationType] = useState<number>(
        props.realtyConfig?.integration_type ?? 0
    )
    const [oneTemplate, setOneTemplate] = useState<any>(props.realtyConfig?.one_template ?? null)
    const [manyTemplates, setManyTemplates] = useState<Map<string, number>>(makeManyTemplatesIds())
    const [templates, setTemplates] = useState<any[]>([])
    const [isEditing, setIsEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        save()
    }, [integrationType, oneTemplate, manyTemplates])

    function makeManyTemplatesIds() {
        let dict = new Map<string, number>()
        if (props.realtyConfig?.many_templates) {
            for (const id of props.realtyConfig.many_templates) {
                dict.set(id.id, id.realty_id)
            }
        }
        return dict
    }

    function onCopy() {
        notifyUser(props, `Ссылка скопирована. Теперь вставьте ее в настройках ${props.serviceName}`);
    }

    useEffect(
        () => {
            loadTemplate()
        },
        []
    )

    async function loadTemplate() {
        const {data} = await httpClient.get(`/templates`)
        setTemplates(data)
    }

    function makeURLView() {
        return <div className="titleContainer" style={{marginTop: "15px", gap: "20px"}}>
            <div className="commonLabel" style={{width: "100%"}}>
                <TextareaStyled
                    style={{
                        marginTop: "10px",
                    }}
                    readOnly
                    value={link()}
                />
                <CopyToClipboard
                    onCopy={() => {
                        onCopy()
                        save()
                    }}
                    text={link()}
                >
                    <button className="new withTitle _wa" style={{marginTop: "15px"}}>
                        <div className="buttonsContainer">
                            <Copy className="icon" style={{fill: "white"}}/>
                            <span>Скопировать строку</span>
                        </div>
                    </button>
                </CopyToClipboard>
            </div>
        </div>
    }

    function makeOneTemplateSelector() {
        return <FormControl sx={{width: "100%", marginTop: "15px"}}>
            <Autocomplete
                freeSolo
                handleHomeEndKeys={true}
                defaultValue={oneTemplate?.title ?? ""}
                inputValue={oneTemplate?.title ?? ""}
                className="classicStyleMuiSelect"
                options={
                    templates.map((template) => {
                        return {id: template._id, title: template.name + '. ' + (template.comment ?? '')}
                    })
                }
                getOptionLabel={(option) => { // @ts-ignore
                    return option?.title ?? ""
                }}
                sx={commonSelect()}
                onFocus={({target}) => {
                    setIsEditing(true)
                }}
                onBlur={({target}) => {
                    setIsEditing(false)
                }}
                onChange={(e, value) => {
                    // @ts-ignore
                    setOneTemplate(value)
                }}
                renderInput={(params) => (
                    <TextFieldStyled
                        inputRef={input}
                        {...params}
                        sx={{
                            border: isEditing ? "1px solid #75809E !important" : "none",
                            background: isEditing ? "white !important" : "#EAECF3",
                            padding: isEditing ? "2.5px !important" : "3.5px",
                        }}
                        variant="outlined"
                        placeholder={"Выберите шаблон"}
                    />
                )}
            />
        </FormControl>
    }

    function link() {
        return `https://api.doki.online/webhook/${props.$store.userSession.realty_api_key}`
    }

    function makeManyTemplatesListView() {
        return <div>
            {
                templates.map((template) => {
                    return <div style={{marginTop: "15px"}}>
                        <div className="buttonsContainer commonLabel">
                            {template.name + '. ' + (template.comment ?? '')}
                        </div>
                        <TextareaStyled
                            defaultValue={manyTemplates.get(template._id)}
                            placeholder={`Введите номер лота из ${props.serviceName}`}
                            style={{
                                marginTop: "10px"
                            }}
                            onChange={({target: {value}}: any) => {
                                manyTemplates.set(template._id, value)
                            }}
                        />
                    </div>
                })
            }
        </div>
    }

    async function save(close: boolean = false) {
        if (integrationType == 1 && !oneTemplate) {
            notifyUser(props, "Выберите шаблон", "Укажите шаблон, на основе которого будут создаваться договоры")
            return
        }
        try {
            await httpClient.patch("/user/self", {
                'realty_integration': {
                    'integration_type': integrationType,
                    'one_template': oneTemplate,
                    'many_templates': Array.from(manyTemplates.entries()).map((pairs) => {
                        return {'id': pairs[0], "realty_id": pairs[1]}
                    }),
                }
            })

            setLoading(false)
            await httpClient.get('/user/self').then(({ data }) => {
                props.$commitToStore({
                    userSession: data
                })
            })

            if (close) {
                props.close()
            }
        } catch (error) {
            notifyAboutError(props, error)
        }
    }

    return <Popup
        maxWidth="650px"
        onClose={() => {
            props.close()
        }}
    >
        <div>
            {
                props.helpView
            }
            <hr className="newHR"/>
            <div className="Body_15" style={{textAlign: "left"}}>
                {`Чтобы настроить интеграцию с ${props.serviceName} - скопируйте ссылку ниже и вставьте ее в соответствующий раздел в настройках ${props.serviceName}`}
            </div>
            <div>
                <div
                    className="buttonsContainer"
                    style={{marginLeft: "-10px", marginTop: "15px"}}
                >
                    <Radio
                        checked={integrationType === IntegrationType.noIntegration}
                        onChange={() => setIntegrationType(IntegrationType.noIntegration)}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <div className="commonLabel">
                        Интеграция отключена
                    </div>
                </div>
                <div
                    className="buttonsContainer"
                    style={{marginLeft: "-10px", marginTop: "15px"}}
                >

                    <Radio
                        checked={integrationType === IntegrationType.useOneTemplate}
                        onChange={() => setIntegrationType(IntegrationType.useOneTemplate)}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <div className="commonLabel">
                        У меня один шаблон на все квартиры
                    </div>
                </div>
                {
                    integrationType === IntegrationType.useOneTemplate &&
                    makeOneTemplateSelector()
                }
                <div
                    className="buttonsContainer"
                    style={{marginLeft: "-10px", marginTop: "15px"}}
                >

                    <Radio
                        checked={integrationType === IntegrationType.useManyTemplate}
                        onChange={() => setIntegrationType(IntegrationType.useManyTemplate)}
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <div className="commonLabel">
                        У меня отдельный шаблон на каждую квартиру
                    </div>
                </div>
                {
                    integrationType === IntegrationType.useManyTemplate &&
                    makeManyTemplatesListView()
                }

                {
                    (integrationType === IntegrationType.useOneTemplate || integrationType === IntegrationType.useManyTemplate) &&
                    <div>
                        <hr className="newHR"/>
                        {
                            makeURLView()
                        }
                    </div>
                }

                <hr className="newHR"/>
                <button
                    className="new withTitle _wa"
                    disabled={loading}
                    style={{marginTop: "15px"}}
                    onClick={async () => {
                        await save(true)
                    }}
                >
                    <div className="buttonsContainer">
                        {
                            loading &&
                            <CircularProgress size={"25px"} sx={{color: "#1FA3EE"}} />
                        }
                        <span>Сохранить и закрыть</span>
                    </div>
                </button>
            </div>
        </div>
    </Popup>
}

IncomingWebhookIntegrationPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(IncomingWebhookIntegrationPopup)

export default IncomingWebhookIntegrationPopup
