import {prepareForRegexp} from "./prepareForRegexp";


export function getHighlightedText(text: string, searchText: string) {
    if (searchText.length === 0) {
        return text
    }

    const preparedText = prepareForRegexp(searchText)
    const parts = text.split(new RegExp(`(${preparedText})`, 'gi'));
    return <span>
        {
            parts.map(
                part =>
                    part.toString().toLowerCase() === searchText.toString().toLowerCase()
                        ? <b style={{background: "#FFCC66", borderRadius: "6px"}}>{part}</b>
                        : part
            )
        }
    </span>;
}