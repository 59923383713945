import React, {useEffect, useState} from "react";
import {commonTextField} from "../Styles/CommonCSSProperties";
import {connect} from "react-redux";
import cleanPhoneNumber from "../../methods/cleanPhoneNumber";
import {preparePhoneNumber, toHumanTime} from "../../funcs";
import {DidntReceiveCode, notifyUserAboutCode} from "./DidntReceiveCode";
import {CircularProgress} from "@mui/material";
import {sendVerificationCode} from "../../methods/sendVerificationCode";
import {notifyAboutError} from "../../methods/notifyUser";

type Props = {
    verification_code: string
    code_channel: string,
    phone_number: string,
    disabled_code_channels: string[],
    updateVerificationCode: Function,
    updateCodeChannel: Function,
    finish: Function,
    back: Function,
    $store: any
}

let VerificationCodeView: any = function EntityFormPopup(props: Props) {
    const {verification_code, code_channel, phone_number, disabled_code_channels, updateVerificationCode, updateCodeChannel, finish, back, $store} = props

    // Resend SMS countdown
    const [resendSmsCountdown, setResendSmsCountdown] = useState(30)
    let timer = setTimeout(() => {}, 0)
    useEffect(() => {
        if (resendSmsCountdown === 0) return
        timer = setTimeout(() => setResendSmsCountdown(resendSmsCountdown - 1), 1000)
        return () => {
            clearTimeout(timer)
        }
    }, [resendSmsCountdown])

    async function requestVerificationCode(channel: string) {
        try {
            await sendVerificationCode(props, cleanPhoneNumber(phone_number), channel)
            updateCodeChannel(channel)
            notifyUserAboutCode(props, channel)
            clearTimeout(timer)
            setResendSmsCountdown(180)
        } catch (error) {
            notifyAboutError(props, error)
        }
    }

    function verificationCodePlaceholder() {
        if (code_channel == "tg") return "Код из телеграм бота"
        if (code_channel == "sms") return "Код из SMS"
        if (code_channel == "call") return "Последние четыре цифры входящего звонка"
    }

    function codeHintView() {
        if (code_channel == "sms") {
            return <div className="hint">
                На номер <b>{preparePhoneNumber(phone_number)}</b> отправлено сообщение с
                кодом. Введите его ниже:
            </div>
        }
        if (code_channel == "tg") {
            return <div className="hint" style={{textAlign: "center"}}>
                Для того, чтобы получить смс код в телеграме, перейдите по ссылке:
                <br/>
                <a href={`https://t.me/${process.env.REACT_APP_TG_AUTH_BOT_NAME}`} target="_blank"><b>@{process.env.REACT_APP_TG_AUTH_BOT_NAME}</b></a>
                <br/>
                Запустите бота, нажав на соответствующую кнопку или командой старт <b>/start</b>
                <br/>
                Затем <b>поделитесь своим контактом</b>, используя кнопку <b>"Выслать свой номер телефона"</b>
            </div>
        }
        if (code_channel == "call") {
            return <div className="hint" style={{textAlign: "center"}}>
                Сейчас вам поступит телефонный звонок.
                <br/>
                Звонок можно отклонить. Ваш код - 4 последние цифры номера телефона, с которого вам позвонили.
                <br/>
                Введите четыре последние цифры номера в окошко ниже:
            </div>
        }
    }
    
    return <>
        {
            codeHintView()
        }

        <form onSubmit={(e) => {
            e.preventDefault()
            finish(code_channel)
        }}>
            <div className="form-field center">
                <input
                    autoFocus={true}
                    style={commonTextField()}
                    type="text"
                    placeholder={verificationCodePlaceholder()}
                    defaultValue={verification_code}
                    onChange={({ target: { value } }: any) => {
                        updateVerificationCode(value.trim())
                    }}
                />
            </div>
            <div>
                <button
                    disabled={$store.loading}
                    className="new withTitle _wa"
                >
                    Завершить
                </button>
            </div>

            <div className="form-field center">
                <button
                    disabled={$store.loading}
                    className="new withTitle secondary _wa"
                    onClick={() => {
                        back()
                    }}>
                    Вернуться назад
                </button>
            </div>
        </form>

        { code_channel != "tg" &&
            <div className="form-field center">
                <button
                    className="_zeroed"
                    disabled={!!resendSmsCountdown}
                    onClick={ async () => {
                        await requestVerificationCode(code_channel)
                    }}
                >
                    {`
                Выслать код ещё раз
                ${resendSmsCountdown ? `(${toHumanTime(resendSmsCountdown)})` : ''}
              `}
                </button>
            </div>
        }

        <DidntReceiveCode
            channel={code_channel}
            disabled_code_channels={disabled_code_channels}
            phone_number={phone_number}
            getCode={ async (channel: string) => {
                await requestVerificationCode(channel)
            }}
        />
        {
            $store.loading &&
            <div style={{marginTop: "15px"}}>
                <CircularProgress sx={{color: "#1FA3EE"}} />
            </div>
        }
    </>
}

VerificationCodeView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(VerificationCodeView)

export default VerificationCodeView