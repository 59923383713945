import React, { useEffect, useState } from "react"
import { useRoute } from "react-router5"
import { connect } from "react-redux"

import _ from "lodash"

import "styles/pages/register.sass"

import {validateMany} from "validate"
import {
  httpClient,
  isCallAllowedForPhoneNumber,
  isSMSAllowedForPhoneNumber,
  isTGAllowedForPhoneNumber, preparePhoneNumber
} from "funcs"

import notifyUser, {capitalize, notifyAboutError} from "../../methods/notifyUser";
import {TextareaStyled} from "../Styles/CommonCSSProperties";
import {EntityInput} from "../Entity/EntityInput";

import {useLocalStorage} from "../../methods/useLocalStorage";
import Checkbox from "@mui/material/Checkbox";
import {ReactComponent as CheckboxIcon} from "../Icons/size24/Checkbox.svg";
import {ReactComponent as CheckboxChecked} from "../Icons/size24/CheckboxChecked.svg";
import {canEditSystemEntityById, contractSystemEntity, entityById} from "methods/systemEntityValueByKeyword"
import {PassportFormContainer} from "./UserForms/PassportFormContainer";
import {
  extraEntitiesToSend,
  extraFieldIsRequired,
  isExtraEntity,
  prepareDataInRegisterForm, validateContractCreatedForAndClientRole,
  validateContractCreatedForAndUser,
  validateContractCreatedForAndUserEmail,
  validateContractCreatedForAndUserPhoneNumber,
  validateCustomerRegisterFormWhileSigning,
  validateEntrepreneurRegisterFormWhileSigning,
} from "../../methods/registerFormValidation";
import {trackGoal} from "../../methods/trackGoal";
import {notifyUserAboutCode} from "./DidntReceiveCode";
import {saveToken} from "../../methods/saveToken";
import {clientRoles, ChooseAccountTypeFrom} from "./UserForms/ChooseAccountTypeFrom";
import ContactsForm from "./UserForms/ContactsForm";
import BankDetailsForm from "./UserForms/BankDetailsForm";
import EntrepreneurForm from "./UserForms/EntrepreneurForm";
import CustomerForm from "./UserForms/CustomerForm";
import {FormInputPlaceholder} from "./UserForms/FormInput";
import {defaultUserFormForSigning} from "./RegisterDefaultValues";
import {updateEntityInEditor} from "../../methods/updateEntityInEditor";
import VerificationCodeView from "./VerificationCodeView";
import {sendVerificationCode} from "../../methods/sendVerificationCode";
import {EntityAdditionalValuesView} from "../Entity/EntityAdditionalValuesView";
import {
  clientBankAccount,
  clientBankName,
  clientBIC,
  clientCorrAccount, clientEmail, clientFIO, clientFirstName, clientLastName, clientLawAddress,
  clientLawName, clientMiddleName, clientOGRN, clientPhoneNumber, clientRole, clientTaxId, firstSigner, storeClientData
} from "../../methods/SystemEntity";
import {userFullname} from "../../methods/utils";


type Props = {
  contract?: any,
  title: string
  subtitle: string,
  updateEntity: Function,
  finish: Function,
  goToMainOnClose: Function,
  $store: any,
  $commitToStore: Function
}

let RegisterPage: any = function RegisterPage(props: Props) {
  const {contract, updateEntity, finish} = props

  const $router = useRoute()
  const [savedForm, setSavedForm, clearLocalStorage] = useLocalStorage("registerForm")

  function defaultForm() {
    return {
      ...defaultUserFormForSigning(is_first_signer, contract, props.$store.userSession),
      contractId: contract?._id ?? '',

      $step: 1,

      promo_code: $router.route.params.promoCode || '',

      entities: extraEntitiesToSend(contract, is_first_signer),

      verification_code: '',

      code_channel: "sms",
    }
  }
  function loadSavedForm() {
    let saved = savedForm()
    if (saved?.contractId == contract?._id ?? '') {
      return saved ?? defaultForm()
    } else {
      return defaultForm()
    }
  }

  const is_first_signer = $router.route.params.is_first_signer ?? false
  const redirect_url = contract
      ? {
        type: 'contract-sign',
        data: {
          contractId: contract._id
        }
      }
      : null

  const [registerForm, setRegisterForm] = useState<any>(loadSavedForm())

  const updateRegisterForm = (form: any) => {
    setSavedForm(form)
    setRegisterForm(form)
  }

  useEffect(() => {
    const updatedForm = {...registerForm}
    let contractEntities = extraEntitiesToSend(contract, is_first_signer)

    for (let entity of contractEntities) {
      let storeValue = updatedForm.entities.filter((storedEntity: any) => entity.keyword == storedEntity.keyword)[0]?.value
      if (storeValue) {
        entity.value = storeValue
        updateEntity(false, entity)
      }
    }
    if (contract) {
      updatedForm.entities = contractEntities
      for (let propertyName in registerForm) {
        let value = registerForm[propertyName]
        if (value) {
          let entity = entityById(contract.system_entities, propertyName)
          if (entity) {
            updateEntityInEditor({...entity, value: value})
          }
        }
      }
      updateRegisterForm(updatedForm)

      let contractClientFio = contractSystemEntity(contract, clientFIO.id)
      if (contractClientFio && contractClientFio.value) {
        updateEntityInEditor(contractClientFio)
      } else {
        if (props.$store.userSession && props.$store.userSession.last_name) {
          updateEntityInEditor({...clientFIO, value: userFullname(props.$store.userSession)})
        }
      }

    }
  }, [])

  useEffect(() => {
    updateRegisterForm({
      ...registerForm,
      ...defaultUserFormForSigning(is_first_signer, contract, props.$store.userSession),
    })
  }, [props.$store.userSession])

  function extraEntitiesToFill() {
    return contract?.entities.filter((entity: any) => {
      return isExtraEntity(entity, is_first_signer)
    }) ?? []
  }

  async function doRegister(code_channel: string) {
    props.$commitToStore({
      loading: true
    })

    const $step = registerForm.$step

    try {
      // Handle $step 1
      if ($step === 1) {
        if (registerForm.client_role == 'customer') {
          await validateAndRequestCode(code_channel, validateCustomerRegisterFormWhileSigning)
        } else if (registerForm.client_role == 'entrepreneur') {
          await validateAndRequestCode(code_channel, validateEntrepreneurRegisterFormWhileSigning)
        }
      }
      else if ($step === 2) {
        if (props.$store.userSession) {
          await sign()
        } else if (registerForm.client_role == 'customer') {
          await registerCustomer()
        } else if (registerForm.client_role == 'entrepreneur') {
          await registerEntrepreneur()
        }
      }
    }
    catch (error) {
      trackGoal('register_validation_failed', {error: error, form: registerForm})
      notifyAboutError(props, error)
    }

    finally {
      props.$commitToStore({
        loading: false
      })
    }
  }

  async function validateAndRequestCode(code_channel: string, validation: Function) {
    let form = prepareDataInRegisterForm(registerForm)

    let validationErrors: string[] = validation(form, contract, is_first_signer)
    if (validationErrors.length)
      throw new Error(validationErrors.join("\n"))

    if (!await sendVerificationCode(props, form.client_phone_number, code_channel)) {
      return
    }
    notifyUserAboutCode(props, code_channel)

    // Save prepared data and go to next step
    setTimeout(() => {
      updateRegisterForm({
        ...registerForm,
        ...form,
        $step: 2,
        code_channel: code_channel
      })
    }, 0)
  }

  async function registerCustomer() {
    const validationErrors = validateMany([
      {rule: "verificationCode", entity: registerForm.verification_code, displayFieldText: 'Проверочный код'}
    ])
    if (validationErrors.length)
      throw new Error(validationErrors.join("\n"))

    const { data: user } = await httpClient.post('/customer', {
      ...registerForm,
      entities: extraEntitiesToSend(contract, is_first_signer),
      redirect_url: redirect_url,
      is_first_signer: is_first_signer
    })

    await finishRegistration(user)
  }

  async function registerEntrepreneur() {
    const validationErrors = validateMany([
      {rule: "verificationCode", entity: registerForm.verification_code, displayFieldText: 'Проверочный код'}
    ])
    if (validationErrors.length)
      throw new Error(validationErrors.join("\n"))

    const { data: user } = await httpClient.post('/entrepreneur', {
      ...registerForm,
      entities: extraEntitiesToSend(contract, is_first_signer),
      redirect_url: redirect_url,
      is_first_signer: is_first_signer
    })

    await finishRegistration(user)
  }

  async function finishRegistration(user: any) {
    saveToken(user.access_token)

    notifyUser(props, contract ? 'Документ успешно подписан' : 'Регистрация успешна')

    const { data: balance } = await httpClient.get(`/get-balance`)

    // Save user
    props.$commitToStore({
      userSession: user,
      balance: balance.balance,
      showWelcomePopup: !contract
    })

    setTimeout(clearLocalStorage, 1)

    if (contract) {
      finish(_.get(user, "signed_contract_id"))
    } else {
      setTimeout(() => {
        $router.router.navigate('new-contract', {
          loaded: 'true'
        })
      }, 100)
      if (props.$store.inviteRoleId) {
        setTimeout(() => {
          $router.router.navigate('profile')
        }, 101)
      }
    }
  }

  async function sign() {
    props.$commitToStore({
      loading: true
    })

    try {
      const {data: contractId} = await httpClient.put(`/contract/${contract._id}/sign`, {
        ...registerForm,
        entities: extraEntitiesToSend(contract, is_first_signer),
        is_first_signer: is_first_signer
      }, {
        maxContentLength: 10000000,
        maxBodyLength: 10000000,
      })

      // Show success notification
      props.$commitToStore({
        notification: {
          title: 'Документ подписан',
          content: null
        }
      })

      setTimeout(() => {
        updateRegisterForm({})
        clearLocalStorage()
        finish(contractId.length === 24 ? contractId : contract._id)
      }, 100)
    } catch (error) {
      notifyAboutError(props, error)
    } finally {
      props.$commitToStore({
        loading: false
      })
    }
  }

  function makeTitle() {
    if (contract) {
      if (props.$store.userSession && registerForm.$step == 2) {
        return 'Код из смс'
      } else {
        return 'Подписание документа'
      }
    } else {
      return 'Регистрация'
    }
  }

  function clientRoleWarning() {
    let roleWarning = validateContractCreatedForAndClientRole(contract, registerForm, props.$store.userSession)
    if (roleWarning) {
      return <div style={{color: "#E22631", textAlign: "center", marginTop: "15px"}} className={"editContractEntitiesCloseTip"}>
        {roleWarning}
      </div>
    }
  }

  function makeUserDataWarning() {
    if (!contract) {
      return null
    }

    if (props.$store.userSession || registerForm.store_client_data || is_first_signer || !contract.first_signer) {
      return null
    }

    if (registerForm.$step == 1 && registerForm.client_role == clientRoles[1]) {
      return <div
          className='Body_15'
          style={{
            // color: "#E22631",
            marginTop: "15px",
            textAlign: "center"
          }}>
        Ваши персональные данные, приложенные фотографии и подписанные документы хранятся только на электронных почтах подписантов данного документа. <b>Сервис ОкиДоки не хранит ваши персональные данные</b>.
      </div>
    }
  }

  function alreadyHaveAccountView() {
    if (!props.$store.userSession && registerForm.$step === 1) {
      return <button
          className="new"
          style={{marginBottom: "10px", textAlign: "center"}}
          onClick={() => {
            trackGoal('register_i_have_account')
            if (contract) {
              props.$commitToStore({
                redirectUrl: {
                  type: 'contract-sign',
                  data: {
                    contractId: contract._id
                  }
                }
              })
            }
            setTimeout(() => $router.router.navigate('main'), 0)
          }}
      >
        <div className="commonLabel">
          <span>Уже есть аккаунт? </span>
          <span style={{color: "#33B5FF"}}>
            Войти в свой аккаунт
          </span>
        </div>
      </button>
    }
  }

  function accountTypeView() {
    if (props.$store.userSession) {
      return null
    }

    if (!canEditSystemEntityById(contract, clientRole.id)) {
      return null
    }

    return <ChooseAccountTypeFrom
      title={contract && !props.$store.userSession
          ? "Я подписываю документ как:"
          : "Я регистрируюсь как:"}
      role={registerForm.client_role}
      onChange={(role: string) => {
        updateRegisterForm({
          ...registerForm,
          client_role: role
        })
      }}
    />
  }


  function makeForm() {
    if (registerForm.client_role === clientRoles[1]) {
      return makeCustomerForm()
    } else {
      return makeEntrepreneurForm()
    }
  }

  function updateFIO(value: string) {
    if (is_first_signer) {
      updateEntityInEditor({ keyword: firstSigner.keyword, value: value, type: "Текст"})
    } else {
      updateEntityInEditor({ keyword: clientFIO.keyword, value: value, type: "Текст"})
    }
  }

  function makeCustomerForm() {
    return <div>
      <CustomerForm
          lastName={registerForm.client_last_name}
          lastNameDisabled={!canEditSystemEntityById(contract, clientLastName.id)}
          onChangeLastName={(value: string) => {
            let fio = value + ' ' + registerForm.client_first_name + ' ' + registerForm.client_middle_name
            updateRegisterForm({ ...registerForm, client_last_name: value, client_fio: fio.trim()})
            updateFIO(fio)
          }}

          firstName={registerForm.client_first_name}
          firstNameDisabled={!canEditSystemEntityById(contract, clientFirstName.id)}
          onChangeFirstName={(value: string) => {
            let fio = registerForm.client_last_name + ' ' + value + ' ' + registerForm.client_middle_name
            updateRegisterForm({ ...registerForm, client_first_name: value, client_fio: fio.trim()})
            updateFIO(fio)
          }}

          middleName={registerForm.client_middle_name}
          middleNameDisabled={!canEditSystemEntityById(contract, clientMiddleName.id)}
          onChangeMiddleName={(value: string) => {
            let fio = registerForm.client_last_name + ' ' + registerForm.client_first_name + ' ' + value
            updateRegisterForm({ ...registerForm, client_middle_name: value, client_fio: fio.trim()})
            updateFIO(fio)
          }}
      />

      <PassportFormContainer
          contract={contract}
          registerForm={registerForm}
          updateRegisterForm={(entityId: string, value: string) => {
            let updatedForm = {...registerForm}
            _.set(updatedForm, entityId, value)
            updateRegisterForm(updatedForm)
          }}
          user={props.$store.userSession}
          fillByAdmin={false}
      />

      {
        contactsForm()
      }

      {
          !contract &&
          promoCodeView()
      }
    </div>
  }

  function makeEntrepreneurForm() {
    return <div>
      <EntrepreneurForm
          fullDataRequired={contract}
          form={registerForm}
          updateProperty={(id: string, value: string) => {
            let updatedForm = {...registerForm}
            _.set(updatedForm, id, value)
            updateRegisterForm(updatedForm)
          }}
          law_name_disabled={!canEditSystemEntityById(contract, clientLawName.id)}
          law_address_disabled={!canEditSystemEntityById(contract, clientLawAddress.id)}
          tax_id_disabled={!canEditSystemEntityById(contract, clientTaxId.id)}
          ogrn_disabled={!canEditSystemEntityById(contract, clientOGRN.id)}
      />

      {
        bankDetailsForm()
      }

      {
        contactsForm()
      }

      {
          !contract &&
          promoCodeView()
      }
    </div>
  }

  function bankDetailsForm() {
    if (contract) {
      return <BankDetailsForm
          form={registerForm}
          updateProperty={(id: string, value: string) => {
            let updatedForm = {...registerForm}
            _.set(updatedForm, id, value)
            updateRegisterForm(updatedForm)
          }}
          bank_name_disabled={!canEditSystemEntityById(contract, clientBankName.id)}
          bank_account_disabled={!canEditSystemEntityById(contract, clientBankAccount.id)}
          corr_account_disabled={!canEditSystemEntityById(contract, clientCorrAccount.id)}
          bic_disabled={!canEditSystemEntityById(contract, clientBIC.id)}
      />
    }
  }

  function contactsForm() {
    let emailWarning = validateContractCreatedForAndUserEmail(contract, registerForm, props.$store.userSession)
    let phoneNumberWarning = validateContractCreatedForAndUserPhoneNumber(contract, registerForm, props.$store.userSession)
    let hasUser = !!props.$store.userSession

    return <ContactsForm
        email={{
          title: 'E-mail',
          placeholder: FormInputPlaceholder.email,
          tip: contract
              ? 'На указанный почтовый адрес придет письмо с подписанным договором'
              : 'На указанный почтовый адрес будут приходить письма с подписанными договорами',
          value: registerForm.client_email,
          disabled: !canEditSystemEntityById(contract, clientEmail.id) || hasUser,
          onChange: (value: string) => {
            updateRegisterForm({ ...registerForm, client_email: value })
            updateEntityInEditor({ keyword: clientEmail.keyword, value: value, type: "Текст"})
          },
          warning: emailWarning
        }}
        phoneNumber={
          {
            title: 'Номер телефона',
            placeholder: FormInputPlaceholder.phoneNumber,
            tip: contract
                ? 'Этот номер телефона придет смс код для подверждения вашего номера телефона.'
                : 'Этот номер телефона придет смс код для входа в ваш профиль в сервисе ОкиДоки.',
            value: registerForm.client_phone_number,
            disabled: !canEditSystemEntityById(contract,clientPhoneNumber.id) || hasUser,
            onChange: (value: string) => {
              updateRegisterForm({...registerForm, client_phone_number: value})
              updateEntityInEditor({ keyword: clientPhoneNumber.keyword, value: value, type: "Текст"})
            },
            warning: phoneNumberWarning
          }
        }
    />
  }

  function storeUserDataView() {
    if (!contract) {
      return null
    }

    if (props.$store.userSession) {
      return null
    }

    if (contract.is_mass_template) {
      return null
    }

    if (registerForm.client_role !== clientRoles[1]) {
      return null
    }

    if (is_first_signer || !contract.first_signer) {
      return null
    }

    if (!canEditSystemEntityById(contract, storeClientData.id)) {
      return null
    }

    return <div>
      <div className="buttonsContainer" style={{marginTop: "15px"}}>
        <Checkbox
            icon={<CheckboxIcon className="icon"/>}
            checkedIcon={<CheckboxChecked className="icon"/>}
            checked={registerForm.store_client_data}
            onChange={({target: {checked}}) => {
              updateRegisterForm({...registerForm, store_client_data: checked})
            }}
        />
        <div className="commonLabel">
          Сохранить ваши данные в системе?
        </div>
      </div>
      <div className="commonSubtitle">
        Если не ставить галочку, то документ и ваши личные данные не будут храниться на нашем сервере. Вы получите свой экземпляр документа по электронной почте.
        <br/>
        Если поставить галочку, то для вас будет создан личный кабинет, и при повторном подписании документов вам нужно будет вводить только смс-код
      </div>

      <hr className="newHR"/>
    </div>
  }

  function promoCodeView() {
    return <div>
      <div className="Subheader_17"  style={{marginTop: "20px"}}>
        Промо-код:
      </div>
      <TextareaStyled
          style={{marginTop: "5px"}}
          placeholder="Промо-код"
          value={registerForm.client_promo_code}
          onChange={({ target: { value } }: any) => updateRegisterForm({ ...registerForm, promo_code: value })}
      />

      <div className="Subheader_17"  style={{marginTop: "20px"}}>
        Расскажите откуда он нас узнали:
      </div>
      <div className="commonSubtitle" style={{marginTop: "0px", whiteSpace: "pre-wrap"}}>
        Выберите из списка или напишите свой вариант
      </div>
      <div style={{marginTop: "5px"}}>
        <EntityInput
            entity={{
              type: 'Список',
              value: registerForm.source ?? '',
              list: [
                "От друзей и коллег",
                "Сам нашел в интернете",
                "Увидел рекламу в телеграме",
                "Увидел рекламу в Youtube",
                "Дали на подпись договор",
              ]
            }}
            updateEntity={(value: string) => {
              updateRegisterForm({
                ...registerForm,
                source: value
              })
            }}
        />
      </div>

      <div className="Subheader_17"  style={{marginTop: "20px"}}>
        Какой вид бизнеса у вас?:
      </div>
      <div className="commonSubtitle" style={{marginTop: "0px", whiteSpace: "pre-wrap"}}>
        Выберите из списка или напишите свой вариант
      </div>
      <div style={{marginTop: "5px"}}>
        <EntityInput
            entity={{
              type: 'Список',
              value: registerForm.business ?? '',
              list: [
                  "Посуточная аренда",
                  "Риэлторские услуги",
                  "Туризм",
                  "Аренда вещей",
                  "Аренда автомобилей",
                  "Косметология",
                  "Образование",
                  "У меня нет бизнеса, я здесь чтобы подписать договор",
              ]
            }}
            updateEntity={(value: string) => {
              updateRegisterForm({
                ...registerForm,
                business: value
              })
            }}
        />
      </div>
    </div>
  }

  function extraFields() {
    let entities = extraEntitiesToFill()
    if (!contract || entities.length == 0) {
      return null
    }

    return <div>
      <hr className="newHR"/>

      <div className="Subheader_17" style={{marginTop: "1px"}}>
        Заполните дополнительные поля для подписания этого документа
      </div>

      {
        entities.map((entity: any, index: number) => <div>
              <div className="Subheader_17" style={{marginTop: "15px"}}>
                <span>
                  {entity.keyword}
                </span>
                {
                    extraFieldIsRequired(entity, is_first_signer) &&
                    <span style={{color: 'red'}}>*</span>
                }

              </div>
              {
                  entity.description &&
                  <div className="commonSubtitle" style={{marginTop: "0px", whiteSpace: "pre-wrap"}}>
                    {entity.description}
                  </div>
              }
              <div style={{marginTop: "5px"}}>
                <EntityInput
                    entity={entity}
                    updateEntity={(value: string) => {
                      let entities = extraEntitiesToSend(contract, is_first_signer)
                      for (let entityInContract of entities) {
                        if (entityInContract.keyword == entity.keyword) {
                          entityInContract.value = value
                          updateEntity(false, entityInContract)
                          setTimeout(() => {
                            updateRegisterForm({
                              ...registerForm,
                              entities: extraEntitiesToSend(contract, is_first_signer)
                            })
                          }, 300)
                          break
                        }
                      }
                    }}
                />
              </div>
              {
                  (entity.allowMultipleValues ?? false) &&
                  <EntityAdditionalValuesView
                      entity={entity}
                      updateEntity={(value: string, additionalValues: string[]) => {
                        updateEntity(false, {
                          ...entity,
                          additionalValues: additionalValues
                        })
                      }}
                  />
              }
            </div>
        )
      }
    </div>
  }

  function makeVerificationCodeView() {
    return <VerificationCodeView
        verification_code={registerForm.verification_code}
        code_channel={registerForm.code_channel}
        phone_number={registerForm.client_phone_number}
        disabled_code_channels={contract?.disabled_code_channels ?? []}
        updateVerificationCode={(value: string) => {
          updateRegisterForm({ ...registerForm, verification_code: value })
        }}
        updateCodeChannel={(value: string) => {
          updateRegisterForm({ ...registerForm, code_channel: value })
        }}
        finish={ async () => {
          await doRegister(registerForm.code_channel)
        }}
        back={() => {
          trackGoal('register_back_to_form')
          updateRegisterForm({...registerForm, $step: 1})
        }}
        $store={props.$store}
    />
  }


  // Render
  return (
    <div className="registerPage">

      <div className="H2_26">
        {makeTitle()}
      </div>

      {
        clientRoleWarning()
      }

      {
        makeUserDataWarning()
      }

      {
        !props.$store.userSession &&
        <hr className="newHR"/>
      }

      {/* Register form step 1*/}

      {registerForm.$step === 1 && (<>
        {
          alreadyHaveAccountView()
        }

        <div style={{width: "100%"}}>
          {
            accountTypeView()
          }

          {
            makeForm()
          }

          {
            extraFields()
          }

          <hr className="newHR"/>

          {
              contract && !contract?.is_mass_template && !props.$store.userSession && registerForm.client_role === clientRoles[1] &&
              storeUserDataView()
          }

          <div className="commonLabel" style={{marginTop: "15px", marginBottom: "10px"}}>
            Как вы хотите получить смс код для подписания?
          </div>

          {
              isCallAllowedForPhoneNumber(registerForm.client_phone_number, contract?.disabled_code_channels ?? []) &&
              <button
                  className="new withTitle _wa"
                  disabled={props.$store.loading || validateContractCreatedForAndUser(contract, registerForm, props.$store.userSession)}
                  onClick={() => {
                    trackGoal('get_code_via_call')
                    doRegister("call")
                  }}
              >
                Получить код через телефонный звонок
              </button>
          }

          {
              isSMSAllowedForPhoneNumber(registerForm.client_phone_number, contract?.disabled_code_channels ?? []) &&
              <button
                  className="new withTitle _wa"
                  style={{marginTop: "15px"}}
                  disabled={props.$store.loading || validateContractCreatedForAndUser(contract, registerForm, props.$store.userSession)}
                  onClick={() => {
                    trackGoal('get_code_via_sms')
                    doRegister("sms")
                  }}
              >
                Получить код через смс
              </button>
          }

          {
              isTGAllowedForPhoneNumber(registerForm.client_phone_number, contract?.disabled_code_channels ?? []) &&
              <button
                  className="new withTitle _wa"
                  style={{marginTop: "15px"}}
                  disabled={props.$store.loading || validateContractCreatedForAndUser(contract, registerForm, props.$store.userSession)}
                  onClick={() => {
                    trackGoal('get_code_via_tg')
                    doRegister("tg")
                  }}
              >
                Получить код через телеграм
              </button>
          }

          {
            !contract && <button
                  className="new withTitle secondary _wa"
                  style={{marginTop: "15px"}}
                  onClick={() => $router.router.navigate('main')}
              >
                Вернуться назад
              </button>
          }
        </div>

        <div className="small-hint" style={{marginBottom: "30px"}}>
          При подписании документа или регистрации вы принимаете условия <a href="/confidential_policy.pdf" target="_blank">Политики конфиденциальности</a> и принимаете условия <a href="/agreement.pdf" target="_blank">Соглашения</a>
        </div>

        <button
            className="new secondary _wa commonSubtitle"
            style={{}}
            onClick={() => {
              trackGoal("register_clear_form", {form: registerForm})
              clearLocalStorage()
              location.reload()
            }}
        >
          Сбросить форму и заполнить заново
        </button>
      </>)}

      {/* Register form step 2 */}

      {
          registerForm.$step === 2 &&
          makeVerificationCodeView()
      }
    </div>
  )
}

RegisterPage = connect(
  (store) => ({ $store: store }),
  (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(RegisterPage)

export default RegisterPage
