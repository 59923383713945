import {useMediaQuery} from "@mui/material";
import Popup from "../../components/Popup";
import {TemplatesHelpView} from "../Templates/TemplatesHelpPopup";
import Checkbox from "@mui/material/Checkbox";
import {ReactComponent as CheckboxIcon} from "../Icons/size24/Checkbox.svg";
import {ReactComponent as CheckboxDisabledChecked} from "../Icons/size24/CheckboxDisabledChecked.svg";
import {ReactComponent as CheckboxChecked} from "../Icons/size24/CheckboxChecked.svg";
import React, {useState} from "react";
import {$moment} from "../../funcs";

export let SubscriptionPopup: any = function SubscriptionPopup(props: any) {
    const mobile = useMediaQuery("(max-width: 760px)");
    const [termChecked, setTermsChecked] = useState(false)
    const [agreementChecked, setAgreementChecked] = useState(false)

    function subscriptionPeriod() {
        if (props.tariff.period == 'month') {
            return 'месяц'
        }

        if (props.tariff.period == 'year') {
            return 'год'
        }

        return ''
    }

    function chargeDate() {
        if (props.tariff.period == 'month') {
            return `Оплата будет списываться ${$moment().format('D')} числа каждого месяца`
        }

        if (props.tariff.period == 'year') {
            return `Следующее списание произойдет ${$moment().format('D MMMM')} следующего года`
        }

        return ''
    }

    function switchTariffRule() {
        let myTariffIsSubscription = props.$store.userSession?.tariff?.type == 'subscription'
        if (myTariffIsSubscription) {
            return 'Остаток действия текущей подписки будет учтен при переходе на новую подписку.'
        } else {
            return 'Внимание! При переходе на безлимитную подписку оставшийся баланс договоров будет утерян и его невозможно будет восстановить!'
        }
    }

    return <Popup
        style={{zIndex: 999}}
        onClose={props.onClose}
    >
        <div>
            <div className="commonLabel">
                Оформление подписки
            </div>

            <div className="commonSubtitle" style={{marginTop: "25px"}}>
                Вы собираетесь оформить подписку для безлимитного доступа к нашему сервису. Мы просим вас ознакомиться с несколькими важными условиями:
                <br/>
                <br/>
                1. Подписка оформляется на {subscriptionPeriod()}. Стоимость подписки - {props.tariff.price / 100} рублей в {subscriptionPeriod()}. {chargeDate()}.
                <br/>
                <br/>
                <b>{switchTariffRule()}</b>
                <br/>
                <br/>
                2. Оферта содержит описание условий автоплатежа. Платежи будут списываться безакцептно с вашей карты в соответствии с выбранным вами периодом (неделя, месяц, год и т.п.).
                <br/>
                <br/>
                3. Согласие на обработку персональных данных. Мы обязуемся защищать и обрабатывать ваши персональные данные с соблюдением законодательства о конфиденциальности. Пожалуйста, ознакомьтесь с нашей политикой конфиденциальности для получения более подробной информации.
                <br/>
                <br/>
                4. Согласие на сохранение учетных данных. Для обеспечения безпроблемного проведения будущих транзакций, мы просим ваше согласие на сохранение ваших учетных данных. Это поможет нам облегчить процесс и предоставить вам бесперебойный доступ к нашим услугам.
                <br/>
                <br/>
                5. Правила отмены и возврата. Вы можете отменить подписку в любой момент на странице своего профиля https://desktop.doki.online/profile
                <br/>
            </div>

            <hr className="newHR"/>

            <div className="buttonsContainer">
                <Checkbox
                    icon={<CheckboxIcon className="icon"/>}
                    checkedIcon={<CheckboxChecked className="icon"/>}
                    checked={termChecked}
                    onChange={({target: {checked}}) => {
                        setTermsChecked(checked)
                    }}
                />
                <div className="commonSubtitle">
                    Я даю соглания на обработку персональных данных в соответствии с <a href="/confidential_policy.pdf" target="_blank">Политикой конфиденциальности</a> и принимаю условия <a href="/agreement.pdf" target="_blank">Соглашения</a>
                </div>
            </div>
            <div className="buttonsContainer" style={{marginTop: "15px"}}>
                <Checkbox
                    icon={<CheckboxIcon className="icon"/>}
                    checkedIcon={<CheckboxChecked className="icon"/>}
                    checked={agreementChecked}
                    onChange={({target: {checked}}) => {
                        setAgreementChecked(checked)
                    }}
                />
                <div className="commonSubtitle">
                    Я согласен с условиями платной подписки изложенными в <a href="/agreement_subscriptions.pdf" target="_blank">Соглашение об использовании платной подписки</a> и даю <a href="/agreement_transactions.pdf" target="_blank">согласие на сохранение учетных данных для будущих транзакций</a>
                </div>
            </div>

            <hr className="newHR"/>

            <button
                disabled={!termChecked || !agreementChecked}
                className="new withTitle _wa"
                onClick={() => {
                    props.subscribe()
                }}
            >
                Оформить подписку
            </button>
        </div>
    </Popup>
}