import Popup from "../../components/Popup";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import MyPromoCodeView from "./MyPromoCodeView";
import {httpClient} from "../../funcs";
import {trackGoal} from "../../methods/trackGoal";
import NewCreateContractFlowView from "./NewCreateContractFlowView";
import {useRoute} from "react-router5";

let UserStoryPopup: any = function UserStoryPopup(props: any) {
    const {story} = props

    const $router = useRoute()

    useEffect(() => {
        trackGoal("user_story_shown", {story_id: story.story_id})
    }, [])

    function close() {
        props.onClose()
        httpClient.post(
            `/user-story-shown`,
            {
                'user_id': props.$store.userSession?._id,
                'story_id': story.story_id
            }
        ).then((res)=> {

        }).catch(err => {
            console.log(err)
        })
    }

    function storyActions() {
        switch (story.story_id) {
            case "referral":
                return referralActions()
            case "new_create_contract_flow":
                return newCreateContractFlow()
            case "new_prices":
                return newPricesAction()
            default:
                return
        }
    }

    function referralActions() {
        return <div style={{marginTop: "25px"}}>
            <MyPromoCodeView fullScreen={true}/>
        </div>
    }

    function newCreateContractFlow() {
        return <div style={{marginTop: "25px"}}>
            <NewCreateContractFlowView/>
        </div>
    }

    function newPricesAction() {
        return <div>
            <button
                className="new withTitle _wa"
                style={{marginTop: "25px"}}
                onClick={() => {
                    $router.router.navigate('profile')
                    close()
                }}
            >
                Перейти в профиль
            </button>

            <hr className="newHR"/>
        </div>
    }


    return <Popup
        style={{zIndex: 999}}
        onClose={close}
    >
        <div>
            <div className="H3_21" style={{textAlign: "center"}}>
                Уведомление
            </div>

            {
                story.story_id != "new_create_contract_flow"
                && <div>
                    <div className="commonLabel" style={{marginTop: "25px", textAlign: "center"}}>
                        {story.title}
                    </div>

                    <div className="commonSubtitle" style={{marginTop: "25px", whiteSpace: "pre-line"}}>
                        {story.message}
                    </div>
                </div>
            }

            <div>
                {
                    storyActions()
                }
            </div>

            <button
                className="new withTitle _wa secondary"
                style={{marginTop: "25px"}}
                onClick={() => {
                    close()
                }}
            >
                Закрыть
            </button>
        </div>
    </Popup>
}

UserStoryPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(UserStoryPopup)

export default UserStoryPopup