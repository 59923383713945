import rubles from "./numberToWords";
import {capitalize} from "./notifyUser";

export let mobileMaxWidth = 760
export let mobileMaxWidthMediaQuery = `(max-width:${mobileMaxWidth}px)`

export function getPostfixForEntity(entity: any) {
    if (!entity) {
        return ""
    }

    let type = entity.type

    if (type == 'Дата' || type == 'Фото') {
        return ""
    }

    return getPostfix(entity.value, entity.needNumberToWords)
}

export function getPostfix(value: string, need: boolean) {
    if (value.trim().replace(/[^\d.,-]/g, '').length != value.trim().length) {
        return ""
    }
    if (!need) {
        return ""
    }

    let result = rubles(value)
    if (result.length == 0) {
        return result
    }

    return ` (${capitalize(rubles(value))})`
}

export function userFullname(user: any): string {
    if (!user) {
        return ""
    }
    if (user.role == 'customer') {
        return (user.last_name ?? "") + " " + (user.first_name ?? "") + " " + (user.middle_name ?? "")
    } else {
        return user.law_name ?? ""
    }
}
