import Popup from "../../components/Popup";
import {connect} from "react-redux";
import React, {useState} from "react";
import {notifyAboutError} from "../../methods/notifyUser";
import {MobileInputMask} from "../../components/elements/InputWithMask";
import {commonTextField} from "../Styles/CommonCSSProperties";
import {validate} from "../../validate";

let InviteEmployeePopup: any = function InviteEmployeePopup(props: any) {
    const [invitePhoneNumber, setInvitePhoneNumber] = useState("")

    return <Popup
        maxWidth="650px"
        onClose={props.onClose}
    >
        <div>
            <div className="centeredLabel">
                <div className="commonLabel" style={{marginTop: "25px", textAlign: "center", whiteSpace: "pre-wrap"}}>
                    Введите номер телефона вашего сотрудника
                </div>
            </div>

            <MobileInputMask
                ref={null}
                value={invitePhoneNumber}
                disabled={false}
                onChange={(value: string) => {
                    setInvitePhoneNumber(value)
                }}
                style={{...(commonTextField()), marginTop: "5px"}}
            />

            <hr className="newHR"/>

            <div className="buttonsContainer" style={{width: "100%"}}>
                <button
                    className="new withTitle _wa"
                    onClick={(e) => {
                        try {
                            let error = validate('phoneNumber', invitePhoneNumber, 'Номер телефона')
                            if (error) {
                                throw new Error("Неправильный номер телефона")
                            }
                            props.invite(invitePhoneNumber)
                        } catch (error) {
                            notifyAboutError(props, error)
                        }
                    }}
                >
                    Пригласить
                </button>
                <button
                    className="new withTitle secondary _wa"
                    onClick={(e) => {
                        props.onClose()
                    }}
                >
                    Отмена
                </button>
            </div>
        </div>
    </Popup>
}

InviteEmployeePopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(InviteEmployeePopup)

export default InviteEmployeePopup