import React from 'react'
import Popup from 'components/Popup'
import { httpClient, nError } from 'funcs'
import { useRoute } from "react-router5"

import {Box, Button, Grid} from '@mui/material'
import ConfirmationPopup from "../Common/ConfirmationPopup";
import notifyUser from "../../methods/notifyUser";

type Props = {
    closePopup: Function,
    contractId: string,
    reload: Function,
    [type: string]: any,
    $commitToStore: Function
}

function DeleteContractPopup(props: Props) {
    const {closePopup, contractId, reload, loadContract} = props
    const $router = useRoute();
    async function hideContract() {
        try {
            await httpClient.put(`/contracts/${contractId}/remove`)

            notifyUser(
                props,
                'Успех',
                'Договор успешно удалён'
            )

            $router.router.navigate('signed-contracts')

            closePopup()
        } catch (error) {

            const $error = nError(error);
            notifyUser(
                props,
                $error.notification.title
            )
        }
    }
    return <ConfirmationPopup
        title={"Вы уверены, что хотите удалить договор?"}
        onClose={closePopup}
        onConfirm={hideContract}
    />
}


export default DeleteContractPopup