import {useRoute} from "react-router5";
import React, {useEffect, useState} from "react";
import {TextareaAutosize, useMediaQuery} from "@mui/material";
import {connect} from "react-redux";
import "pages/Styles/TemplatesCommon.sass"
import "pages/Styles/EditContractCommon.sass"
import {ReactComponent as Close} from "../../Icons/size24/Close.svg";
import {ReactComponent as Search} from "../../Icons/size24/Search.svg";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {
    commonSelect,
    MenuProps,
    TextareaStyled
} from "../../Styles/CommonCSSProperties";
import {httpClient} from "../../../funcs";
import {TemplatesHelpPopup} from "../../Templates/TemplatesHelpPopup";
import {mobileMaxWidthMediaQuery} from "../../../methods/utils";

type Props = {
    searchText: string,
    search: Function,
    selectedFilter: string[],
    selectStatusFilter: Function,
    $store: any,
    $commitToStore: Function
}

let ContractListHeader: any = function ContractsListHeader(props: Props) {
    const $router = useRoute();
    const {searchText, search, selectedFilter, selectStatusFilter, $store} = props

    const [showSearch, setShowSearch] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [availableContractTypes, setAvailableContractTypes] = useState<string[]>([]);
    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)

    const allContractTypes = "Любой статус договора"

    useEffect(
         () => {
             loadContractTypes()
        },
        []
    )

    async function loadContractTypes() {
        const { data: contractTypes } = await httpClient.get(`/contract-types`, {})
        let types: string[] = contractTypes.map((t: any) => (t.name))
        setAvailableContractTypes(types)
    }

    function mobileButtonsContainer() {
        return <div className="navbar" style={{padding: "20px 15px 15px 15px"}}>
            <div>
                {
                    showSearch
                        ? <div className="buttonsContainer _wa">
                            {makeSearch()}
                            <button
                                className="new bigIconed"
                                onClick={() => {
                                    search("")
                                    setShowSearch(false)
                                }}
                            >
                                <Close className="icon" style={{stroke: "#75809E"}} />
                            </button>
                        </div>
                        : <div
                            className="buttonsContainer _wa"
                        >
                            <button
                                className="new bigIconed"
                                onClick={() => {
                                    setShowSearch(true)
                                }}
                            >
                                <div>
                                    <Search className="icon"/>
                                </div>

                            </button>
                            <button
                                className="new tip _wa"
                                onClick={() => {
                                    setShowHelp(true)
                                }}
                            >
                                Как это работает?
                            </button>
                        </div>
                }
            </div>

            {makeFilter({marginTop: "15px", width: "100%"})}
        </div>
    }

    function desktopButtonsContainer() {
        return <div className="titleContainer">
            {makeSearch()}

            {makeFilter({ maxWidth: "300px"})}
        </div>
    }

    function makeSearch() {
        return <TextareaStyled
            padding={12}
            placeholder={"Поиск"}
            style={{
                maxWidth: "382px"
            }}
            value={searchText}
            onChange={({ target: { value }}: any) => {
                search(value);
            }}
            onResize={undefined}
            onResizeCapture={undefined}
        />
    }

    function makeFilter(sx: SxProps<Theme>) {
        let filter = selectedFilter ?? []
        let value = filter.length > 0 ? filter[0] : allContractTypes
        return <FormControl sx={sx}>
            <Select
                labelId="contract-type-label"
                id="contract-type"
                className="classicStyleMuiSelect"
                value={value}
                onChange={(event: SelectChangeEvent) => {
                    const {target} = event
                    if (!target) {
                        return
                    }
                    if (target.value === allContractTypes) {
                        selectStatusFilter([])
                    } else {
                        selectStatusFilter([target.value])
                    }
                }}
                MenuProps={MenuProps}
                sx={{...commonSelect("45px"), minWidth: "200px"}}
            >
                {
                    [allContractTypes].concat(availableContractTypes).map((filter) => (
                        <MenuItem key={filter} value={filter}>
                            <ListItemText primary={filter}/>
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    }

    return <div>
        {
            mobile ? mobileButtonsContainer() : desktopButtonsContainer()
        }
        {
            showHelp &&
            <TemplatesHelpPopup onClose={() => {setShowHelp(false)}}/>
        }
    </div>
}

ContractListHeader = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ContractListHeader)

export default ContractListHeader
