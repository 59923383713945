import React, {useEffect, useState} from "react"
import {useRoute} from "react-router5"
import {connect} from "react-redux"

import _ from "lodash"

import classnames from "classnames"

import "styles/pages/profile.sass"

import {$moment, httpClient, toHumanTime} from "funcs"

import Icon from "components/Icon"
import {CircularProgress, useMediaQuery} from "@mui/material";
import Popup from "../../components/Popup";
import PaymentStatus from "components/payment/PaymentStatus"
import {ReactComponent as Delete} from "../Icons/size24/Delete.svg";

import UserFields from "./UserFields";
import UpdateUserFields from "./UpdateUserFields";
import BankDetailsView from "./BankDetailsView";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import IncomingWebhookIntegrationPopup from "./IntegrationsPopup/IncomingWebhookIntegrationPopup";
import AmoCRMIntegrationPopup from "./IntegrationsPopup/AmoCRMIntegrationPopup";
import TGBotIntegrationView from "./TGBotIntegrationView";
import {supportLink} from "../../methods/openInNewTab";
import PromoActivatedPopup from "./PromoActivatedPopup";
import {checkPermission, UserPermission} from "../../methods/permissionByRole";
import UserListView from "./UserListView";
import {PageLoader} from "../Contracts/ContractList/PageLoader";
import {getToken, saveToken} from "../../methods/saveToken";
import {loadSelf, signOut} from "../../methods/loadSelf";
import TariffView from "./TariffView";
import MyTariffView from "./MyTariffView";
import MyPromoCodeView from "./MyPromoCodeView";
import RequestBillPopup from "./RequestBillPopup";
import IntegrationsView from "./IntegrationsPopup/IntegrationsView";

let ProfilePage: any = function ProfilePage(props: any) {
  const $router = useRoute()
  const [tariffs, setTariffs] = useState<any[]>([])
  const [packages, setPackages] = useState<any[]>([])
  const [cards, setCards] = useState<any[]>([])
  const [promoPopupShown, setPromoPopupShown] = useState(false)
  const [promoCode, setPromoCode] = useState<any>('')
  const [promoCodeActivated, setPromoCodeActivated] = useState<any>(false)
  const [showBonusPromoCode, setShowBonusPromoCode] = useState<any>(false)
  const [codeActivationResult, setCodeActivationResult] = useState<any>(null)
  const [expirationPromoCodeTimer, setExpirationPromoCodeTimer] = useState<any>(null)

  const promoCodes = _.get(props, "$store.userSession.active_promo_code", {})
  useEffect(() => {
    if (promoCodes && _.get(promoCodes, 'is_expiration_24_hours') && _.get(promoCodes, 'time_expiration')) {
      const nowSeconds = $moment().unix();
      const expirationTime = promoCodes['time_expiration'];
      if (expirationTime - nowSeconds < 0) {
        setShowBonusPromoCode(false)
        return;
      }
      setShowBonusPromoCode(true)
      setTimeout(() => setExpirationPromoCodeTimer(Math.round(expirationTime - nowSeconds)), 1000)
    } else {
      setShowBonusPromoCode(true)
    }
  }, [expirationPromoCodeTimer, promoCodes])


  const [paymentNotification, setPaymentNotification] = useState<boolean>(false);

  useEffect(() => {
    if ($router.route.params.success && $router.route.params.transactionId) {
      setPaymentNotification(true)
    }
  }, [$router])

  // Load tariffs
  useEffect(() => {
    if (!hasSession()) {
      return
    }

    loadSelf(props)
  }, [])

  useEffect(() => {
    if (checkPermission(props, UserPermission.OWNER)) {
      httpClient.get('/tariffs').then(({ data }) => {
        setTariffs(data)
      }).catch((error) => {
        console.log(error)
      })

      httpClient.get('/packages').then(({ data }) => {
        setPackages(data)
      }).catch((error) => {
        console.log(error)
      })

      loadCards()
    }
  }, [props.$store.userSession])

  function hasSession() {
    return getToken() != null
  }

  function userLoaded() {
    return props.$store.userSession != null
  }

  async function activatePromoCode() {
    httpClient.put("/user/activate_promo_code", {'promo_code': promoCode})
        .then(result => {
          const resultData = _.get(result, 'data', [])

          if (resultData) {
            setCodeActivationResult(resultData)
            setPromoCodeActivated(true)
          }
        })
        .catch(err => {
          notifyAboutError(props, err)
        })
    setPromoPopupShown(false)
  }

  function loadCards() {
    httpClient.get('/user/entrepreneur/list_cards').then(({ data }) => {
      if (data[0]) {
        let cards = data.filter((card: any) => { return card.status == 'CONFIRMED'})
        setCards(cards)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  async function addPaymentMethod() {
    let data = await httpClient.post('/add_payment_method')
    window.location.href = data.data.PaymentURL
  }

  async function deleteCard(card: any) {
    await httpClient.delete(`/user/entrepreneur/card/${card.card_id}`)
    setTimeout(() => {
      location.reload()
    }, 300)
  }

  function tariffView(tariff: any, i: number) {
    return <TariffView
      tariff={tariff}
      i={i}
      promoCodes={promoCodes}
      showBonusPromoCode={showBonusPromoCode}
      $store={props.$store}
      $commitToStore={props.$commitToStore}
    />
  }

  function myTariffSection() {
    return <div style={{marginTop: "5px"}}>
      <div className="commonLabel" style={{textAlign: "left"}}>
        Ваш тариф:
      </div>

      <div className="tariffs" style={{marginTop: "5px"}}>
        <MyTariffView
            tariff={props.$store.userSession.tariff}
            $store={props.$store}
            $commitToStore={props.$commitToStore}
        />
      </div>
    </div>
  }

  function packagesView() {
    if (packages.length > 0) {
      return <div style={{marginTop: "5px"}}>
        <div className="commonLabel" style={{textAlign: "left"}}>
          Пополнить баланс документов:
        </div>

        <div className="commonSubtitle" style={{textAlign: "left", marginTop: "5px"}}>
          Купленные пакеты бессрочные, они никогда не сгорят.
        </div>

        <div className="tariffs">
          {
            expirationPromoCodeTimer &&
              <div className="commonSubtitle" style={{fontSize: "15px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                До сгорания промо-кода осталось:
                <div className="commonLabel" style={{fontSize: "20px", paddingLeft: "5px"}}>
                  {expirationPromoCodeTimer ? toHumanTime(expirationPromoCodeTimer) : ''}
                </div>
              </div>
          }

          {
            packages.map((tariff: any, i: number) => tariffView(tariff, i))
          }
        </div>
      </div>
    }
  }

  function cardString(card: any) {
    if (!card) {
      return
    }

    if (card.exp_date) {
      return <span>{card.pan}, {card.exp_date.slice(0, 2) + '/' + card.exp_date.slice(2)}</span>
    } else {
      return <span>{card.pan}</span>
    }
   }

  function cardsView() {
    if (!tariffs || cards.length == 0) {
      return null
    }

    return <div className={"templateViewContainer disabledHover"}>
      <div className="commonLabel" style={{textAlign: "left"}}>
        Привязанные карты:
      </div>

      {
          cards.length == 0 &&
          <button className="new withTitle small" onClick={addPaymentMethod}>
            Добавить карту
          </button>
      }
      <div style={{marginTop: "15px"}}>
        {
          cards.map((card: any, i: number) => {
            return <div className="titleContainer" style={{marginTop: "5px"}}>
              {cardString(card)}
              <div className="action-buttons">
                <button
                    className="new smallIconed"
                    onClick={() => deleteCard(card)}
                >
                  <Delete className="icon dark"/>
                </button>
              </div>
            </div>
          })
        }
      </div>
    </div>
  }

  function subscriptionsView() {
    if (tariffs.length) {
      return <div style={{marginTop: "5px"}}>
        <div className="commonLabel" style={{textAlign: "left"}}>
          Подписка:
        </div>

        <div className="commonSubtitle" style={{textAlign: "left"}}>
          Если вы оформили безлимитную подписку, то вы можете пользоваться сервисом столько, сколько вам нужно, не думая о балансе вообще.
          Мы будем списывать деньги с вашей карты раз в месяц или раз в год в зависимости от выбранного тарифа.
          Здесь же можно отменить подписку в любое время.
        </div>

        <div className="tariffs">
          {
            tariffs.map((tariff: any, i: number) => tariffView(tariff, i))
          }
        </div>
      </div>
    }
  }

  function requestBill() {
    return <RequestBillPopup/>
  }

  function enterPromoCodeView() {
    if (!checkPermission(props, UserPermission.OWNER)) {
      return
    }
    return <div className={"templateViewContainer disabledHover"}>
      <div className="commonTitle" style={{textAlign: "left"}}>
        Введите промо-код
      </div>
      <div className="commonSubtitle" style={{textAlign: "left"}}>
        Промо-код позволяет получить бонусы. Например: набор шаблонов для определенной сферы бизнеса или бонус к купленному пакету.
        <br/>
        Попросите коллег поделиться своим промо-кодом, чтобы и вы и он получили бонусы 🙂
      </div>
      <hr className={"newHR"}/>
      <div className="row-data">
        <button
            className="new withTitle _wa"
            onClick={() => setPromoPopupShown(true)}
        >
          Ввести промо-код
        </button>
      </div>
    </div>
  }

  function myPromoCodeView() {
    if (!props.$store.userSession.promo_code) {
      return null
    }
    return <div className={"templateViewContainer disabledHover"}>
      <MyPromoCodeView/>
    </div>
  }

  function supportView() {
    return <div className={"templateViewContainer disabledHover"}>
      <div className="commonTitle" style={{textAlign: "left"}}>
        Поддержка
      </div>

      <div className={"row-data"}>
        <a href={supportLink()}>
          <div className="buttonsContainer">
            <div style={{display: "flex", height: "24px", width: "24px"}}>
              <Icon className="__telegram" icon="telegram-1"/>
            </div>
            <span className="__telegram">@OkiDokiSupportBot</span>
          </div>
        </a>
      </div>

      <div className={"row-data"}>
        <a href="https://wa.me/79626240184">
          <div className="buttonsContainer">
            <div style={{display: "flex", height: "24px", width: "24px"}}>
              <Icon className="__whatsapp" icon="whatsapp-1" />
            </div>
            <span className="__whatsapp">+7 (962) 624 0184</span>
          </div>
        </a>
      </div>
    </div>
  }

  function signOutView() {
    return <div className={"templateViewContainer disabledHover"}>
      <div className="commonTitle" style={{textAlign: "left"}}>
        Выход
      </div>

      <br />
      <button
          className="new withTitle _wa"
          onClick={() => signOut(props)}
      >
        Выйти
      </button>
    </div>
  }

  function notificationsView() {
    return <TGBotIntegrationView/>
  }

  function userName(user: any) {
    if (user.role == 'customer') {
        return user.last_name + ' ' + user.first_name + ' ' + user.middle_name
    }
    if (user.role == 'entrepreneur') {
        return user.law_name
    }
    return ''
  }

  // Render
  return (
      <div>
        {
            !userLoaded() &&
            <PageLoader />
        }
        <div className="profilePage">
          <div className="container">
            <section>
              {
                  userLoaded() &&
                  <>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                      <div className="commonH1">
                        {
                          userName(props.$store.userSession)
                        }
                      </div>
                      <div>
                        {
                            checkPermission(props, UserPermission.OWNER) &&
                            <UpdateUserFields />
                        }
                      </div>
                    </div>

                    <UserFields />

                    <BankDetailsView />

                    {
                      myTariffSection()
                    }

                    {
                      packagesView()
                    }

                    {
                      cardsView()
                    }

                    {
                      subscriptionsView()
                    }

                    {
                      requestBill()
                    }
                  </>
              }
            </section>
            <aside>
              {
                  userLoaded() &&
                  <>
                    {
                      enterPromoCodeView()
                    }

                    {
                      myPromoCodeView()
                    }

                    <IntegrationsView/>

                    {
                      notificationsView()
                    }

                    {
                      <UserListView $store={props.$store} $commitToStore={props.$commitToStore}/>
                    }
                  </>
              }
              {
                supportView()
              }

              {
                signOutView()
              }
            </aside>
          </div>
        </div>

        {
            promoCodeActivated &&
            <PromoActivatedPopup
                result={codeActivationResult}
                promoCode={promoCode}
                onClose={() => {
                  setPromoCodeActivated(false)
                }}
            />
        }

        {
          promoPopupShown &&
            <Popup onClose={() => setPromoPopupShown(false)}>
              <>
                <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      activatePromoCode()
                    }}
                >
                  <div className="popup-title">Активировать промо-код</div>
                  <div className="form-field _flexed">
                    <input
                        placeholder="Промо-код"
                        onChange={({ target: { value } }) => setPromoCode(value)} />
                  </div>
                  <button className="new withTitle _wa">Активировать</button>
                </form>
              </>
            </Popup>
        }

        {
          paymentNotification &&
            <PaymentStatus
                setPaymentNotification={setPaymentNotification}
                transactionId={$router.route.params.transactionId}
                success={$router.route.params.success}
            />
        }
      </div>
  )
}

ProfilePage = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
      $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ProfilePage)

export default ProfilePage
