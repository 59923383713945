import Popup from "../../components/Popup";
import "pages/Styles/TemplatesCommon.sass"
import {useMediaQuery} from "@mui/material";
import React from "react";
import {School} from "@mui/icons-material";
import WelcomeView from "../Profile/WelcomeView";
import SectionHeader from "../CreateContract/SectionHeader";
import {HelpView} from "../Common/HelpView";

export let TemplatesHelpPopup: any = function TemplatesHelpPopup(props: any) {
    const mobile = useMediaQuery("(max-width: 760px)");

    return <Popup
        style={{zIndex: 999}}
        onClose={props.onClose}
    >
        <WelcomeView $store={props.$store}/>
    </Popup>
}

export let TemplatesHelpView: any = function TemplatesHelpPopup(props: any) {
    return <HelpView
        title={"Что нужно сделать, чтобы подписать и отправить договор?"}
        subtitle={"Для начала вам нужен шаблон договора. Если у вас нет готового шаблона - то можете загрузить любой .docx файл (MS Word).\n" +
            "Посмотрите короткое видео о том, как это делается."}
        videoId={"0ugr9xC0eF4"}
        rutubeVideoId={"d379fca0fd22239f97424d8af7fc9000"}

    />
}

export let TemplateGuideView: any = function TemplateGuideView(props: {template: any, toggleExpanded: Function}) {
    const mobile = useMediaQuery("(max-width: 760px)");

    let template = props.template
    if (!template) {
        return
    }

    let guides = template.guides ?? []
    let guide = guides.filter((guide: any) => guide.device == (mobile ? "mobile" : "desk"))[0] ??
        guides.filter((guide: any) => guide.device == "any")[0]

    if (!guide) {
        return
    }

    function helpView() {
        let text: JSX.Element
        if (guide.video_id == "0ugr9xC0eF4") {
            text = <div>
                <div>{guide.text}</div>
                <br/>
                <div className={"commonSubtitle"}>
                    Так же вы можете <b>заказать настройку шаболона</b> у наших специалистов. Стоимость 1000 рублей. Заявку можете оставить
                    <a href={"https://doki.online/template?utm_source=template_guide"} target="_blank"> у нас на сайте ↗ </a>
                </div>
            </div>
        } else {
            text = <div>{guide.text}</div>
        }

        return <HelpView
            subtitle={text}
            videoId={guide.video_id}
            rutubeVideoId={guide.rutube_video_id}
        />
    }

    return <SectionHeader
        sectionId={guide}
        title={guide.title}
        expanded={!guide.closed}
        toggleSectionExpanded={props.toggleExpanded}
        icon={<School sx={{fill: "#75809E"}}/>}
        body={helpView()}
    />
}

export let ContractHelpPopup: any = function ContractHelpPopup(props: any) {
    const mobile = useMediaQuery("(max-width: 760px)");

    return <Popup
        style={{zIndex: 999}}
        onClose={props.onClose}
    >
        <ContractHelpView/>
    </Popup>
}

export let ContractHelpView: any = function ContractHelpView(props: any) {
    return <HelpView
        title={"Что нужно сделать, чтобы подписать договор?"}
        subtitle={
            <div>
                Вначале важно прочитать договор. Как только убедитесь, что все с договором в порядке и нет ошибок, жмите кнопку "Заполнить".
                <br/>
                <br/>
                Затем заполняете все нужные данные и нажимаете кнопку "Подписать"
                <br/>
                <br/>
                Последний штрих - введите код из SMS, и вуаля! Договор подписан!
                <br/>
                <br/>
                Если хотите узнать, как это все делается, посмотрите короткое видео. Там все понятно покажут!
                <br/>
                <br/>
            </div>

        }
        videoId={"686LW9zbfH0"}
        rutubeVideoId={"6d96fad49fd321ccbf3d1dd0c128cda8"}
    />
}