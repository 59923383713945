import Popup from "../../components/Popup";
import {connect} from "react-redux";
import React from "react";

let PromoActivatedPopup: any = function PromoActivatedPopup(props: any) {

    function parseActivationPromoCodeResult() {
        let result = props.result

        if (!result) return null;

        return result.map((el: any) => {
            if (el['key'] === 'template') {
                const templatesName = el['value'].map((el: any) => <div className="Body_15">"{el}"</div>)
                return <div className="Subheader_17" style={{marginTop: "15px"}}>
                    Добавлены шаблоны: {templatesName}
                </div>
            }
            if (el['key'] === 'default') {
                return <div className="Subheader_17" style={{marginTop: "15px"}}>
                    К вашему счёту добавлено {el['value']} договоров
                </div>
            }
            if (el['key'] === 'payment') {
                return <div>
                    <div className="Subheader_17" style={{marginTop: "15px"}}>
                        А так же вас ждёт бонус +{el.value.payment_add_documents_by_percent}% при оплате пакета! 🔥🔥🔥
                    </div>
                    <div className="Body_15">

                        Обратите внимание, бонус действует только в течении 24 часов!
                    </div>
                </div>
            }
        })
    }

    return <Popup
        maxWidth="650px"
        onClose={props.onClose}
    >
        <div>
            <div className="H3_21" style={{textAlign: "center"}}>
                Промо-код {props.promoCode} активирован!
            </div>

            <div style={{marginTop: "15px"}}>
                {
                    parseActivationPromoCodeResult()
                }
            </div>

            <hr className="newHR"/>

            <button
                className="new withTitle _wa"
                onClick={props.onClose}
            >
                Супер!
            </button>
        </div>
    </Popup>
}

PromoActivatedPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(PromoActivatedPopup)

export default PromoActivatedPopup