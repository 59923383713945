import {connect} from "react-redux";
import React from "react";

let WelcomeView: any = function WelcomeView(props: any) {
    let userSession = props.$store?.userSession
    let promo = userSession?.active_promo_code

    return <div>
        <div className="commonTitle">
            Добро пожаловать в сервис ОкиДоки!
        </div>

        <hr className="newHR"/>

        {
            promo &&
            <div>
                <div className="Subheader_17">
                    У вас активирован промо-код.
                </div>
                <div className="Body_15" style={{marginTop: "5px"}}>
                    По нему вы получили набор шаблонов и приятный бонус к покупке, который действует 24 часа.
                    Поэтому советуем сразу определиться с подходящим вам тарифом. Посмотреть тарифы можно в профиле.
                </div>

                <div className="Subheader_17" style={{marginTop: "20px"}}>
                    Как выставить на подпись договор?
                </div>

                <div className="Body_15" style={{marginTop: "5px"}}>
                    Нажмите на нужный вам шаблон из списка.
                    Внутри вы увидите сам шаблон и инструкцию, как им пользоваться
                </div>
            </div>
        }
        {
            !promo &&
            <div>
                <div className="Subheader_17">
                    Чтобы выставить свой первый договор, вам нужен шаблон!
                </div>

                <div className="Body_15" style={{marginTop: "5px"}}>
                    Вы можете загрузить свой шаблон (в формате .docx) и настроить его, руководствуясь
                    <a href="https://rutube.ru/video/d379fca0fd22239f97424d8af7fc9000/" target="_blank"> нашим видео.</a>
                    <br/>
                    <br/>
                    Если у вас возникают трудности с настройкой шаблона - можете обратиться в техподдержку, либо
                    <a href="https://doki.online/template" target="_blank"> оставить заявку на настройку вашего шаблона.</a>

                </div>
                <div className="Body_15" style={{marginTop: "10px"}}>
                    Или можете воспользоваться нашими готовыми договорами для отдельных ниш бизнеса. Для этого нажмите на кнопку "Шаблон" и выберите подходящий вам шаблон
                </div>
            </div>
        }

        <div>
            <div className="Subheader_17" style={{marginTop: "20px"}}>
                А есть ли приложение?
            </div>

            <div className="Body_15" style={{marginTop: "5px"}}>
                Приложений как таковых нет, но можно вынести наш мобильный сайт на рабочий стол вашего смартфона. Вот видео инструкции для
                <a href="https://rutube.ru/video/dd0ec1cff60ac989e41bf8ef780fd624/" target="_blank"> Android </a>
                и для
                <a href="https://rutube.ru/video/aa87aaf3b320a830227c61c3f4471e5b/" target="_blank"> iPhone </a>
            </div>
        </div>

        <div>
            <div className="Subheader_17" style={{marginTop: "20px"}}>
                Сколько стоит и как оплатить?
            </div>
            <div className="Body_15" style={{marginTop: "5px"}}>
                Переходите на вкладку профиль и выбирайте тариф. Оплата банковской картой.
            </div>
        </div>

        <hr className={"newHR"}/>

        <div className="Body_15" style={{marginTop: "5px"}}>
            Подпишитесь на наши каналы в
            <a href="https://t.me/okidoki_digital_docs" target="_blank"> Telegram </a>
            и в
            <a href="https://youtube.com/playlist?list=PLnTJBQfQ5OcGQjhDr8pW1_uMObiXxhQtK&si=nGVnIqbF49xuLiAI" target="_blank"> Youtube</a>
            , чтобы быть в курсе новостей сервиса
        </div>
    </div>
}

WelcomeView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(WelcomeView)

export default WelcomeView