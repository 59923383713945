import cleanPhoneNumber from "../../methods/cleanPhoneNumber";
import _ from "lodash";
import {
    clientBankAccount,
    clientBankName, clientBIC, clientCorrAccount,
    clientEmail,
    clientFirstName,
    clientLastName, clientLawAddress, clientLawName,
    clientMiddleName, clientOGRN,
    clientPassportIssuedAt,
    clientPassportIssuedBy,
    clientPassportIssuedByCode,
    clientPassportNumber,
    clientPassportPhoto,
    clientPassportRegistration,
    clientPassportRegistrationPhoto,
    clientPassportSelfiePhoto,
    clientPassportType, clientPhoneNumber, clientRole, clientTaxId, PassportType, storeClientData,
    SystemEntity
} from "../../methods/SystemEntity";
import {
    contractSystemEntity,
    dateFromIsoToPlain,
    systemEntityValueByKeyword
} from "../../methods/systemEntityValueByKeyword";
import {clientRoles} from "./UserForms/ChooseAccountTypeFrom";

export function defaultUserFormForContractCreating(contract: any) {
    let clientPassportTypeEntity = contractSystemEntity(contract, clientPassportType.id) ?? clientPassportType
    let clientPassport = clientPassportTypeEntity.value ?? ""
    if (clientPassport.length == 0) {
        clientPassport = clientPassportTypeEntity.selectedValues[0] ?? PassportType.russianPassport.toString()
    }

    // console.log("clientPassportTypeEntity", clientPassportTypeEntity)
    return {
        ...defaultUserFormForSigning(false, contract, {}),
        client_last_name: contractSystemEntity(contract, clientLastName.id)?.value ?? "",
        client_first_name: contractSystemEntity(contract, clientFirstName.id)?.value ?? "",
        client_middle_name: contractSystemEntity(contract, clientMiddleName.id)?.value ?? "",
        client_passport_type: clientPassport,
        client_role: contractSystemEntity(contract, clientRole.id)?.value ?? ''
    }
}

export function defaultUserFormForSigning(is_first_signer: boolean, contract: any, user: any) {
    function userProperty(entity: SystemEntity): string {
        return _.get(user, entity.userPath, '')
    }
    
    return {
        client_last_name: is_first_signer ? userProperty(clientLastName) : defaultLastname(contract, user),
        client_first_name: is_first_signer ? userProperty(clientFirstName) : defaultFirstname(contract , user),
        client_middle_name: is_first_signer ? userProperty(clientMiddleName) : defaultMiddleName(contract , user),

        client_passport_type: is_first_signer ? userProperty(clientPassportType) : defaultPassportType(contract , user),
        client_passport_number: is_first_signer ? userProperty(clientPassportNumber) : defaultPassportNumber(contract , user),
        client_passport_issued_by_code: is_first_signer ? userProperty(clientPassportIssuedByCode) : defaultIssuedByCode(contract , user),
        client_passport_issued_at: is_first_signer ? userProperty(clientPassportIssuedAt) : defaultIssuedAt(contract , user),
        client_passport_issued_by:  is_first_signer ? userProperty(clientPassportIssuedBy) : defaultIssuedBy(contract , user),
        client_passport_registration:  is_first_signer ? userProperty(clientPassportRegistration) : defaultRegistration(contract , user),

        client_passport_photo: is_first_signer ? userProperty(clientPassportPhoto) : defaultPassportPhoto(contract , user),
        client_passport_selfie_photo: is_first_signer ? userProperty(clientPassportSelfiePhoto) : defaultPassportSelfiePhoto(contract , user),
        client_passport_registration_photo: is_first_signer ? userProperty(clientPassportRegistrationPhoto) : defaultPassportRegistrationPhoto(contract , user),

        client_law_name: is_first_signer ? userProperty(clientLawName) : defaultLawName(contract , user),
        client_law_address: is_first_signer ? userProperty(clientLawAddress) : defaultLawAddress(contract , user),
        client_tax_id: is_first_signer ? userProperty(clientTaxId) : defaultTaxId(contract , user),
        client_ogrn: is_first_signer ? userProperty(clientOGRN) : defaultOgrn(contract , user),

        client_bank_name: is_first_signer ? userProperty(clientBankName) : defaultBankName(contract , user),
        client_bank_account: is_first_signer ? userProperty(clientBankAccount) : defaultBankAccount(contract , user),
        client_corr_account: is_first_signer ? userProperty(clientCorrAccount) : defaultCorrAccount(contract , user),
        client_bic: is_first_signer ? userProperty(clientBIC) : defaultBic(contract , user),

        client_email: is_first_signer ? userProperty(clientEmail) : defaultEmail(contract, user),
        client_phone_number: is_first_signer ? userProperty(clientPhoneNumber) : defaultPhoneNumber(contract, user),

        client_role: defaultRole(contract, user),

        store_client_data: defaultStoreUserDataValue(contract, is_first_signer),
    }
}

export function defaultRole(contract: any, user: any) {
    if (user) {
        return user.role
    }
    let contractClientRole = contractSystemEntity(contract, clientRole.id) ?? clientRole
    if (contractClientRole.value) {
        return contractClientRole.value
    }
    return contract ? clientRoles[1] : clientRoles[0]
}

export function defaultStoreUserDataValue(contract: any, is_first_signer: boolean): boolean {
    if (!contract) {
        return true
    }

    if (contract.is_mass_template) {
        return true
    }

    if (is_first_signer || !contract.first_signer) {
        return true
    }

    let store_client_data = contractSystemEntity(contract, storeClientData.id)?.value
    if (typeof store_client_data !== 'undefined') {
        return store_client_data
    }

    return true
}

export function defaultLastname(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientLastName
    )
}

export function defaultFirstname(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientFirstName
    )
}

export function defaultMiddleName(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientMiddleName
    )
}

export function defaultEmail(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientEmail
    )
}

export function defaultPhoneNumber(contract: any, user: any) {
    let clientPhoneNumberEntity = contractSystemEntity(contract, clientPhoneNumber.id)
    if (user && user.phone_number && clientPhoneNumberEntity?.editable != false) {
        return cleanPhoneNumber(user.phone_number, true)
    } else {
        return cleanPhoneNumber(clientPhoneNumberEntity?.value ?? '', true)
    }
}

export function defaultPassportType(contract: any, user: any) {
    let passport_type = defaultPropertyValue(
        contract,
        user,
        clientPassportType
    )
    if (passport_type && passport_type.length > 0) {
        return passport_type
    }

    return passportTypeFromSelectedValues(contract)
}

export function passportTypeFromSelectedValues(contract: any): PassportType {
    let entity = contractSystemEntity(contract, clientPassportType.id) ?? clientPassportType
    let selectedValues = entity.selectedValues ?? []

    if (selectedValues.length == 0) {
        return PassportType.russianPassport
    }

    if (selectedValues.length == 1) {
        return selectedValues[0]
    }

    return selectedValues.includes(PassportType.russianPassport) ? PassportType.russianPassport : selectedValues[0]
}

export function defaultPassportNumber(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientPassportNumber
    )
}

export function defaultIssuedByCode(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientPassportIssuedByCode
    )
}

export function defaultIssuedAt(contract: any, user: any) {
    let value = defaultPropertyValue(
        contract,
        user,
        clientPassportIssuedAt
    )
    return dateFromIsoToPlain(value)
}

export function defaultIssuedBy(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientPassportIssuedBy
    )
}

export function defaultRegistration(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientPassportRegistration
    )
}

export function defaultPassportPhoto(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientPassportPhoto
    )
}

export function defaultPassportSelfiePhoto(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientPassportSelfiePhoto
    )
}

export function defaultPassportRegistrationPhoto(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientPassportRegistrationPhoto
    )
}

export function defaultLawName(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientLawName
    )
}

export function defaultLawAddress(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientLawAddress
    )
}

export function defaultTaxId(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientTaxId
    )
}

export function defaultOgrn(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientOGRN
    )
}

export function defaultBankName(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientBankName
    )
}

export function defaultBankAccount(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientBankAccount
    )
}

export function defaultCorrAccount(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientCorrAccount
    )
}

export function defaultBic(contract: any, user: any) {
    return defaultPropertyValue(
        contract,
        user,
        clientBIC
    )
}



export function defaultPropertyValue(
    contract: any,
    user: any,
    systemEntity: SystemEntity | null
) {
    if (!systemEntity) {
        return ''
    }
    let value = systemEntityValueByKeyword(contract, systemEntity.keyword, systemEntity.id)

    if (value && value.length > 0) {
        return value
    } else {
        if (systemEntity.userPath && systemEntity.userPath.length > 0) {
            return _.get(user, systemEntity.userPath, '')
        } else {
            return ''
        }
    }
}