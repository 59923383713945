import React, { useState } from "react"
import { connect } from "react-redux"
import "styles/pages/profile.sass"

import { $moment } from "funcs"

let UserFields: any = function UserFields(props: any) {
  function makeEntrepreneurDataView() {
    return <div className={"templateViewContainer disabledHover marginTop"}>
      <div className="commonLabel" style={{textAlign: "left"}}>
        Данные юр. лица:
      </div>

      <div className="common" style={{marginTop: "20px"}}>
        <div className="commonSubtitle">Полное наименование юр. лица:</div>
        <div className="commonRegular15">{props.$store.userSession.law_name}</div>
      </div>

      <div className="common" style={{marginTop: "20px"}}>
        <div className="commonSubtitle">Адрес регистрации:</div>
        <div className="commonRegular15">{props.$store.userSession.law_address}</div>
      </div>

      {
          props.$store.userSession.tax_id &&
          <div className="common" style={{marginTop: "20px"}}>
            <div className="commonSubtitle">ИНН:</div>
            <div className="commonRegular15">
              {props.$store.userSession.tax_id}
            </div>
          </div>
      }

      {
          props.$store.userSession.ogrn?.length > 0 &&
          <div className="common" style={{marginTop: "20px"}}>
            <div className="commonSubtitle">ОГРН:</div>
            <div className="commonRegular15">
              {props.$store.userSession.ogrn}
            </div>
          </div>
      }
    </div>
  }

  function passportView() {
    const passport = props.$store.userSession.passport;

    if (!passport) {
      return null
    }

    if (!passport.number) {
      return null
    }

    return <div className={"templateViewContainer disabledHover marginTop"}>
      <div className="commonLabel" style={{textAlign: "left"}}>
        Паспортные данные:
      </div>

      <div className="common" style={{marginTop: "20px"}}>
        <div className="commonSubtitle">Серия и номер:</div>
        <div className="commonRegular15">{passport.number}</div>
      </div>
      {
        passport.issued_by_code &&
        <div className="common" style={{marginTop: "15px"}}>
          <div className="commonSubtitle">Код подразделения:</div>
          <div className="commonRegular15">{passport.issued_by_code}</div>
        </div>
      }
      {
          passport.issued_at &&
          <div className="common" style={{marginTop: "15px"}}>
            <div className="commonSubtitle">Дата выдачи паспорта:</div>
            <div className="commonRegular15">{$moment(passport.issued_at).format('LL')}</div>
          </div>
      }
    </div>
  }

  // Render
  return (
    <div>
      <div className={"templateViewContainer disabledHover"}>
        <div className="commonLabel" style={{textAlign: "left"}}>
          Основная информация:
        </div>
        <div className="common" style={{marginTop: "20px"}}>
          <div className="commonSubtitle">Номер телефона:</div>
          <div className="commonRegular15">{props.$store.userSession.phone_number}</div>
        </div>
        {
            props.$store.userSession.additional_phone_number &&
            <div className="common" style={{marginTop: "20px"}}>
              <div className="commonSubtitle">Телефон для клиентов:</div>
              <div className="commonRegular15">{props.$store.userSession.additional_phone_number}</div>
            </div>
        }

        <div className="common" style={{marginTop: "20px"}}>
          <div className="commonSubtitle">E-Mail:</div>
          <div className="commonRegular15">{props.$store.userSession.email}</div>
        </div>

        {
            props.$store.userSession.role == 'customer' && props.$store.userSession.law_address &&
            <div className="common" style={{marginTop: "20px"}}>
              <div className="commonSubtitle">Адрес прописки:</div>
              <div className="commonRegular15">{props.$store.userSession.law_address}</div>
            </div>
        }

        {
            props.$store.userSession.role == 'customer' && props.$store.userSession.tax_id &&
            <div className="common" style={{marginTop: "20px"}}>
              <div className="commonSubtitle">ИНН:</div>
              <div className="commonRegular15">{props.$store.userSession.tax_id}</div>
            </div>
        }
      </div>

      {
          props.$store.userSession.role == 'entrepreneur' &&
          makeEntrepreneurDataView()
      }

      {
        passportView()
      }
    </div>
  )
}

UserFields = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
      $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(UserFields)

export default UserFields
