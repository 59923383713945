import {useRoute} from "react-router5";
import {CircularProgress, useMediaQuery} from "@mui/material";
import {connect} from "react-redux";
import React from "react";

export let PageLoader: any = function PageLoader(props: any) {
    return <div className="centeredLoader" style={{marginTop: "130px"}}>
        <CircularProgress sx={{color: "#1FA3EE"}} />
    </div>
}

export let ListLoader: any = function ListLoader(props: any) {
    return <div className="centeredLoader" style={{marginTop: "15px"}}>
        {
            props.loading &&
            <CircularProgress sx={{color: "#1FA3EE"}} />
        }
        {
            !props.loading &&
            <div style={{height: "40px"}}/>
        }
    </div>
}