import Popup from "../../components/Popup";
import {connect} from "react-redux";
import React, {useState} from "react";
import {TextareaStyled} from "../Styles/CommonCSSProperties";

type Props = {
    title: string,
    placeholder: string,
    oldText: string,
    onClose: Function,
    canSaveEmpty: boolean,
    saveNewName: Function
}

let EnterTextPopup: any = function EntityFormPopup(props: Props) {
    const {title, placeholder, oldText, onClose, canSaveEmpty, saveNewName} = props
    const [newText, setNewText] = useState(oldText)

    return <Popup
        style={{zIndex: 999}}
        onClose={onClose}
    >
        <div>
            <div className="commonLabel" style={{marginTop: "25px", textAlign: "center"}}>
                {title}
            </div>
            <div className="form-field">
                <TextareaStyled
                    value={newText}
                    maxRows={1}
                    onKeyPress={(e: any) => {
                        if(e.key === 'Enter')
                            e.preventDefault()
                    }}
                    placeholder={placeholder}
                    onChange={({target: {value}}: any) => {
                        setNewText(value);
                    }} 
                />
            </div>

            <hr className="newHR"/>

            <button
                className="new withTitle _wa"
                disabled={canSaveEmpty ? false : newText.trim().length == 0}
                onClick={() => {
                    saveNewName(newText.trim())
                }}
            >
                Сохранить
            </button>
        </div>
    </Popup>
}

EnterTextPopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(EnterTextPopup)

export default EnterTextPopup