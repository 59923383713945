import { httpClient, nError } from "funcs"
import React, { useEffect, useRef, useState} from "react"
import { connect } from "react-redux"
import _ from "lodash";

import "styles/MUI-style.sass"

import { useRoute } from "react-router5"

import {useMediaQuery} from "@mui/material";
import Editor from "../../../components/Editor";

import "pages/Styles/EditContractCommon.sass"
import "pages/Styles/EditContractEditor.sass"
import "pages/Styles/EditContractEntities.sass"

import {ReactComponent as ArrowUp} from "pages/Icons/size16/ArrowUp.svg"
import {Entity, Flow} from "../../Entity/Entity";
import ContractViewHeader from "./ContractViewHeader";
import {addValueToSpanTags, clearValueInSpanTags} from "../../../methods/addValueToSpanTags";
import {validateContractBeforeSave} from "../../../methods/emptyEntities";
import {scrollToElement} from "../../../methods/scrollTo";
import {PageLoader} from "../ContractList/PageLoader";
import {ContractHelpView} from "../../Templates/TemplatesHelpPopup";
import ContractFooter from "../../EditContract/ContractFooter";
import {calculateFormula} from "../../../methods/parseFormula";
import { Document, Page, pdfjs } from 'react-pdf';
import NewContractPopup from "../../EditContract/NewContractPopup";
import {clearFormattingForEntities} from "../../../methods/clearFormattingForEntities";
import {updateLocalEntities} from "../../../methods/updateLocalEntities";
import {systemEntityValueByKeyword} from "../../../methods/systemEntityValueByKeyword";
import {addSpanTagsToBody} from "../../../methods/addSpanTagsToBody";
import {checkChangesInBody} from "../../../methods/checkChangesInBody";
import {notifyAboutError} from "../../../methods/notifyUser";
import {ContractStatus} from "../../../methods/ContractStatus";
import {updateEntityInEditor} from "../../../methods/updateEntityInEditor";
import {getPostfixForEntity, mobileMaxWidthMediaQuery} from "../../../methods/utils";
import {addEntities, deleteEntities, renameEntity, setOrderForEntity} from "../../../methods/workWithEntities";
import {firstSignerTitle, targetUserTitle} from "../../../methods/workWithContract";
import {clientFIO} from "../../../methods/SystemEntity";
import Entities from "../../Entity/Entities";
import {HelpView} from "../../Common/HelpView";

let ContractViewPage: any = function ContractViewPage(props: any) {
    const $router = useRoute();

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    });

    const [contract, setContract] = useState<any>(null)
    const [editingEntity, setEditingEntity] = useState<string | null>(null)
    const [isEditing, setIsEditing] = useState(false)
    const [savingStatus, setSavingStatus] = useState("idle")
    const [loading, setLoading] = useState(false);
    const [newContractPopup, setNewContractPopup] = useState(false)
    const [needUpdateFormulas, setNeedUpdateFormulas] = useState(false)
    const [needUpdateOrder, setNeedUpdateOrder] = useState<any>(null)

    const {contractId} = $router.route.params;

    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)

    function updateFormulas() {
        for (const entity of contract.entities) {
            if (entity.type == "Формула") {
                updateFormula(entity.keyword)
            }
        }
        setNeedUpdateFormulas(false)
    }

    function updateFormula(keyword: string) {
        let entities = [...contract.entities]
        const entityIndex = contract.entities.findIndex(
            (e: any) => e.keyword === keyword
        );
        let entity = entities[entityIndex]

        entities[entityIndex].value = calculateFormula(contract, entity)
        entities[entityIndex].postfix = getPostfixForEntity(entity)

        setContract({...contract, entities})
        updateEntityInEditor(entity)
    }

    function updateEntity(isSystem: boolean, entity: any) {
        // console.log("updateEntity in page", entity)
        let entities = [...contract.entities]
        const entityIndex = entities.findIndex(
            (e: any) => e.keyword === entity.keyword,
        );
        entities[entityIndex] = entity
        setContract({
            ...contract,
            entities: entities,
        });
        
        setTimeout(
            () => {
                updateEntityInEditor(entity)
            },
            100
        )

        // setIsChanging('changing')
    }

    async function approveContract() {
        setLoading(true)
        let savedContract = contract;
        setContract(null)
        try {
            const {data: approvedContract} = await httpClient.put(`/contracts/${contractId}/approve`);

            if (_.get(approvedContract, "status.internal_id", null) === 2) {
                props.$commitToStore({
                    notification: {
                        title: "Договор подписан и данные подтверждены",
                    }
                })
                location.reload()
            } else {
                props.$commitToStore({
                    notification: {
                        title: "Не удалось подтвердить введённые данные",
                    }
                })
            }
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setLoading(false)
            setContract(savedContract)
        }
    }

    // Load template function
    async function loadContract(loadContractId: string | null = null, user: any | null = null) {
        setLoading(true)
        try {
            const {data: template} = await httpClient.get(`/contracts/${loadContractId || contractId}`, {
                params: {
                    userId: user?._id ?? (props.$store.userSession && props.$store.userSession["_id"]),
                },
            });

            let entities = [...template.entities]
            for (let entity of entities) {
                entity.needToFillBeforeSign = (entity.value?.toString() ?? "").length == 0 && !!entity.clientCanFill
            }
            template.entities = entities

            if (template?.is_mass_template === true) {
                template.system_entities = updateLocalEntities(template, props.$store.userSession)
            }

            if (props.$store.userSession?._id == template.first_signer?._id &&
                (template.status?.internal_id == ContractStatus.Draft || template.status?.internal_id == ContractStatus.Awaiting)) {
                template.system_entities = updateLocalEntities(template, props.$store.userSession)
            }

            if (template.status?.internal_id != ContractStatus.Signed) {
                template.body = addSpanTagsToBody(template, template.body)
                template.body = addValueToSpanTags(template, template.body)
            }
            setContract(template);

            if (template.status?.internal_id == ContractStatus.Draft) {
                setIsEditing(true)
            }
            setNeedUpdateFormulas(true)
        } catch (error) {
            notifyAboutError(props, error)
            if ((error as any).response?.status == 404) {
                setTimeout(() => { $router.router.navigate('main') }, 300)
            }
        } finally {
            setLoading(false)
        }
    }

    async function updateContract(completion?: Function) {
        // Fetch data from templateForm
        let {body} = contract;
        let shouldShowPopup = contract?.is_mass_template === true || contract.status.internal_id === 0

        if (body.length == 0) {
            return
        }

        setSavingStatus("saving")
        try {
            // Checks
            if (!body) {
                throw new Error("Документ не может быть пустым");
            }

            validateContractBeforeSave(contract, true)

            body = clearFormattingForEntities(body)
            body = addSpanTagsToBody(contract, body)

            let entities = [...contract.entities]
            for (let entity of entities) {
                clearValueInSpanTags(contract.body, entity)
            }

            body = addValueToSpanTags(contract, body)

            await httpClient.put(`/contract/${contract._id}`, {
                body: body,
                entities: JSON.stringify(entities),
                system_entities: JSON.stringify(contract.system_entities),
                settings: JSON.stringify(contract.settings ?? {}),
                actual_user_card_id: contract.actual_user_card_id
            });
            setIsEditing(false)
            if (shouldShowPopup) {
                setNewContractPopup(true)
            }
            setSavingStatus('saved')
            setTimeout(
                () => {setSavingStatus("idle")},
                3000
            )
            if (completion) {
                completion()
            }
        } catch (error) {
            notifyAboutError(props, error)
        }
    }

    // Load template
    useEffect(() => {
        loadContract();
    }, []);

    useEffect(() => {
        if (contract && needUpdateFormulas) {
            updateFormulas()
        }
        if (contract && needUpdateOrder) {
            _setOrderForEntity(needUpdateOrder.keyword, needUpdateOrder.order)
        }
    }, [contract?.entities, needUpdateFormulas, needUpdateOrder])

    const [scrolledFrom, setScrolledFrom] = useState<string | null>(null)
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);
        if (scrolledFrom) {
            window.removeEventListener('scroll', onScroll);
            window.addEventListener('scroll', onScroll, { passive: true });
            return () => window.removeEventListener('scroll', onScroll);
        } else {
            window.removeEventListener('scroll', onScroll)
        }
    }, [scrolledFrom]);

    useEffect(() => {
        if (scrolledFrom && Math.abs(offset - startOffset) > 500) {
            setScrolledFrom(null)
        }
    }, [offset])

    // Rerender function
    function getBody() {
        return contract.body ?? ""
    }

     async function saveEditedVersion() {
        let needReload = contract?.status?.internal_id !== ContractStatus.Draft
        await updateContract(() => {
            setTimeout(() => {
                if (needReload) {
                    location.reload()
                }
            }, 300)
        })
    }

    function cancelEditing() {
        setIsEditing(false)
    }

    function startEditing() {
        setIsEditing(true)
    }

    function canSign() {
        return contract.created_by._id != props.$store.userSession?._id && (!contract.target_user || !contract.first_signer)
    }

    function _addEntities(isSystem: boolean, entities: any[]) {
        let updatedContract = addEntities(contract, isSystem, entities)
        setContract(updatedContract)
    }

    function _deleteEntities(isSystem: boolean, keywords: string[]) {
        let updatedContract = deleteEntities(contract, isSystem, keywords)
        setContract(updatedContract)
    }

    function _renameEntity(oldKeyword: string, newKeyword: string) {
        let updatedContract = renameEntity(contract, oldKeyword, newKeyword)
        setContract(updatedContract)
    }

    function _setOrderForEntity(keyword: string, order: number) {
        let updatedContract = setOrderForEntity(contract, keyword, order)
        setContract(updatedContract)
        setNeedUpdateOrder(null)
    }

    function showSection() {
        if (!contract) {
            return false
        }

        if (canSign()) {
            return true
        }

        if (contract.created_by._id == props.$store.userSession?._id) {
            //исходящий
            return true
        } else {
            //входящий
            if (contract.body == "") {
                return false
            } else {
                return (contract?.status?.internal_id === 2) && contract.entities.length > 0
            }
        }
    }


    let editor = useRef<any>()
    const [numPages, setNumPages] = useState(0);

    function makeEditor() {
        if (!contract.body) {
            let targetUserIsMe = contract.target_user?._id == props.$store.userSession?._id
            return <HelpView
                title={targetUserIsMe ? "Вы не дали разрешение на хранение ваших персональных данных." : "Ваш клиент не разрешил хранить его персональные данные"}
                subtitle={`Поэтому договор сохраняется на вашей электронной почте ${props.$store.userSession?.email ?? ""} и на почте ${targetUserIsMe ? (firstSignerTitle(contract)) : (targetUserTitle(contract))}. Пожалуйста, убедитесь, что сообщения от нашего сервиса не попадают в папку со спамом. Посмотрите небольшое видео о том, как это проверить. Обратите внимание, иногда письмо с договором приходит не сразу, а через некоторое время. Если договор так и не пришел, напишите к нам в поддержку`}
                videoId={"L8QGx9jMWGc"}
            />
        }
        if (contract.body_type == 'pdf') {
            let contentType = "application/pdf";
            let body = contract.body.slice(2).slice(0,-1)
            body = "data:" + contentType + ";base64," + body
            let width = mobile ? window.innerWidth - 32 : 622

            let pages: number[] = Array.from(Array(numPages).keys())
            return <div>
                <Document
                    onLoadSuccess={(result) => {
                        setNumPages(result.numPages)
                    }}
                    file={body}
                >
                    {
                        numPages && pages.map((i) =>
                            <div style={{marginBottom: "15px"}}>
                                <Page
                                    pageNumber={i + 1}
                                    renderTextLayer={false}
                                    renderAnnotationLayer={false}
                                    width={width}
                                />
                            </div>
                        )
                    }
                </Document>
                {
                    makeFooter()
                }
            </div>
        }
        return <div id="editor">
            <Editor
                readonly={!isEditing}
                body={getBody()}
                onBlur={(newValue) => {
                    for (const entity of contract.entities) {
                        updateEntityInEditor(entity, contract.entities)
                    }
                    for (const entity of contract.system_entities) {
                        updateEntityInEditor(entity)
                    }
                }}
                onChange={(value) => {
                    let updatedContract = checkChangesInBody(props, contract)
                    let newBody = addSpanTagsToBody(updatedContract, value)
                    setContract({
                        ...updatedContract,
                        body: newBody
                    })
                }}
            />
            {
                makeFooter()
            }
        </div>
    }

    function makeFooter() {
        let isMassTemplate = contract?.is_mass_template === true
        let footerInBody = ContractStatus.footerInBody.includes(contract?.status?.internal_id)
        if (isMassTemplate || !footerInBody) {
            return <div className="editContractFooterContainer">
                <ContractFooter
                    template={contract}
                    showTip={false}
                    generateStamp={!footerInBody}
                    $store={props.$store}
                />
            </div>
        }
    }
    // Render
    return <div className="ContractViewPage" style={{maxWidth: showSection() ? "1090px" : "654px"}}>
        <div className="NewContractPage_actNew">
            {
                loading && !contract &&
                <PageLoader />
            }
            {
                contract &&
                <div>
                    <div>
                        <ContractViewHeader
                            prevRoute={$router.previousRoute}
                            contract={contract}
                            savingStatus={savingStatus}
                            isEditing={isEditing}
                            loadContract={loadContract}
                            saveEditedVersion={async () => {
                                await saveEditedVersion()
                            }}
                            cancelEditing={cancelEditing}
                            startEditing={startEditing}
                            updateEntity={(isSystem: boolean, entity: any) => {
                                updateEntity(isSystem, entity)
                                setNeedUpdateFormulas(true)
                            }}
                            approveContract={approveContract}
                            $commitToStore={props.$commitToStore}
                            $store={props.$store}
                        />
                    </div>
                    <div className="editContractConstructor readOnly">
                        {
                            showSection() && <>
                                <section style={{maxWidth: "653px"}}>
                                    {
                                        makeEditor()
                                    }
                                </section>
                                <aside>
                                    <div>
                                        {
                                            canSign() && !mobile &&
                                            <div className="templateViewContainer">
                                                <ContractHelpView/>
                                            </div>
                                        }

                                        {
                                            !canSign() &&
                                            <>
                                                {
                                                    isEditing &&
                                                    <Entities
                                                        template={contract}
                                                        updateEntity={(isSystem: boolean, entity: any) => {
                                                            setNeedUpdateFormulas(true)
                                                            _addEntities(isSystem, [entity])
                                                        }}
                                                        addEntities={(isSystem: boolean, entities: any[]) => {
                                                            setNeedUpdateFormulas(true)
                                                            _addEntities(isSystem, entities)
                                                        }}
                                                        deleteEntities={(isSystem: boolean, keywords: string[]) => {
                                                            setNeedUpdateFormulas(true)
                                                            _deleteEntities(isSystem, keywords)
                                                        }}
                                                        renameEntity={_renameEntity}
                                                        setOrderForEntity={(keyword: string, order: number) => {
                                                            setNeedUpdateOrder({keyword: keyword, order: order})
                                                        }}
                                                        didTapScroll={(keyword: string) => {
                                                            setTimeout(
                                                                () => {
                                                                    setScrolledFrom(keyword)
                                                                    setStartOffset(window.scrollY)
                                                                    setOffset(window.scrollY)
                                                                },
                                                                1000
                                                            )
                                                        }}
                                                        toggleGuideExpanded={() => {}}
                                                        updateActualUserCardId={(actual_user_card_id: string | null) => {
                                                            setContract({...contract, actual_user_card_id: actual_user_card_id})
                                                        }}
                                                        updateContractSettings={(settings: any) => {
                                                            setContract({...contract, settings: settings})
                                                        }}
                                                        isTemplate={false}
                                                        $store={props.$store}
                                                    />
                                                }
                                                {
                                                    !isEditing &&
                                                    <div className="editContractEntitiesContainer">
                                                        <div className="titleContainer">
                                                            <div className="editContractEntitiesTitle">
                                                                Мои Поля
                                                            </div>
                                                        </div>
                                                        {
                                                            contract.entities.map((entity: any, i: number) =>
                                                                <Entity
                                                                    key={entity.keyword}
                                                                    template={contract}
                                                                    entity={entity}
                                                                    isSystem={false}
                                                                    isEditing={editingEntity == entity.keyword}
                                                                    setIsEditing={(editing: boolean) => {
                                                                        setEditingEntity(editing ? entity.keyword : null)
                                                                    }}
                                                                    update={(isSystem: boolean, entity: any) => {
                                                                        setNeedUpdateFormulas(true)
                                                                        updateEntity(isSystem, entity)
                                                                    }}
                                                                    didTapScroll={(keyword: string) => {
                                                                        setTimeout(
                                                                            () => {
                                                                                setScrolledFrom(keyword)
                                                                                setStartOffset(window.scrollY)
                                                                                setOffset(window.scrollY)
                                                                            },
                                                                            1000
                                                                        )
                                                                    }}
                                                                    flow={Flow.ReadOnly}
                                                                />,
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </>
                                        }
                                        {
                                            !isEditing && !canSign() &&
                                            <div className="editContractEntitiesContainer" style={{marginTop: "15px"}}>
                                                <div className="titleContainer">
                                                    <div className="editContractEntitiesTitle">
                                                        Автозаполняемые Поля
                                                    </div>
                                                </div>
                                                {
                                                    contract.system_entities
                                                        .filter((entity: any) =>
                                                            !!entity.value &&
                                                            !entity.hidden &&
                                                            (typeof entity.value === 'string') &&
                                                            entity.id != clientFIO.id
                                                        )
                                                        .map((entity: any, i: number) =>
                                                            <Entity
                                                                key={entity.keyword}
                                                                template={contract}
                                                                entity={entity}
                                                                isSystem={true}
                                                                flow={isEditing ? Flow.InputSystem : Flow.ReadOnly}
                                                            />,
                                                        )
                                                }
                                            </div>
                                        }
                                    </div>
                                </aside>
                            </>
                        }
                        {
                            !showSection() &&
                            makeEditor()
                        }
                    </div>
                </div>
            }

            {
                scrolledFrom &&
                <div
                    className="scrollView"
                    onClick={() => {
                        let kw = `entityView.${scrolledFrom}`;
                        scrollToElement(kw)
                        setScrolledFrom(null)
                    }}
                >
                    <div>
                        Вернуться к редактированию Поля
                    </div>
                    <ArrowUp className="smallIcon" style={{fill: "white"}}/>
                </div>
            }


            {
                newContractPopup &&
                <NewContractPopup
                    contractId={contract._id}
                    shouldSign={true}
                    settings={
                        {
                            instructions: contract.settings ?? {
                                pre_sign:
                                    'Высылаем вам договор на подписание.\n' +
                                    'Для подписания договора необходимо пройти по ссылке и внимательно ознакомиться с текстом договора, затем зарегистрироваться или авторизоваться на сайте и ввести все необходимые данные.\n' +
                                    'Ваш экземпляр договора придёт вам на почту после подписания 😊\n' +
                                    '\n' +
                                    'Если у вас возникнут вопросы, можете посмотреть небольшое обучающее видео - https://rutube.ru/video/6d96fad49fd321ccbf3d1dd0c128cda8/ или задать вопросы нам, мы с радостью на них ответим.',
                            }
                        }
                    }
                    clientPhoneNumber={systemEntityValueByKeyword(contract, "Телефон клиента") ?? ""}
                    close={() => {
                        setNewContractPopup(false)
                        location.reload()
                    }}
                />
            }
        </div>
    </div>
};

ContractViewPage = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(ContractViewPage)

export default ContractViewPage
