import React, {useEffect, useState} from "react";
import {$moment, httpClient} from "../../funcs";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import Popup from "../../components/Popup";
import {connect} from "react-redux";
import {SubscriptionPopup} from "./SubscriptionPopup";
import classnames from "classnames";
import _ from "lodash";
import {loadSelf} from "../../methods/loadSelf";

let TariffView: any = function TariffView(props: any) {
    let {tariff, i, promoCodes, showBonusPromoCode} = props

    const [subscriptionPopup, setSubscriptionPopup] = useState<any>(null)

    async function buyPackage(tariffId: string) {
        const data = await httpClient.put('/user/entrepreneur/buy-package', {
            tariff_id: tariffId
        })

        if (!data.data.tariff) {
            window.location.href = data.data.PaymentURL
            return
        }
        const tariff = data.data.tariff

        props.$commitToStore({
            userSession: {
                ...props.$store.userSession,
                tariff
            },
            notification: {
                title: 'Оплачено',
                content: `Вы купили пакет "${tariff.name}"`
            }
        })
    }

    async function cancelSubscription() {
        try {
            const { data: balance } = await httpClient.put('/user/entrepreneur/cancel-subscription')
            notifyUser(props, 'Подписка успешно отменена')
            await loadSelf(props)
        }
        catch (error) {
            notifyAboutError(props, error)
        }
    }

    async function selectTariff(tariffId: string) {
        try {
            const data = await httpClient.put('/user/entrepreneur/switch-tariff', {
                tariff_id: tariffId
            })

            if (data.data.PaymentURL) {
                window.location.href = data.data.PaymentURL
                return
            }

            const tariff = data.data.tariff
            if ((data.data.Status == "CONFIRMED" || data.data.Status == "AUTHORIZED") && tariff) {
                props.$commitToStore({
                    userSession: {
                        ...props.$store.userSession,
                        tariff
                    },
                    notification: {
                        title: 'Тариф изменён',
                        content: `Вам подключён тарифный план "${tariff.name}"`
                    }
                })
            } else {
                notifyUser(props, "Не удалось изменить тариф", "Убедитесь что на карте есть деньги или попробуйте другую карту")
            }
        } catch (error) {
            notifyAboutError(props, error)
        }
    }

    function tariffBG(i: number): string {
        if (i == 0) {
            return "#75809E"
        }
        if (i == 1) {
            return "#33B5FF"
        }
        if (i == 2) {
            return "#2339AA"
        }
        return "#75809E"
    }

    function tariffNameBG(i: number): string {
        if (i == 0) {
            return "#657090"
        }
        if (i == 1) {
            return "#16A6F8"
        }
        if (i == 2) {
            return "#152B9E"
        }
        return "#75809E"
    }

    function hrBG(i: number): string {
        if (i == 0) {
            return "#939EBC"
        }
        if (i == 1) {
            return "#75CDFF"
        }
        if (i == 2) {
            return "#4D63D4"
        }
        return "#939EBC"
    }

    function addPromoCodeBonus(promoCodes: any, tariff: any) {
        const promoCodeName = _.get(promoCodes, 'code_name')
        const isPromoAdditionalBonus = _.get(promoCodes, 'is_promo_payment')
        const additionalBonusPercent = _.get(promoCodes, 'payment_add_documents_by_percent')
        if (!isPromoAdditionalBonus || !tariff || !additionalBonusPercent || tariff.type == 'subscription') {
            return null
        }

        try {
            const additionalValue = Math.ceil(tariff.prepaid_documents_count * Number.parseFloat(additionalBonusPercent) / 100);
            return `+${additionalValue} по коду ${promoCodeName}`
        } catch {
            return null;
        }
    }

    function buttonTitle() {
        let thisTariffIsSubscription = tariff.type == 'subscription'

        if (tariff.price == 0) {
            return thisTariffIsSubscription ? 'Оформить подписку' : 'Купить пакет'
        }

        let myTariffIsSubscription = props.$store.userSession?.tariff?.type == 'subscription'
        let thisTariffIsMine = props.$store.userSession?.tariff?._id == tariff._id
        if (myTariffIsSubscription) {
            if (thisTariffIsMine) {
                return 'Отменить подписку'
            } else {
                return thisTariffIsSubscription ? 'Перейти на эту подписку' : 'Купить пакет'
            }
        } else {
            return thisTariffIsSubscription ? 'Оформить подписку' : 'Купить пакет'
        }
    }

    return <div>
        <div
            key={tariff._id}
            style={{background: tariffBG(i)}}
            className={"tariff"}
        >
            <div
                className="name"
                style={{background: tariffNameBG(i), display: "inline-block"}}
            >
                {tariff.name}
            </div>

            {
                showBonusPromoCode &&
                <div className="promoCodeBonus">
                    {
                        addPromoCodeBonus(promoCodes, tariff)
                    }
                </div>
            }

            <div className="price" style={{marginTop: "10px"}}>
                {
                    !tariff.is_default &&
                    `${tariff.price/100} рублей ${tariff.type == 'subscription' ? (tariff.period == 'month' ? 'в месяц' : 'в год') : ''}`
                }
                {
                    tariff.prepaid_documents_count > 0 && !tariff.is_default &&
                    <span style={{fontWeight: 400}}>
                                      / {(tariff.price/100) / tariff.prepaid_documents_count} рублей за 1 договор
                                    </span>
                }
            </div>
            <hr className="newHR" style={{background: hrBG(i)}}/>
            <div className="titleContainer">
                <div></div>
                <div className="buttonsContainer">
                    {
                        tariff.type == 'subscription' && props.$store.userSession?.tariff?._id == tariff._id && props.$store.userSession.next_subscription_charge_date &&
                        <div className="commonSubtitle" style={{color: "#FFFFFF"}}>
                            Следующее списание: {$moment(props.$store.userSession.next_subscription_charge_date).format('LL')}
                        </div>
                    }
                    <button
                        className="new underlined"
                        onClick={async () => {
                            if (tariff.type == 'subscription') {
                                if (props.$store.userSession?.tariff?._id == tariff._id) {
                                    await cancelSubscription()
                                } else {
                                    setSubscriptionPopup(tariff)
                                }
                            } else {
                                await buyPackage(tariff._id)
                            }
                        }}
                    >
                        {
                            buttonTitle()
                        }
                    </button>
                </div>
            </div>
        </div>
        {
            subscriptionPopup &&
            <SubscriptionPopup
                onClose={() => setSubscriptionPopup(null)}
                tariff={subscriptionPopup}
                $store={props.$store}
                subscribe={() => {
                    setSubscriptionPopup(null)
                    selectTariff(subscriptionPopup._id)
                }}
            />
        }
    </div>
}

TariffView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(TariffView)

export default TariffView