import Popup from "../../components/Popup";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {httpClient, preparePhoneNumber} from "../../funcs";
import {notifyAboutError, notifyUser} from "../../methods/notifyUser";
import {userFullname} from "../../methods/utils";
import {AccountCircle} from "@mui/icons-material";
import {finishAuth} from "../../methods/finishAuth";
import {useRoute} from "react-router5";
import _ from "lodash";
import {checkPermission, UserPermission} from "../../methods/permissionByRole";
import InviteEmployeePopup from "./InviteEmployeePopup";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {inviteLink} from "../../utils/funcs/getContractLink";

let UserListView: any = function UserListView(props: any) {
    const $router = useRoute()

    const [showList, setShowList] = useState(false)
    const [showInvite, setShowInvite] = useState(false)
    const [invitePhoneNumber, setInvitePhoneNumber] = useState("")

    const [rolesLoaded, setRolesLoaded] = useState(false)
    const [myUserId, setMyUserId] = useState(props.$store.userSession?._id)
    const [currentUserId, setCurrentUserId] = useState(props.$store.userSession?._id)
    const [users, setUsers] = useState<any[]>([])
    const [grantedToYouRoles, setGrantedToYouRoles] = useState<any[]>([])
    const [grantedByYouRoles, setGrantedByYouRoles] = useState<any[]>([])

    useEffect(() => {
        showInvitation()
    }, [])

    async function showInvitation() {
        let inviteRoleId = props.$store.inviteRoleId
        if (inviteRoleId) {
            await getUserList()
        }
    }

    useEffect(() => {
        let inviteRoleId = props.$store.inviteRoleId
        if (inviteRoleId && rolesLoaded) {
            let role = grantedToYouRoles.filter((role: any) => role._id == inviteRoleId)[0]
            if (role) {
                setShowList(true)
                notifyUser(props, `Вас пригласил ${userFullname(role.granted_by)}`, 'Примите его приглашение чтобы начать работать')
            } else {
                notifyUser(props, 'Приглашение не найдено', 'Убедитесь, что приглашение выдано на ваш номер телефона, или обратитесь в тех поддержку')
            }
            props.$commitToStore({
                inviteRoleId: null
            })
        }
    }, [grantedToYouRoles, rolesLoaded])

    async function getUserList() {
        props.$commitToStore({
            loading: true
        })

        try {
            let { data } = await httpClient.get(`/get-user-list`)

            setCurrentUserId(data.current_user_id)
            setMyUserId(data.my_user_id)

            let users = data.users.filter((user:any) => user.type == 'user')
            setUsers(users)

            let grantedToYouRoles = data.users.filter((user: any) => {
                return user.type == 'role' && (_.get(user, 'granted_to._id') == myUserId || inviteByPhoneIsForMe(user))
            })
            console.log('load grantedToYouRoles', grantedToYouRoles)
            setGrantedToYouRoles(grantedToYouRoles)

            let grantedByYouRoles = data.users.filter((user: any) => {
                return user.type == 'role' && _.get(user, 'granted_by._id') == myUserId
            })
            setGrantedByYouRoles(grantedByYouRoles)

            setRolesLoaded(true)
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            props.$commitToStore({
                loading: false
            })
        }
    }

    function inviteByPhoneIsForMe(user: any) {
        return _.get(user, 'granted_to_phone_number') == props.$store.userSession.phone_number
    }

    async function invite(phoneNumber: string) {
        props.$commitToStore({
            loading: true
        })

        try {
            let { data } = await httpClient.post(
                `/create-role`,
                {'phone_number': preparePhoneNumber(phoneNumber)}
            )

            notifyUser(props, data.text)
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            props.$commitToStore({
                loading: false
            })
        }
    }

    async function revoke(role: any) {
        props.$commitToStore({
            loading: true
        })

        try {
            let { data } = await httpClient.put(
                `/revoke-role`,
                {'_id': role._id}
            )

            notifyUser(props, data.text)
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            props.$commitToStore({
                loading: false
            })
        }
    }

    async function accept(role: any) {
        props.$commitToStore({
            loading: true
        })

        try {
            let { data } = await httpClient.put(
                `/accept-role`,
                {'_id': role._id}
            )

            notifyUser(props, data.text)
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            props.$commitToStore({
                loading: false
            })
        }
    }

    function userView(user: any) {
        if (user) {
            return <div>
                <div className="Subheader_15">
                    {
                        userFullname(user)
                    }
                </div>
                <div className="Body_15">
                    {user.phone_number}, {user.email}
                </div>
            </div>
        }
    }

    function makeUsersView() {
        return <div className="Subheader_17">
            {
                users.length > 0 &&
                makeMyUsersView()
            }

            {
                grantedByYouRoles.length > 0 &&
                makeGrantedByYouView()
            }

            {
                grantedToYouRoles.length > 0 &&
                makeGrantedToYouView()
            }
        </div>
    }

    function makeMyUsersView() {
        return <div className="Subheader_17">
            Ваши аккаунты
            <div className={"commonSubtitle"}>
                На один номер можно максимум завести 2 аккаунта - один как физ. лицо и один как юр. лицо. Чтобы завести новый аккаунт - выйдите из текущего и перейдите в регистрацию нового аккаунта
            </div>
            <div style={{marginTop: "10px"}}>
                {
                    users.map((user: any) => {
                        return <div
                            className="templateViewContainer disabledHover"
                            style={{marginBottom: "15px"}}
                        >
                            {
                                userView(user)
                            }
                            {
                                makeMyUsersButtons(user)
                            }
                        </div>
                    })
                }
            </div>
            {/*<hr className="newHR"/>*/}
        </div>
    }

    function makeMyUsersButtons(user: any) {
        return <div className="buttonsContainer" style={{marginTop: "15px"}}>
            <button
                className="new withTitle small"
                disabled={currentUserId == user._id}
                onClick={async () => {
                    await finishAuth(props, $router, user.access_token, 'profile')
                    props.$commitToStore({
                        role: null
                    })
                    await getUserList()
                }}
            >
                {currentUserId == user._id ? "Текущий" : "Войти"}
            </button>
        </div>
    }

    function makeGrantedByYouView() {
        return <div className="Subheader_17">
            Сотрудники, подключенные к вашему аккаунту:
            <div style={{marginTop: "10px"}}>
                {
                    grantedByYouRoles.map((user: any) => {
                        return <div
                            className="templateViewContainer disabledHover"
                            style={{marginBottom: "15px"}}
                        >
                            <div>
                                {
                                    userView(user.granted_to)
                                }

                                {
                                    user.granted_to_phone_number &&
                                    <div className="Subheader_15">
                                        { user.granted_to_phone_number }
                                    </div>
                                }
                                {
                                    makeGrantedByYouButtons(user)
                                }
                            </div>
                        </div>
                    })
                }
            </div>
            {/*<hr className="newHR"/>*/}
        </div>
    }

    function makeGrantedByYouButtons(user: any) {
        return <div style={{marginTop: "15px"}}>
            {
                user.status == "INVITED" &&
                <CopyToClipboard
                    text={`Вас пригласили присоединиться к аккаунту ${userFullname(props.$store.userSession)} в ОкиДоки. Пройдите про ссылке и зарегистрируйтесь (если у вас нет аккаунта). Затем, подтвердите приглашение. \n\n ${inviteLink(user._id)}`}
                >
                    <button
                        style={{marginTop: "10px"}}
                        className="new withTitle secondary small"
                        onClick={() => {
                            notifyUser(props, "Отправьте скопированную ссылку вашему сотруднику")
                        }}
                    >
                        Скопировать приглашение
                    </button>
                </CopyToClipboard>
            }
            <button
                style={{marginTop: "15px"}}
                className="new withTitle secondary small"
                onClick={async () => {
                    await revoke(user)
                    await getUserList()
                }}
            >
                Отозвать
            </button>
        </div>
    }

    function makeGrantedToYouView() {
        return <div className="Subheader_17">
            Аккаунты, к которым вам выдали доступ как сотруднику:
            <div style={{marginTop: "10px"}}>
                {
                    grantedToYouRoles.map((user: any) => {
                        return <div
                            className="templateViewContainer disabledHover"
                            style={{marginBottom: "15px"}}
                        >
                            {
                                userView(user.granted_by)
                            }
                            {
                                makeGrantedToYouButtons(user)
                            }
                        </div>
                    })
                }
            </div>
            {/*<hr className="newHR"/>*/}
        </div>
    }

    function makeGrantedToYouButtons(user: any) {
        return <div style={{marginTop: "15px"}}>
            {
                user.status == 'CONFIRMED' &&
                <button
                    className="new withTitle small"
                    disabled={currentUserId == user.granted_by._id}
                    onClick={async () => {
                        await finishAuth(props, $router, user.access_token, 'profile')
                        props.$commitToStore({
                            role: user
                        })
                        await getUserList()
                    }}
                >
                    {currentUserId == user.granted_by._id ? "Текущий" : "Войти"}
                </button>
            }
            {
                user.status == 'INVITED' &&
                <button
                    className="new withTitle small"
                    onClick={async () => {
                        await accept(user)
                        await finishAuth(props, $router, user.access_token, 'profile')
                        props.$commitToStore({
                            role: user
                        })
                        await getUserList()
                    }}
                >
                    Принять приглашение
                </button>
            }
        </div>
    }

    return <div>
        <div className={"templateViewContainer disabledHover"}>
            <div className="commonTitle" style={{textAlign: "left"}}>
                Управление аккаунтами
            </div>
            <div className="commonSubtitle" style={{textAlign: "left"}}>
                Вы можете переключаться между своими аккаунтами и приглашать сотрудников присоединиться к вашему аккаунту
            </div>
            <hr className={"newHR"}/>
            <div className="row-data">
                <button
                    className="new withTitle _wa "
                    onClick={async () => {
                        setShowList(true)
                        await getUserList()
                    }}
                >
                    Посмотреть аккаунты
                </button>
            </div>
        </div>

        {
            showList &&
            <Popup
                maxWidth="650px"
                onClose={() => {setShowList(false)}}
            >
                <>
                    {
                        makeUsersView()
                    }
                    {
                        (checkPermission(props, UserPermission.ADD_USERS) || checkPermission(props, UserPermission.OWNER)) &&
                        <>
                            <hr className="newHR"/>
                            <button
                                className="new withTitle _wa"
                                onClick={async () => {
                                    setShowInvite(true)
                                }}
                            >
                                Добавить сотрудника в ваш аккаунт
                            </button>
                        </>
                    }
                    {
                        props.$store.loading &&
                        <div className="form-field center">
                            <CircularProgress sx={{color: "#1FA3EE", textAlign: "center"}} />
                        </div>
                    }
                </>
            </Popup>
        }

        {
            showInvite &&
            <InviteEmployeePopup
                $store={props.$store}
                $commitToStore={props.$commitToStore}
                onClose={() => {setShowInvite(false)}}
                invite={async (phoneNumber: string) => {
                    setInvitePhoneNumber(phoneNumber)
                    setShowInvite(false)
                    await invite(phoneNumber)
                    await getUserList()
                }}
            />
        }
    </div>
}

UserListView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(UserListView)

export default UserListView