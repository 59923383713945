import { useState, useRef } from 'react'
import Popup from "../../components/Popup";
import {connect} from "react-redux";
import "pages/Styles/EditContractCommon.sass"
import _ from "lodash";

import { $moment } from "funcs"

import Radio from '@mui/material/Radio';
import {FormControlLabel, RadioGroup, useMediaQuery} from "@mui/material";
import {ReactComponent as RadioChecked} from "../Icons/size24/RadioChecked.svg";
import {ReactComponent as RadioUnchecked} from "../Icons/size24/RadioUnchecked.svg";

import {makeDatePicker} from "../Entity/EntityInput"
import {linkForClientElement} from "../../methods/linkForClientElement";
import {mobileMaxWidthMediaQuery} from "../../methods/utils";

type Props = {
    template: any,
    onClose: Function,
    onConfirm: Function,
    $commitToStore: any
}

function entitiesForOnlyOwner(entities: Array<any>) {
    return entities
            .map(entity => {
                if (entity["adminCanFill"] === true && entity["clientCanFill"] === false) {
                    return entity
                }
            })
            .filter(el => el)
}

let MakeMassTemplatePopup: any = function MakeMassTemplatePopup(props: Props) {
    const {template, onClose, onConfirm} = props

    const [makeMassTemplate, setMakeMassTemplate] = useState(_.get(template, "is_mass_template", false))
    const [needToCheckAfterSign, setNeedToCheckAfterSign] = useState(_.get(template, "mass_template_need_to_check_after_sign", false))

    const [isEditing, setIsEditing] = useState(false)
    const today = $moment().add(1, 'M').format('LL')
    const [expiredDate, setExpiredDate] = useState(_.get(template, "mass_template_expire_date", today))
    const input = useRef<HTMLInputElement>(null);

    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)

    return <Popup
        style={{zIndex: 999}}
        onClose={onClose}
    >
        <div>
            <div>
                <div className="commonLabel" style={{marginTop: "25px"}}>
                    Хотите выставить договор для массового подписания?
                </div>
                <RadioGroup
                    defaultValue={makeMassTemplate ? 'Да' : 'Нет'}
                    onChange={(e, value) => {
                        setMakeMassTemplate(value === 'Да')
                    }}
                >
                    <FormControlLabel
                        value={'Да'}
                        control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                        label={'Да'}
                    />
                    <FormControlLabel
                        value={'Нет'}
                        control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                        label={'Нет'}
                    />
                </RadioGroup>
            </div>

            {
                makeMassTemplate &&
                <>

                    <hr className="newHR"/>

                    <div>
                        <div className="commonLabel" style={{marginTop: "25px"}}>
                            Хотите проверять введённые пользователем данные после подписания?
                        </div>
                        <RadioGroup
                            defaultValue={needToCheckAfterSign ? 'Да' : 'Нет'}
                            onChange={(e, value) => {
                                setNeedToCheckAfterSign(value === 'Да')
                            }}
                        >
                            <FormControlLabel
                                value={'Да'}
                                control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                                label={'Да'}
                            />
                            <FormControlLabel
                                value={'Нет'}
                                control={<Radio checkedIcon={<RadioChecked/>} icon={<RadioUnchecked/>}/>}
                                label={'Нет'}
                            />
                        </RadioGroup>
                    </div>

                    <div>
                        <div className="commonLabel" style={{marginTop: "25px"}}>
                            Дата окончания для массового подписания договора
                        </div>
                        {makeDatePicker(input, isEditing, setIsEditing, {value: expiredDate}, setExpiredDate)}
                    </div>

                    <div style={{marginTop: "30px"}}>
                        {linkForClientElement(props, template["_id"], "Ссылка на массовое подписание договоров", mobile)}
                    </div>
                </>
            }

            <hr className="newHR"/>
            
            <div className="buttonsContainer" style={{marginTop: "25px", width: "100%"}}>
                <button
                    className="new withTitle _wa"
                    onClick={(e) => {
                        const entitiesNameForOnlyOwner = entitiesForOnlyOwner(template['entities']).map(el => el["keyword"])
                        if (entitiesNameForOnlyOwner.length > 0) {
                            props.$commitToStore({
                                notification: {
                                    title: "Ошибки в полях шаблона",
                                    content: `Уберите поля, которые не сможет заполнить пользователь: ${entitiesNameForOnlyOwner.join(', ')}`
                                }
                            })
                            return
                        }
                        onConfirm({makeMassTemplate, needToCheckAfterSign, expiredDate})
                    }}
                >
                    Сохранить
                </button>
            </div>
        </div>
    </Popup>
}

MakeMassTemplatePopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(MakeMassTemplatePopup)

export default MakeMassTemplatePopup