import {Close, ExpandMore} from "@mui/icons-material";
import React from "react";

type Props = {
    title: string,
    subtitle: any,
    videoId: string,
    rutubeVideoId: string,
    externalLink?: string,
    externalLinkTitle?: string,
    close: Function,
    expand: Function
}

export let HelpView: any = function HelpView(props: Props) {

    function videoURL(): string | null {
        let rutubePreferred = true
        if (rutubePreferred) {
            return rutubeURL() ?? youtubeURL()
        } else {
            return youtubeURL() ?? rutubeURL()
        }
    }

    function rutubeURL(): string | null {
        if (props.rutubeVideoId) {
            return `https://rutube.ru/play/embed/${props.rutubeVideoId}/`
        } else {
            return null
        }
    }

    function youtubeURL(): string | null {
        if (props.videoId) {
            return `https://www.youtube.com/embed/${props.videoId}/`
        } else {
            return null
        }
    }

    return <div>
        <div className="titleContainer">
            <div className="commonLabel" style={{textAlign: "center"}}>
                {props.title}
            </div>
            {
                props.close &&
                <div style={{marginBottom: "auto"}}>
                    <button
                        className="new smallIconed close-button"
                        onClick={() => props.close()}
                    >
                        <Close className="icon" style={{stroke: "#75809E"}} />
                    </button>
                </div>
            }
            {
                props.expand &&
                <div style={{marginBottom: "auto"}}>
                    <button
                        className="new smallIconed close-button"
                        onClick={() => props.expand()}
                    >
                        <ExpandMore className="icon" style={{fill: "#75809E"}} />
                    </button>
                </div>
            }
        </div>

        {
            props.subtitle &&
            <div className="commonSubtitle" style={{marginTop: "25px", whiteSpace: "pre-line"}}>
                {props.subtitle}
            </div>
        }

        {
            videoURL() &&
            <div className="video-responsive">
                <iframe
                    width="480"
                    height="480"
                    src={videoURL() ?? ''}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded video"
                />
            </div>
        }
        {
            props.externalLink &&
            <a href={props.externalLink} target="_blank">{props.externalLinkTitle ?? ""}</a>
        }
    </div>
}