import Popup from "../../components/Popup";
import {connect} from "react-redux";
import "pages/Styles/EditContractCommon.sass"
import Carousel from "react-material-ui-carousel";
import React, {useEffect, useState} from "react";
import {ReactComponent as AddEntity} from "../Icons/size24/Add.svg"
import {userFullname} from "../../methods/utils";
import UserCardForm from "./UserCardForm";
import {httpClient} from "../../funcs";
import {notifyAboutError} from "../../methods/notifyUser";
import {loadSelf} from "../../methods/loadSelf";
import EntityCopyButton from "./ContractRequestedClientData/Views/EntityCopyButton";
import {firstSigner} from "../../methods/SystemEntity";
import {getActualUser} from "../../methods/updateLocalEntities";
import {contractSystemEntity} from "../../methods/systemEntityValueByKeyword";
import Stamp from "./Stamp";

let UserCardsView: any = function UserCardsPopup(props: any) {
    const [newCardPopup, setNewCardPopup] = useState(false)
    const [editingCard, setEditingCard] = useState<any>(null)
    function getUserCards() {
        let user = props.$store.userSession
        if (!user) {
            return []
        } else {
            return [user].concat(user.additional_user_cards ?? [])
        }
    }

    const [userCards, setUserCards] = useState<any[]>([])

    useEffect(() => {
        setUserCards(getUserCards())
    }, [props.$store.userSession])



    async function createNewCard(card: any) {
        try {
            props.$commitToStore({
                loading: true
            })

            if (card.role == 'customer' && card.passport_number) {
                card.passport = {
                    number: card.passport_number,
                    issued_by_code: card.passport_issued_by_code,
                    issued_at: card.passport_issued_at,
                    issued_by: card.passport_issued_by,
                    registration: card.passport_registration,
                }
            }

            let {data: createdCard} = await httpClient.post('/additional-user-cards', card)

            let cards = userCards
            cards.push(createdCard)
            setUserCards(cards)

            await loadSelf(props)
            setNewCardPopup(false)
        }

        catch (error) {
            notifyAboutError(props, error)
        }

        finally {
            props.$commitToStore({
                loading: false
            })
        }
    }

    async function updateCard(cardToUpdate: any) {
        try {
            props.$commitToStore({
                loading: true
            })

            if (cardToUpdate.role == 'customer' && cardToUpdate.passport_number) {
                cardToUpdate.passport = {
                    number: cardToUpdate.passport_number,
                    issued_by_code: cardToUpdate.passport_issued_by_code,
                    issued_at: cardToUpdate.passport_issued_at,
                    issued_by: cardToUpdate.passport_issued_by,
                    registration: cardToUpdate.passport_registration,
                }
            }

            await httpClient.put('/additional-user-cards', cardToUpdate)

            let cards = [...userCards]
            const cardIndex = cards.findIndex(
                (card: any) => card._id == cardToUpdate._id
            );
            cards[cardIndex] = cardToUpdate
            setUserCards(cards)

            setNewCardPopup(false)

            await loadSelf(props)
            setEditingCard(null)
        }

        catch (error) {
            notifyAboutError(props, error)
        }

        finally {
            props.$commitToStore({
                loading: false
            })
        }
    }

    async function deleteCard(cardToDelete: any) {
        try {
            props.$commitToStore({
                loading: true
            })

            await httpClient.delete(`/additional-user-cards/${cardToDelete._id}`)

            let cards = [...userCards]
            for (let card of cards) {
                if (card._id == cardToDelete._id) {
                    card.deleted = true
                }
            }

            setUserCards(cards)

            await loadSelf(props)
            setNewCardPopup(false)
        }

        catch (error) {
            notifyAboutError(props, error)
        }

        finally {
            props.$commitToStore({
                loading: false
            })
        }
    }

    function actualUserCardIdIndex(): number {
        let actualUserCardId = props.contract.actual_user_card_id
        if (actualUserCardId) {
            let index = userCards.map((userCard: any) => userCard._id).indexOf(actualUserCardId)
            if (index != -1) {
                return index
            } else {
                return 0
            }
        } else {
            return 0
        }
    }

    return <div>
        <hr className="newHR"/>

        <div className={"titleContainer"}>
            <div className={"Subheader_17"}>
                Выбор карточки исполнителя
            </div>

            <button
                className="new mediumIconed"
                style={{backgroundColor: "#33B5FF"}}
                onClick={() => {
                    setNewCardPopup(true)
                }}
            >
                <AddEntity className="icon" style={{fill: "white"}}/>
            </button>
        </div>

        <div className={"titleContainer"} style={{marginTop: "15px"}}>
            <div>
                <div className="commonLabel">
                    {firstSigner.keyword}
                </div>
                <div className="commonSubtitle">
                    Разместите эту метку в шапке договора
                </div>
            </div>
            <EntityCopyButton
                keyword={firstSigner.keyword}
                onCopy={() => {
                    let entity = contractSystemEntity(props.contract, firstSigner.id) ?? firstSigner
                    let actualUser = getActualUser(props.$store.userSession, props.contract)
                    entity.value = userFullname(actualUser)
                    props.addEntities(true, [entity])
                }}
            />
        </div>

        <div className={"Body_15"} style={{marginTop: "15px"}}>
            Вы можете выставлять договор от имени разных исполнителей. Чтобы добавить новую карточку исполнителя нажмите на кнопку +
        </div>

        <div className={"Body_15"} style={{marginTop: "15px", fontWeight: "bold"}}>
            Просто выберите нужную карточку и договор будет подписан это карточкой!
        </div>

        <Carousel
            index={actualUserCardIdIndex()}
            swipe={true}
            duration={0}
            navButtonsAlwaysVisible={false}
            animation={"fade"}
            autoPlay={false}
            cycleNavigation={false}
            fullHeightHover={false}
            onChange={(now, previous) => {
                if (typeof now === 'number') {
                    let currentCard = userCards[now]
                    let entity = contractSystemEntity(props.contract, firstSigner.id) ?? firstSigner
                    let actualUser = getActualUser(props.$store.userSession, {...props.contract, actual_user_card_id: currentCard._id})
                    entity.value = userFullname(actualUser)
                    props.addEntities(true, [entity])
                    props.updateActualUserCardId(currentCard._id)
                }
            }}
        >
            {
                userCards.map( (user) => {
                    return <Stamp
                        user={user}
                        contract={props.contract}
                        edit={(user: any) => {
                            setEditingCard(user)
                            setNewCardPopup(true)
                        }}
                        delete={async (user: any) => {
                            await deleteCard(user)
                        }}
                        allowEdit={true}
                    />
                })
            }
        </Carousel>

        {
            newCardPopup &&
            <Popup
                style={{zIndex: 999}}
                onClose={() => {setNewCardPopup(false)}}
            >
                <UserCardForm
                    editingCard={editingCard}
                    saveCard={async (newCard: any) => {
                        if (editingCard) {
                            await updateCard(newCard)
                        } else {
                            await createNewCard(newCard)
                        }
                    }}
                />
            </Popup>
        }
    </div>
}

UserCardsView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(UserCardsView)

export default UserCardsView