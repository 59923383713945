import {$moment} from "../funcs";
import {userFullname} from "./utils";
import {updateEntityInEditor} from "./updateEntityInEditor";
import {contractSystemEntity} from "./systemEntityValueByKeyword";
import {firstSigner} from "./SystemEntity";

export function updateLocalEntities(template: any, userSession: any, isFirstLoad: boolean | null = null) {
    let entitiesToUpdate: any[] = []

    if (!template) {
        return
    }

    let actualUser = getActualUser(userSession, template)
    console.log("updateLocalEntities", actualUser)
    if (actualUser) {
        let firstSignerEntity = contractSystemEntity(template, firstSigner.id)
        if (firstSignerEntity) {
            firstSignerEntity.value = userFullname(actualUser)
            entitiesToUpdate.push(firstSignerEntity)
        } else {
            let entity = {...firstSigner}
            entity.value = userFullname(actualUser)
            template.system_entities.push(entity)
        }
    }

    template.system_entities.forEach((entity: any, i: number) => {
        if (entity.keyword == "Текущая дата" && (!entity.value || entity.value?.length == 0 || isFirstLoad == true)) {
            entity.value = $moment().format("LL")
            entitiesToUpdate.push(entity)
        }
        if (!userSession) {
            return
        }
        if (entity.keyword == "Адрес регистрации исполнителя") {
            entity.value = actualUser.law_address
            entitiesToUpdate.push(entity)
        }
        if (entity.keyword == "ИНН исполнителя") {
            entity.value = actualUser.tax_id
            entitiesToUpdate.push(entity)
        }
    });

    entitiesToUpdate.forEach((entity: any) => {
        updateEntityInEditor(entity)
    })
    return template.system_entities
}

export function getActualUser(userSession: any, contractOrTemplate: any): any {
    if (!userSession) {
        return userSession
    }

    let user = {...userSession}
    let actualUserCardId = contractOrTemplate.actual_user_card_id
    if (!actualUserCardId) {
        return user
    }

    let additionalUserCards = userSession.additional_user_cards ?? []

    let actualUserCard = additionalUserCards.filter((card: any) => card._id == actualUserCardId)[0]
    if (!actualUserCard) {
        return user
    }

    let propertiesToClean = [
        'tax_id', 'ogrn',
        'law_name', 'law_address',
        'last_name', 'first_name', 'middle_name',
        'organisation_stamp',
        'bank_name', 'bank_account', 'bank_corr_account', 'bin',
        'email',
        'passport'
    ]

    for (let propertyToClean of propertiesToClean) {
        delete user[propertyToClean]
    }

    for (let property in actualUserCard) {
        if (Object.prototype.hasOwnProperty.call(actualUserCard, property) && property != '_id') {
            user[property] = actualUserCard[property]
        }
    }

    user['actual_user_card_id'] = actualUserCardId

    return user
}