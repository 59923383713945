import {scrollToElement} from "./scrollTo";
import {validateRegisterFormWhileCreatingContract} from "./registerFormValidation";
import {defaultUserFormForContractCreating} from "../pages/Auth/RegisterDefaultValues";


export function validateContractBeforeSave(contract: any, shouldSign: boolean | null) {
    let missing = missingEntities(contract)
    if (missing.length > 0) {
        let kw = `copyView.${missing[0]}`;
        scrollToElement(kw, true)
        throw new Error(
            `Метка Поля "${missing[0]}" должна быть в тексте договора. Скопируйте метку и разместите ее в тексте договора`,
        );
    }

    let allEmptyEntities = emptyEntities(contract)
    if (allEmptyEntities.length > 0) {
        let kw = `input.${allEmptyEntities[0]}`;
        scrollToElement(kw, true)
        throw new Error(
            `Поле "${allEmptyEntities[0]}" должно быть заполнено`,
        );
    }

    let userFormErrors = validateRegisterFormWhileCreatingContract(
        contract.system_entities,
        defaultUserFormForContractCreating(contract)
    )
    if (userFormErrors.length > 0) {
        throw new Error(userFormErrors.join(".\n\n"))
    }
}

export function emptyEntities(contract: any): string[] {
    return contract.entities
        .filter((entity: any) => !("isRequired" in entity) || entity["isRequired"] === true)
        .filter(
            (entity: any) =>
                (["Текст", "Дата", "Фото", "Список", "Формула"].includes(entity.type) || !entity.type) &&
                !entity.clientCanFill &&
                (entity.value?.toString() ?? "").length == 0,
        )
        .map((entity: any) => { return entity.keyword })
}

export function missingEntities(contract: any): string[] {
    return contract.entities
        .filter((entity: any) => (entity.isRequired ?? true))
        .filter((entity: any) => !contract.body.includes(`<<${entity.keyword}>>`))
        .map((entity: any) => { return entity.keyword })
}