import {connect} from "react-redux";
import React, {useState} from "react";
import {useRoute} from "react-router5";
import {State} from "router5";
import {Params} from "router5/dist/types/base";
import {Tooltip, useMediaQuery} from "@mui/material";

import {ReactComponent as Back} from "pages/Icons/size24/Back.svg"
import {ReactComponent as Close} from "pages/Icons/size24/Close.svg"
import ErrorView from "../../Common/ErrorView";
import ContractStatusView from "../ContractStatusView";
import {ContractActionsButton, Flow} from "../ContractActionsButton";
import {Check} from "@mui/icons-material";
import FillEntitiesPopup from "./FillEntitiesPopup";
import {ContractHelpPopup} from "../../Templates/TemplatesHelpPopup";
import {contractStatus, ContractStatus} from "../../../methods/ContractStatus";
import {defaultUserFormForSigning} from "../../Auth/RegisterDefaultValues";
import {
    extraEntitiesToSend, validateCustomerRegisterFormWhileSigning, validateEntrepreneurRegisterFormWhileSigning,
} from "../../../methods/registerFormValidation";
import {mobileMaxWidthMediaQuery} from "../../../methods/utils";
import {EditSignedContractPopup} from "./EditSignedContractPopup";

type Props = {
    prevRoute: State | null,
    contract: any,
    isEditing: boolean,
    saveEditedVersion: Function,
    cancelEditing: Function,
    startEditing: Function,
    loadContract: Function,
    errorMessage: string | null,
    savingStatus: string,
    updateEntity: Function,
    approveContract: Function,
    $commitToStore: Function,
    $store: any
}

interface prevParams {
    [name: string]: [string, Params];
}

const params: prevParams = {
    "contract": ["signed-contracts", {}],
};

let ContractViewHeader: any = function ContractViewHeader(props: Props) {
    const $router = useRoute();
    const {
        prevRoute, contract, isEditing, saveEditedVersion, cancelEditing,
        startEditing, loadContract, savingStatus, updateEntity, approveContract,
        $commitToStore, $store
    } = props
    const [errorClosed, setErrorClosed] = useState(false);

    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)
    const contractIsInAwaitingStatus = contractStatus(contract) == ContractStatus.Awaiting;

    const [showHelp, setShowHelp] = useState(false);
    const [fillEntitiesPopup, setFillEntitiesPopup] = useState(false)
    const [editButtonForEditSignedContractPopup, setEditButtonForEditSignedContractPopup] = useState(false)

    function back() {
        if (prevRoute) {
            if (prevRoute.path !== $router.route.path) {
                $router.router.navigate(prevRoute.name, prevRoute.params);
            } else {
                const route = params[prevRoute.name];
                $router.router.navigate(route[0], route[1]);
            }
        } else {
            $router.router.navigate('signed-contracts', { reload: false })
        }
    }

    function sign() {
        props.$commitToStore({
            loading: false
        })
        setFillEntitiesPopup(true)
    }

    function canSign() {
        if (contract.is_mass_template == true) {
            return true
        }

        if (contract.status?.internal_id != ContractStatus.Awaiting) {
            return false
        }

        let is_first_signer_link = $router.route.params.is_first_signer ?? false
        let userSession = props.$store.userSession
        if (!userSession) {
            if (is_first_signer_link) {
                return !contract.first_signer
            } else {
                return !contract.target_user
            }
        }

        let is_creator_is_me = contract.created_by?._id == userSession._id
        let is_first_signer_is_me = contract.first_signer?._id == userSession._id
        let is_target_user_is_me = contract.target_user?._id == userSession._id

        if (is_creator_is_me || is_first_signer_is_me || is_target_user_is_me) {
            return false
        }

        if (is_first_signer_link) {
            return !contract.first_signer
        } else {
            return !contract.target_user
        }
    }

    function canEdit() {
        return contractIsInAwaitingStatus && !isEditing && contract.created_by._id == props.$store.userSession?._id
    }

    function showEditButtonForEditSignedContractPopup() {
        return contract.status?.internal_id == ContractStatus.Signed
    }

    function signButtonTitle() {
        const is_first_signer = $router.route.params.is_first_signer ?? false
        let registerForm = {
            ...defaultUserFormForSigning(is_first_signer, contract, props.$store.userSession),
            entities: extraEntitiesToSend(contract, is_first_signer)
        }
        let validationErrors: string[] = []
        if (registerForm.client_role == 'customer') {
            validationErrors = validateCustomerRegisterFormWhileSigning(registerForm, contract, is_first_signer)
        } else if (registerForm.client_role == 'entrepreneur') {
            validationErrors = validateEntrepreneurRegisterFormWhileSigning(registerForm, contract, is_first_signer)
        }

        if (validationErrors.length) {
            return "Заполнить"
        } else {
            return "Подписать"
        }
    }


    function makeBackButton() {
        if (props.$store.userSession) {
            return <button
                className="new bigIconed"
                onClick={() => {
                    back()
                }}
            >
                <Back className="icon dark"/>
            </button>
        } else {
            return null
        }
    }

    function makeEditButtons() {
        if (isEditing && !contract?.is_mass_template) {
            if (contract?.status.internal_id === 0) {
                return <button
                    className="new withTitle"
                    onClick={() => {
                        saveEditedVersion()
                    }}
                >
                    Подписать и отправить
                </button>
            }
            return <div className="buttonsContainer">
                <Tooltip arrow={true} title={"Отменить редактирование"}>
                    <button
                        className="new bigIconed"
                        style={{background: "#E22631"}}
                        onClick={() => {
                            cancelEditing()
                        }}
                    >
                        <Close className="icon" style={{stroke: "white"}}/>
                    </button>
                </Tooltip>
                <Tooltip arrow={true} title={"Сохранить отредактированную версию"}>
                    <button
                        className="new bigIconed"
                        style={{background: "#4EBA28"}}
                        onClick={() => {
                            saveEditedVersion()
                        }}
                    >
                        <Check className="icon" sx={{fill: "white"}}/>
                    </button>
                </Tooltip>
            </div>
        } else if (contractStatus(contract) == ContractStatus.AwaitingConfirmation && contract.created_by._id == props.$store.userSession?._id) {
            return <button
                className="new withTitle"
                onClick={() => {
                    approveContract()
                }}
            >
                Завершить проверку
            </button>
        }

        if (canEdit() || showEditButtonForEditSignedContractPopup()) {
            return <button
                className="new withTitle"
                onClick={() => {
                    if (showEditButtonForEditSignedContractPopup()) {
                        setEditButtonForEditSignedContractPopup(true)
                    } else {
                        startEditing()
                    }
                }}
            >
                Редактировать
            </button>
        }

        if (canSign()) {
            return <button
                className="new withTitle"
                onClick={() => {
                    sign()
                }}
            >
                {contract.sign_button_title ?? signButtonTitle()}
            </button>
        }

        return null
    }

    function mobileButtonsContainer() {
        return <div className="navbar">
            <div style={{padding: "20px 15px 15px 15px"}}>
                {
                    $store.userSession && <>
                        <div className="titleContainer">
                            <div className="buttonsContainer">
                                <button
                                    className="new"
                                    style={{width: "16px", height: "16px"}}
                                    onClick={() => {
                                        back()
                                    }}
                                >
                                    <Back className="smallIcon" style={{fill: "#75809E"}}/>
                                </button>
                                <div className="navbarHeader">
                                    К списку договоров
                                </div>
                            </div>

                            <div className="serviceMessage">
                                {savingStatus === "saving" ? "сохраняем..." : ""}
                                {savingStatus === "saved" ? "сохранено" : ""}
                            </div>
                        </div>
                        <hr className="newHR"/>
                    </>
                }
                <div
                    className="titleContainer"
                >
                    {
                        makeActionsButton()
                    }

                    {
                        canSign() &&
                        <button
                            className="new tip _wa"
                            onClick={() => {
                                setShowHelp(true)
                            }}
                        >
                            Как подписать?
                        </button>
                    }
                    {
                        makeEditButtons()
                    }
                </div>
            </div>
        </div>
    }

    function desktopButtonsContainer() {
        return <div className="titleContainer">
            <div className="buttonsContainer">
                {
                    makeBackButton()
                }

                <ContractActionsButton
                    flow={Flow.InDetailsButtons}
                    contract={contract}
                    reload={loadContract}
                    $store={props.$store}
                    $commitToStore={props.$commitToStore}
                />
            </div>

            <div className="buttonsContainer">
                <div className="serviceMessage">
                    {savingStatus === "saving" ? "сохраняем..." : ""}
                    {savingStatus === "saved" ? "сохранено" : ""}
                </div>

                {
                    makeEditButtons()
                }
            </div>
        </div>
    }

    function makeActionsButton() {
        return <ContractActionsButton
            flow={Flow.InDetailsSelect}
            contract={contract}
            reload={loadContract}
            $store={props.$store}
            $commitToStore={props.$commitToStore}
        />
    }

    function desktopNameContainer() {
        return <div style={{marginTop: "25px"}}>
            <div className="contractTitle">
                {contract.name + " "}
            </div>

            <div style={{marginTop: "15px"}}>
                <ContractStatusView contract={contract} userSession={props.$store.userSession}/>
            </div>
        </div>
    }

    function mobileNameContainer() {
        return <div style={{marginTop: $store.userSession ? "148px" : "96px"}}>
            <div className="contractTitle">
                {contract.name}
            </div>

            <div className="buttonsContainer" style={{marginTop: "15px"}}>
                <ContractStatusView contract={contract} userSession={props.$store.userSession}/>
            </div>
        </div>
    }

    return <div>
        {
            mobile ? mobileButtonsContainer() : desktopButtonsContainer()
        }

        {
            mobile ? mobileNameContainer() : desktopNameContainer()
        }

        {
            contractStatus(contract) == ContractStatus.AwaitingConfirmation && contract.first_signer?._id == props.$store.userSession?._id &&
            <div className="commonSubtitle" style={{marginTop: "15px"}}>
                Договор подписан вашим клиентом.
                <br/>
                Вам необходимо проверить введенные данные и завершить проверку если договор заполнен верно. Если в договоре присутствуют ошикби - вы можете отклонить договор по кнопке ✖️
            </div>
        }

        {
            contractStatus(contract) == ContractStatus.AwaitingConfirmation && contract.target_user?._id == props.$store.userSession?._id &&
            <div className="commonSubtitle" style={{marginTop: "15px"}}>
                Когда договор пройдет проверку, на вашу почту придет уведомление.
                <br/>
                Если договор долго висит в статусе проверки - обратитесь к тому, кто вам выслал ссылку на договор.
            </div>
        }

        {
            !errorClosed && props.errorMessage &&
            <ErrorView message={props.errorMessage} onClose={() => {setErrorClosed(true)}}/>
        }

        {
            showHelp &&
            <ContractHelpPopup onClose={() => {setShowHelp(false)}}/>
        }

        {
            fillEntitiesPopup &&
            <FillEntitiesPopup
                contract={contract}
                close={() => {
                    setFillEntitiesPopup(false)
                }}
                updateEntity={updateEntity}
                finish={(contractId: string) => {
                    setFillEntitiesPopup(false)

                    if (contractId) {
                        $router.router.navigate(
                            'contract',
                            {contractId: contractId.length === 24 ? contractId : contract._id}
                        )
                        location.reload()

                        if (contract.redirect_url) {
                            setTimeout(() => {
                                location.href = contract.redirect_url
                            }, 3000)
                        }
                    } else if (!props.$store.userSession) {
                        setTimeout(() => $router.router.navigate('main'), 0)
                    }
                }}
                $store={$store}
                $commitToStore={$commitToStore}
            />
        }

        {
            editButtonForEditSignedContractPopup &&
            <EditSignedContractPopup
                onClose={() => {
                    setEditButtonForEditSignedContractPopup(false)
                }}
                contract={contract}
                reload={loadContract}
                $store={props.$store}
                $commitToStore={props.$commitToStore}
            />
        }
    </div>
}

ContractViewHeader = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(ContractViewHeader)

export default ContractViewHeader
