import {connect} from "react-redux";
import {clientRoles, ChooseAccountTypeFrom} from "../Auth/UserForms/ChooseAccountTypeFrom";
import React, {useEffect, useState} from "react";
import CustomerForm from "../Auth/UserForms/CustomerForm";
import ContactsForm from "../Auth/UserForms/ContactsForm";
import {FormInputMask, FormInputPlaceholder} from "../Auth/UserForms/FormInput";
import {PassportForm} from "../Auth/UserForms/PassportForm";
import EntrepreneurForm from "../Auth/UserForms/EntrepreneurForm";
import BankDetailsForm from "../Auth/UserForms/BankDetailsForm";
import {validateMany} from "../../validate";
import {notifyAboutError} from "../../methods/notifyUser";
import UserStamp from "../Profile/UserStamp";
import {allPassportTypes, PassportType} from "../../methods/SystemEntity";
import _ from "lodash";
import {passportIssuedAtTitle} from "../../methods/workWithEntities";

type Props = {
    editingCard?: any,
    saveCard: Function
}

let UserCardForm: any = function UserCardForm(props: Props) {
    const [form, setForm] = useState<any>(  {
        _id: props.editingCard?._id ?? '',
        last_name: props.editingCard?.last_name ?? '',
        first_name: props.editingCard?.first_name ?? '',
        middle_name: props.editingCard?.middle_name ?? '',

        passport_number: props.editingCard?.passport?.number ?? '',
        passport_issued_by_code: props.editingCard?.passport?.issued_by_code ?? '',
        passport_issued_by: props.editingCard?.passport?.issued_by ?? '',
        passport_issued_at: passportIssuedAtTitle(props.editingCard?.passport?.issued_at),
        passport_registration: props.editingCard?.passport?.registration ?? '',

        law_name: props.editingCard?.law_name ?? '',
        law_address: props.editingCard?.law_address ?? '',
        tax_id: props.editingCard?.tax_id ?? '',
        ogrn: props.editingCard?.ogrn ?? '',

        bank_name: props.editingCard?.bank_name ?? '',
        bank_account: props.editingCard?.bank_account ?? '',
        corr_account: props.editingCard?.corr_account ?? '',
        bic: props.editingCard?.bic ?? '',

        email: props.editingCard?.email ?? '',
        phoneNumber: props.editingCard?.phoneNumber ?? '',

        role: props.editingCard?.role ?? clientRoles[0],
        passport_type: props.editingCard?.passport_type ?? PassportType.russianPassport,
        organisation_stamp: props.editingCard?.organisation_stamp ?? '',
    })

    const stampInputState = useState(form.organisation_stamp);
    const [stampValue, setStampValue] = stampInputState;

    useEffect(() => {
        setForm({
            ...form,
            organisation_stamp: stampValue
        })
    }, [stampValue])

    async function validateAndSave() {
        if (form.role == clientRoles[1]) {
            await validateAndSaveCustomerCard()
        } else {
            await validateAndSaveEntrepreneurCard()
        }
    }

    async function validateAndSaveEntrepreneurCard() {
        const email = form.email.trim()

        const law_name = form.law_name.trim()
        const law_address = form.law_address.trim()

        const bank_name = form.bank_name.trim()

        let validationErrors = validateMany([
            {required: true,  rule: "lawName",           entity: law_name,          displayFieldText: 'Имя вашего физического или юридического лица'},
            {required: true,  rule: "lawAddress",        entity: law_address,       displayFieldText: 'Адрес регистрации'},

            {required: true,  rule: "ruTaxId",           entity: form.tax_id,       displayFieldText: 'ИНН'},
            {required: true,  rule: "ruOgrn",            entity: form.ogrn,         displayFieldText: 'ОГРН'},

            {required: false, rule: "bankName",          entity: bank_name,         displayFieldText: 'Название банка'},
            {required: false, rule: "ruBankAccount",     entity: form.bank_account, displayFieldText: 'Расчетный счет'},
            {required: false, rule: "ruBankCorrAccount", entity: form.corr_account, displayFieldText: 'Корреспондентский счет'},
            {required: false, rule: "bic",               entity: form.bic,          displayFieldText: 'БИК'},

            {required: true,  rule: "email",             entity: email,             displayFieldText: 'E-Mail'},
        ])

        if (validationErrors.length) {
            notifyAboutError(props, new Error(validationErrors.join("\n")))
            return
        }

        props.saveCard(form)
    }

    function validateAndSaveCustomerCard() {
        const email = form.email.trim()

        let number = (form.passport_number ?? "").trim()
        let issued_at = form.passport_issued_at ?? ""
        let issued_by_code = form.passport_issued_by_code ?? ""
        let issued_by = (form.passport_issued_by ?? "").trim()
        let registration = (form.passport_registration ?? "").trim()

        let validationErrors = validateMany([
            {required: true,  rule: "firstName",  entity: form.first_name.trim(),  displayFieldText: 'Имя'},
            {required: false, rule: "middleName", entity: form.middle_name.trim(), displayFieldText: 'Отчество'},
            {required: true,  rule: "lastName",   entity: form.last_name.trim(),   displayFieldText: 'Фамилия'},

            {required: false, rule: form.client_passport_type === PassportType.russianPassport ? "ruPassportNumber" : "anotherPassportNumber", entity: number, displayFieldText: 'Серия и номер паспорта'},
            {required: false, rule: "ruPassportIssuedDidAt",  entity: issued_at,      displayFieldText: 'Дата выдачи паспорта'},
            {required: false, rule: "ruPassportIssuedByCode", entity: issued_by_code, displayFieldText: 'Код подразделения'},
            {required: false, rule: "notEmpty",               entity: issued_by,      displayFieldText: 'Кем выдан паспорт'},
            {required: false, rule: "notEmpty",               entity: registration,   displayFieldText: 'Адрес регистрации'},
            
            {required: true,  rule: "email", entity: email, displayFieldText: 'E-Mail'},
        ])

        if (validationErrors.length) {
            notifyAboutError(props, new Error(validationErrors.join("\n")))
            return
        }

        props.saveCard(form)
    }

    function makeForm() {
        if (form.role === clientRoles[1]) {
            return makeCustomerForm()
        } else {
            return makeEntrepreneurForm()    
        }
    }
    
    function makeCustomerForm() {
        return <div>
            <CustomerForm
                lastName={form.last_name}
                onChangeLastName={(value: string) => {
                    setForm({ ...form, last_name: value })
                }}

                firstName={form.first_name}
                onChangeFirstName={(value: string) => {
                    setForm({ ...form, first_name: value })
                }}

                middleName={form.middle_name}
                onChangeMiddleName={(value: string) => {
                    setForm({ ...form, middle_name: value })
                }}
            />

            <PassportForm
                form={{
                    choosePassportType: {
                        client_passport_type: form.client_passport_type,
                        allowedPassportTypes: allPassportTypes,
                        onChange: (value: string) => {
                            setForm({...form, passport_type: value})
                        }
                    },

                    number: {
                        placeholder: form.client_passport_type === PassportType.russianPassport
                            ? FormInputPlaceholder.passportNumberRF
                            : FormInputPlaceholder.passportNumberForeignCountry,
                        value: form.passport_number,
                        onChange: (value: string) => {
                            setForm({ ...form, passport_number: value })
                        },
                        mask: form.client_passport_type === PassportType.russianPassport
                            ? FormInputMask.passportNumber
                            : undefined
                    },
                    issuedAt: {
                        placeholder: FormInputPlaceholder.passportIssuedAt,
                        value: form.passport_issued_at,
                        onChange: (value: string) => {
                            setForm({ ...form, passport_issued_at: value })
                        },
                        mask: FormInputMask.passportIssuedAt
                    },
                    issuedByCode: {
                        placeholder: FormInputPlaceholder.passportIssuedByCode,
                        value: form.passport_issued_by_code,
                        onChange: (value: string) => {
                            setForm({ ...form, passport_issued_by_code: value })
                        },
                        mask: FormInputMask.passportIssuedByCode
                    },
                    issuedBy: {
                        placeholder: FormInputPlaceholder.passportIssuedBy,
                        value: form.passport_issued_by,
                        onChange: (value: string) => {
                            setForm({...form, passport_issued_by: value})
                        }
                    },
                    registration: {
                        placeholder: FormInputPlaceholder.passportRegistration,
                        value: form.passport_registration,
                        onChange: (value: string) => {
                            setForm({...form, passport_registration: value})
                        }
                    }
                }}
            />
        </div>
    }
    
    function makeEntrepreneurForm() {
        return <div>
            <EntrepreneurForm
                fullDataRequired={false}
                form={copyWithoutClient(form)}
                updateProperty={(id: string, value: string) => {
                    let updatedForm = {...form}
                    _.set(updatedForm, id.replace('client_', ''), value)
                    setForm(updatedForm)
                }}
            />

            {
                bankDetailsForm()
            }
        </div>
    }

    function copyWithoutClient(form: any): any {
        return {
            ...form,
            client_law_name: form.law_name,
            client_law_address: form.law_address,
            client_tax_id: form.tax_id,
            client_ogrn: form.ogrn,

            client_bank_name: form.bank_name,
            client_bank_account: form.bank_account,
            client_corr_account: form.corr_account,
            client_bic: form.bic
        }
    }

    function bankDetailsForm() {
        return <BankDetailsForm
            form={copyWithoutClient(form)}
            updateProperty={(id: string, value: string) => {
                let updatedForm = {...form}
                _.set(updatedForm, id.replace('client_', ''), value)
                setForm(updatedForm)
            }}
        />
    }

    function stampForm() {
        return <div style={{marginTop: "15px"}}>
            <div className="commonLabel" style={{marginBottom: "15px"}}>
                Печать или подпись
            </div>
            <UserStamp
                inputState={stampInputState}
            />
        </div>
    }

    function contactsForm() {
        return <ContactsForm
            email={{
                placeholder: FormInputPlaceholder.email,
                value: form.email,
                onChange: (value: string) => {
                    setForm({ ...form, email: value })
                }
            }}
        />
    }
    
    return <div>
        <ChooseAccountTypeFrom
            title={"Тип карточки подписанта"}
            role={form.role}
            onChange={(role: string) => {
                setForm({
                    ...form,
                    role: role
                })
            }}
        />

        {
            makeForm()
        }

        {
            contactsForm()
        }

        {
            stampForm()
        }

        <hr className="newHR"/>

        <button
            className="new withTitle _wa"
            onClick={async () => {
                await validateAndSave()
            }}
        >
            Сохранить карточку
        </button>
    </div>
}

UserCardForm = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) })
)(UserCardForm)

export default UserCardForm
