import { httpClient } from "funcs"
import React, {useEffect, useRef, useState} from "react"
import { connect } from "react-redux"

import "styles/MUI-style.sass"

import { useRoute } from "react-router5"

import {Backdrop, CircularProgress, useMediaQuery} from "@mui/material";

import "pages/Styles/EditContractCommon.sass"
import "pages/Styles/EditContractEditor.sass"
import "pages/Styles/EditContractEntities.sass"
import {notifyAboutError} from "../../../methods/notifyUser";
import {PageLoader} from "../../Contracts/ContractList/PageLoader";
import ContractFooter from "../ContractFooter";
import {Upload} from "@mui/icons-material";
import {Document, Page, pdfjs} from "react-pdf";
import {TemplateGuideView} from "../../Templates/TemplatesHelpPopup";
import CreateContractView from "../../CreateContract/CreateContractView";
import _ from "lodash";
import useUpdateTemplate from "../../../utils/hooks/template/useUpdateTemplate";
import CreatePDFHeader from "./CreatePDFHeader";
import NewContractPopup from "../NewContractPopup";
import {entityById, systemEntityValueByKeyword} from "../../../methods/systemEntityValueByKeyword";
import {clientPhoneNumber} from "../../../methods/SystemEntity";
import {
    addEntities,
    clearedEntitiesTemplate,
    deleteEntities,
} from "../../../methods/workWithEntities";
import {validateRegisterFormWhileCreatingContract} from "../../../methods/registerFormValidation";
import {defaultUserFormForContractCreating} from "../../Auth/RegisterDefaultValues";
import {trackGoal} from "../../../methods/trackGoal";
import * as process from "process";
import {mobileMaxWidthMediaQuery} from "../../../methods/utils";

let CreatePDFPage: any = function CreatePDFPage(props: any) {
    const $router = useRoute();

    const [template, setTemplate] = useState<any>(null)
    const [contractInfo, setContractInfo] = useState<any>(null)
    const [file, setFile] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [numPages, setNumPages] = useState(0);

    const uploadRef = useRef<HTMLInputElement>(null);
    const [isChanging, setIsChanging] = useUpdateTemplate(template, updateTemplate)

    const [dragActive, setDragActive] = useState(false);

    const mobile = useMediaQuery(mobileMaxWidthMediaQuery)

    // handle drag events
    const handleDrag = function(event: any) {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    }

    const handleDrop = function(event: any) {
        console.log("handleDrop", event.dataTransfer.files)
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files) {
            processFiles(event.dataTransfer.files)
        }
    };

    function processFiles(files: FileList | null) {
        const file = files && files.length ? files[0] : null;
        let name = file?.name ?? "";
        let components = name.split(".");
        components.pop();
        name = components.join(".");
        setTemplate({...template, name: name})
        setFile(file)
    }

    async function saveContract() {
        setLoading(true)

        try {
            let validationErrors: string[] = []

            if (!file) validationErrors.push("Файл не выбран");
            if (validationErrors.length) throw new Error(validationErrors.join("\n"));

            let userFormErrors = validateRegisterFormWhileCreatingContract(
                template.system_entities,
                defaultUserFormForContractCreating(template)
            )

            if (userFormErrors.length > 0) {
                throw new Error(userFormErrors.join(".\n\n"))
            }

            trackGoal('new_pdf_contract')

            const formData = new FormData();

            formData.append("file", file, template.name);

            formData.append(
                'system_entities',
                JSON.stringify(template.system_entities)
            )

            formData.append(
                'settings',
                JSON.stringify(template.settings)
            )

            if (template.actual_user_card_id) {
                formData.append(
                    'actual_user_card_id',
                    template.actual_user_card_id
                )
            }

            const {data: contractInfo} = await httpClient.post("/contract/new-pdf-contract", formData)

            setContractInfo({
                contractId: contractInfo.contract_id,
                phone_number: entityById(template.system_entities, clientPhoneNumber.id)?.value
            })
            await clearEntities()
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setLoading(false)
        }
    }

    async function updateTemplate() {
        setIsChanging("saving")
        try {
            await httpClient.put(`/template/${template._id}`, {
                body: '',
                body_type: 'pdf',
                name: template.name,
                comment: template.comment,
                entities: JSON.stringify([]),
                system_entities: JSON.stringify(template.system_entities),
                guides: template.guides,
                settings: JSON.stringify(template.settings)
            })
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setIsChanging('saved')
        }
    }

    // Load template function
    async function loadTemplate() {
        setLoading(true)
        try {
            const {data: templates} = await httpClient.get(`/get-pdf-templates`)

            setTemplate(templates[0]);
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setLoading(false)
        }
    }

    async function clearEntities() {
        setTemplate(clearedEntitiesTemplate(template))
        await updateTemplate()
    }

    // Load template
    useEffect(() => {
        loadTemplate();
    }, []);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    });

    function updateContractSettings(settings: any) {
        setTemplate({
            ...template,
            settings
        })

        setIsChanging('debouncing')
    }

    function getEntities(isSystem: boolean): any[] {
        let path = isSystem ? 'system_entities' : 'entities'

        return [..._.get(template, path, [])]
    }

    function _addEntities(isSystem: boolean, entities: any[]) {
        let updatedTemplate = addEntities(template, isSystem, entities)
        setTemplate(updatedTemplate)
        setIsChanging('debouncing')
    }

    function _deleteEntities(isSystem: boolean, keywords: string[]) {
        let updatedTemplate = deleteEntities(template, isSystem, keywords)
        setTemplate(updatedTemplate)
        setIsChanging('debouncing')
    }

    function makeEditor() {
        return <div id="editor">
            {
                !file &&
                makeLoadPDFView()
            }
            {
                file &&
                makeDocumentViewer()
            }
            {
                file &&
                makeFooter()
            }
        </div>
    }

    function makeDocumentViewer() {
        if (!file) {
            return
        }
        let width = mobile ? window.innerWidth - 32 : 622
        let pages: number[] = Array.from(Array(numPages).keys())
        return <Document
            onLoadSuccess={(result) => {
                setNumPages(result.numPages)
            }}
            noData={'Не удалось отобразить PDF'}
            error={'Не удалось отобразить PDF'}
            file={file}
        >
            {
                numPages && pages.map((i) =>
                    <div style={{marginBottom: "15px"}}>
                        <Page
                            pageNumber={i + 1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            width={width}
                        />
                    </div>
                )
            }
        </Document>
    }

    function dropAreaView() {
        return <div>
            <input
                type="file"
                style={{display: "none"}}
                ref={uploadRef}
                accept=".pdf"
                onChange={({target: {files}}) => {
                    processFiles(files)
                }}
            />
            <div className={"commonSubtitle"}
                 style={{textAlign: "center", marginBottom: "15px", pointerEvents: "none"}}>
                Перетащите сюда файл с расширением .pdf или воспользуйтесь кнопкной
            </div>
            <button
                className="new withTitle secondary _wa"
                style={{pointerEvents: "none"}}
            >
                <div
                    className="buttonsContainer"
                    style={{justifyContent: "center"}}
                >
                    <Upload className="icon" style={{fill: "#0E1F4D"}}/>
                    <span>
                        {
                            file ? `${template.name}` : `Выбрать файл`
                        }
                    </span>
                </div>
            </button>
        </div>
    }
    function makeLoadPDFView() {
        return <div>
            <div
                onDragEnter={handleDrag}
                onDragExit={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}

                style={{
                    border: dragActive ? "1px dashed #75809E" : "1px dashed #D4D8E1",
                    borderRadius: "20px",
                    padding: "50px"

                }}
            >
                {
                    dragActive && dropAreaView()
                }
                {
                    !dragActive &&
                    <div
                        onClick={(e) => {
                            uploadRef.current?.click()
                        }}
                    >
                        {dropAreaView()}
                    </div>
                }
            </div>
        </div>
    }
    function makeFooter() {
        return <div className="editContractFooterContainer">
            <ContractFooter
                template={template}
                showTip={true}
                $store={props.$store}
                generateStamp={true}
            />
        </div>
    }

    // Render
    return <div className="CreatePDFPage" style={{maxWidth: "1090px"}}>
        <div className="NewContractPage_actNew">
            {
                loading && !template &&
                <PageLoader />
            }
            {
                template &&
                <div>
                    <div>
                        <CreatePDFHeader
                            template={template}
                            saveContract={saveContract}
                            isChanging={isChanging}
                            saveNewName={(newName: string) => {
                                setTemplate({...template, name: newName})
                                setIsChanging("debouncing")
                            }}
                            hasFile={file}
                            deleteFile={() => {setFile(null)}}
                            $store={props.$store}
                        />
                    </div>
                    <div className="editContractConstructor readOnly" style={{flexDirection: mobile ? "column" : "unset"}}>
                        <section style={{maxWidth: "653px", marginTop: mobile ? "133px" : "0px"}}>
                            {
                                makeEditor()
                            }
                        </section>
                        <aside>
                            <div className={"sectionsContainer"} style={{marginTop: mobile ? "15px" : "0px"}}>
                                <TemplateGuideView
                                    template={template}
                                    toggleExpanded={props.toggleGuideExpanded}
                                />

                                <CreateContractView
                                    contract={template}
                                    updateActualUserCardId={(actual_user_card_id: string | null) => {
                                        setTemplate({...template, actual_user_card_id: actual_user_card_id})
                                    }}
                                    isTemplate={true}
                                    updateContractSettings={updateContractSettings}
                                    addEntities={_addEntities}
                                    deleteEntities={_deleteEntities}
                                    $store={props.$store}
                                />
                            </div>
                        </aside>
                    </div>
                </div>
            }
        </div>
        {
            contractInfo &&
            <NewContractPopup
                contractId={contractInfo.contractId}
                shouldSign={true}
                settings={template.settings}
                templateId={template._id}
                clientPhoneNumber={contractInfo.phone_number}
                close={() => {
                    $router.router.navigate("new-contract", {act: "list"}, {reload: true})
                }}
            />
        }
        {
            <Backdrop
                sx={{ backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 999 }}
                open={loading}
            >
                <CircularProgress sx={{color: "#1FA3EE"}} />
            </Backdrop>
        }
    </div>
};

CreatePDFPage = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(CreatePDFPage)

export default CreatePDFPage
