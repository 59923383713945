import React, {useEffect, useState} from "react"
import {connect} from "react-redux"

import _ from "lodash"

import InputMask from "react-input-mask";

import {httpClient} from "funcs"
import Popup from "../../components/Popup";
import {commonTextField, TextareaStyled, TextFieldStyled} from "../Styles/CommonCSSProperties";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {ReactComponent as Edit} from "../Icons/size24/Edit.svg"
import {useMediaQuery} from "@mui/material";
import cleanPhoneNumber from "../../methods/cleanPhoneNumber";

import {BANK_DETAIL_FIELDS} from "./BankDetailsView";
import UserStamp, {ENTREPRENEUR_STAMP_KEY} from "./UserStamp"
import moment from "moment";
import {validate} from "../../validate";
import {MobileInputMask} from "../../components/elements/InputWithMask";
import VerificationCodeView from "../Auth/VerificationCodeView";
import {sendVerificationCode} from "../../methods/sendVerificationCode";
import {cleanDigitsField} from "../../methods/cleanDigitsField";

const MAIN_FIELDS = [
  {
    key: "email",
    label: "E-Mail",
    mask: "",
  },
  {
    key: "phone_number",
    label: "Телефон для входа"
  },
  {
    key: "additional_phone_number",
    label: "Телефон для клиентов",
  }
]

const CUSTOMER_FIELDS = [
  {
    key: "last_name",
    label: "Фамилия",
    mask: "",
  },
  {
    key: "first_name",
    label: "Имя",
    mask: "",
  },
  {
    key: "middle_name",
    label: "Отчество",
    mask: "",
  },
  {
    key: "law_address",
    label: "Адрес регистрации",
    mask: "",
  },
  {
    key: "tax_id",
    label: "ИНН",
    mask: "999999999999",
  },
  {
    key: "passport.number",
    label: "Серия и номер",
    mask: "9999 999999",
  },
  {
    key: "passport.issued_by_code",
    label: "Код подразделения",
    mask: "999-999",
  },
  {
    key: "passport.issued_at",
    label: "Дата выдачи паспорта",
    mask: "99.99.9999",
  },
]

const ENTREPRENEUR_FIELDS = [
  {
    key: "law_name",
    label: "Полное наименование юр. лица",
    mask: "",
  },
  {
    key: "law_address",
    label: "Адрес регистрации",
    mask: "",
  },
  {
    key: "tax_id",
    label: "ИНН",
    mask: "999999999999",
  },
  {
    key: "ogrn",
    label: "ОГРН",
    mask: "999999999999999",
  }
]

const ALL_FIELDS = [...MAIN_FIELDS, ...ENTREPRENEUR_FIELDS, ...CUSTOMER_FIELDS, ...BANK_DETAIL_FIELDS];

function getBankDetailsObject(userSession: object, fields: Array<object>) {
  const result: any = {};

  fields.forEach(({key}: any) => {
    let value = _.get(userSession, key, null)
    if (key == 'additional_phone_number' && value) {
      result[key] = cleanPhoneNumber(value, true)
      return;
    }

    if (key === "passport.issued_at" && value) {
      result[key] = moment(value).format('DD.MM.YYYY')

      return;
    }

    result[key] = value
  })

  return result
}

let UpdateUserFields: any = function UpdateUserFields(props: any) {
  const userSession = props.$store.userSession;
  const [showPopForUpdatingUserFields, setShowPopForUpdatingUserFields] = useState<any>(false)

  const stampInputState = useState(_.get(userSession, ENTREPRENEUR_STAMP_KEY, ""));
  const [stampValue, setStampValue] = stampInputState;
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationCodePopup, setVerificationCodePopup] = useState(false)
  const [codeChannel, setCodeChannel] = useState('sms')

  const [userFields, setUpdateUserFields] = useState<any>(
    getBankDetailsObject(userSession, ALL_FIELDS)
  )

  // обновление значений формы
  useEffect(
    () => {
      setUpdateUserFields(getBankDetailsObject(userSession, ALL_FIELDS))
      setStampValue(_.get(userSession, ENTREPRENEUR_STAMP_KEY, ""))
    },
    [showPopForUpdatingUserFields]
  )

  const mobile = useMediaQuery("(max-width: 760px)");

  async function updateSelf() {
    let fields = {...userFields}

    for(let key in fields) {
      let value = fields[key]
      if (value != null && value.length > 0) {
        fields[key] = value.trim()
      }
    }

    let phoneNumber = cleanPhoneNumber(fields.phone_number)
    if (phoneNumber && phoneNumber.slice(0,1) != '+') {
      phoneNumber = '+' + phoneNumber
    }
    fields.phone_number = phoneNumber

    if (phoneNumber != userSession.phone_number && verificationCode.length == 0) {
      if (!await sendVerificationCode(props, phoneNumber, 'sms')) {
        return
      }
      setVerificationCodePopup(true)
      return;
    }

    fields['verification_code'] = verificationCode

    let additionalPhone = cleanPhoneNumber(fields.additional_phone_number)
    if (additionalPhone && additionalPhone.slice(0,1) != '+') {
      additionalPhone = '+' + additionalPhone
    }
    fields.additional_phone_number = additionalPhone

    fields.tax_id = cleanDigitsField(fields.tax_id)

    fields.ogrn = cleanDigitsField(fields.ogrn)

    fields[ENTREPRENEUR_STAMP_KEY] = stampValue;

    let issued_at = _.get(fields, "passport.issued_at", "")
    if (issued_at) {
      let issued_at_error = validate('ruPassportIssuedDidAt', issued_at, "Дата выдачи паспорта")
      if (issued_at_error) {
        notifyUser(props, "Ошибка в заполнении поля", issued_at_error)
        return
      } else {
        fields["passport.issued_at"] = moment(issued_at, 'DD.MM.YYYY').format('YYYY-MM-DD')
      }
    }

    try {
      await httpClient.patch("/user/self", fields)
      setShowPopForUpdatingUserFields(false)
      notifyUser(props, "Данные обновлены")
      setTimeout(() => {
        location.reload()
      }, 300)
    } catch (error) {
      notifyAboutError(props, error)
    } finally {
      setVerificationCode('')
    }
  }

  function phoneNumberInput(key: string, label: string) {
    return <div className="commonTextfield" style={{width: "100%"}}>
      <MobileInputMask
          ref={null}
          value={userFields[key]}
          disabled={false}
          onChange={(value: string) => {
            setUpdateUserFields({ ...userFields, [key]: value })
          }}
          style={{...(commonTextField())}}
      />
    </div>
  }

  function input(key: string, label: string, mask: string) {
    return mask
        ? <InputMask
            mask={mask}
            placeholder={label}
            value={userFields[key]}
            disabled={false}
            onChange={({ target: { value } }: any) => setUpdateUserFields({ ...userFields, [key]: value })}
        >
          <TextFieldStyled id={key}/>
        </InputMask>
        : <TextareaStyled
            placeholder={label}
            value={userFields[key]}
            onChange={({ target: { value } }: any) => setUpdateUserFields({ ...userFields, [key]: value })}
        />
  }

  function prepareElementsByFields(fields: Array<object>) {
    return fields.map(({key, label, mask}: any) => {
      return (
        <div className="common">
          <div
              className="commonSubtitle"
              style={{
                display: "flex",
                width: mobile ? "40%" : "50%",
                alignItems: "center",
                marginTop: mobile ? "15px" : "0px",
                marginRight: "5px"
              }}
          >
            {label}:
          </div>

          <div style={{width: "400px", height: "63px", marginTop: "5px"}}>
          {
            (key == "phone_number" || key == "additional_phone_number")
                  ? phoneNumberInput(key, label)
                  : input(key, label, mask)
          }
          </div>
        </div>
      )
    })
  }


  return (
    <>
      <button className="new" style={{marginRight: "0px"}} onClick={() => setShowPopForUpdatingUserFields(true)}>
        <Edit className="icon dark" />
      </button>

      {
          showPopForUpdatingUserFields && !verificationCodePopup &&
          <Popup
              style={{zIndex: 1000}}
              maxWidth="650px"
              onClose={() => setShowPopForUpdatingUserFields(false)}
          >
            <div style={{width: "100%"}}>

              <div className="commonLabel" style={{textAlign: "left"}}>
                Основная информация:
              </div>
              {prepareElementsByFields(MAIN_FIELDS)}


              {
                  userSession.role == 'customer' &&
                  <>
                    <div className="commonLabel" style={{textAlign: "left", marginTop: "20px"}}>
                      Паспортные данные:
                    </div>
                    {
                      prepareElementsByFields(CUSTOMER_FIELDS)
                    }
                  </>
              }

              {
                  userSession.role == 'entrepreneur' &&
                  <>
                    <div className="commonLabel" style={{textAlign: "left", marginTop: "20px"}}>
                      Данные юр. лица:
                    </div>
                    {
                      prepareElementsByFields(ENTREPRENEUR_FIELDS)
                    }
                  </>
              }

              <div className="commonLabel" style={{textAlign: "left", marginTop: "20px"}}>
                Реквизиты:
              </div>
              {
                prepareElementsByFields(BANK_DETAIL_FIELDS)
              }

              <div className="commonLabel" style={{textAlign: "left", marginTop: "20px"}}>
                Печать:
              </div>
              <UserStamp
                  style={{marginTop: "15px"}}
                  inputState={stampInputState}
              />

              <hr className="newHR"/>

              <button
                  style={{marginTop: "20px", width: "100%", maxWidth: mobile ? "700px" : "300px", margin: "auto"}}
                  className="new withTitle _wa"
                  onClick={() => {
                    updateSelf()
                  }}
              >
                Обновить
              </button>
            </div>
          </Popup>
      }
      {
          verificationCodePopup &&
          <Popup
              onClose={() => setVerificationCodePopup(false)}
          >
            <VerificationCodeView
                verification_code={verificationCode}
                code_channel={codeChannel}
                phone_number={userFields.phone_number}
                disabled_code_channels={userSession?.disabled_code_channels ?? []}
                updateVerificationCode={(value: string) => {
                  setVerificationCode(value)
                }}
                updateCodeChannel={(value: string) => {
                  setCodeChannel(value)
                }}
                finish={ async () => {
                  setVerificationCodePopup(false)
                  updateSelf()
                }}
                back={() => {
                  setVerificationCodePopup(false)
                }}
                $store={props.$store}
            />
          </Popup>
      }
    </>
  )
}

UpdateUserFields = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
      $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(UpdateUserFields)

export default UpdateUserFields
