import Popup from "../../components/Popup";
import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {TextareaStyled} from "../Styles/CommonCSSProperties";
import {validateMany} from "../../validate";
import {httpClient, nError} from "../../funcs";
import {useRoute} from "react-router5";
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {ReactComponent as Upload} from "../Icons/size24/Upload.svg"

type Props = {
    onClose: Function,
    didAddTemplate: Function
}

let AddTemplatePopup: any = function AddTemplatePopup(props: Props) {
    const $router = useRoute();

    const {onClose, didAddTemplate} = props

    const [newTemplateForm, setNewTemplateForm] = useState<any>({
        name: "",
        _file: null,

        $disabled: false,
    });

    const [readyTemplates, setReadyTemplates] = useState<any[]>([])

    const newTemplateUploadRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        loadReadyTemplates()
    }, [])

    function loadFile(files: FileList | null) {
        const file =
            files && files.length ? files[0] : null;
        let name = (file?.name ?? "")
            .trim()
            .split('_')
            .join(' ')
        let components = name.split(".");
        components.pop();
        name = components.join(".");
        setNewTemplateForm({
            ...newTemplateForm,
            name: name,
            _file: file,
        })
    }

    async function addNewTemplate() {
        // Disallow sending form again
        setNewTemplateForm({
            ...newTemplateForm,
            $disabled: true,
        });

        let {name} = newTemplateForm;

        let file: any = newTemplateUploadRef.current?.files
            ? newTemplateUploadRef.current?.files[0]
            : null;

        // Prepare data
        name = name.trim()

        try {
            // Validate input data
            const validationErrors = validateMany([
                {
                    rule: "contractName",
                    entity: name,
                    displayFieldText: "Название шаблона",
                },
            ]);

            if (!file) validationErrors.push("Файл не выбран");

            if (validationErrors.length) throw new Error(validationErrors.join("\n"));

            // Prepare form data
            const formData = new FormData();

            formData.append("name", name);
            formData.append("file", file, file.name);

            // Send request
            const {data: newTemplate} = await httpClient.post("/template", formData);

            // Update templates list

            didAddTemplate(newTemplate)

            // Close popup
            onClose();
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            // Allow sending form again
            setNewTemplateForm({
                ...newTemplateForm,
                $disabled: false,
            });
        }
    }

    async function loadReadyTemplates() {
        try {
            const {data: templates} = await httpClient.get("/ready-templates");
            setReadyTemplates(templates)
        } catch(error) {
            notifyAboutError(props, error)
        }
    }

    async function addReadyTemplate(templateId: string) {
        try {
            const {data: newTemplate} = await httpClient.post("/add-ready-template", {template_id: templateId})
            didAddTemplate(newTemplate)
        } catch(error) {
            notifyAboutError(props, error)
        }
    }

    function makeLoadDocumentView() {
        return <div>
            <div className="commonLabel" style={{marginTop: "25px"}}>
                Чтобы добавить свой шаблон, нужно загрузить word файл вашего
                договора с расширением .docx.
            </div>
            <div className="commonSubtitle" style={{marginTop: "5px"}}>
                После этого обязательно посмотрите
                <a href="https://rutube.ru/video/d379fca0fd22239f97424d8af7fc9000/" target="_blank"> наше видео </a>
                о том, как настроить свой шаблон. Видео так же будет в колонке справа от текста договора.
                <br/>
                <br/>
                Если у вас возникают трудности с настройкой шаблона - можете обратиться в техподдержку, либо
                <a href="https://doki.online/template" target="_blank"> оставить заявку на настройку вашего шаблона.</a>
            </div>
            <div className="form-field">
                <TextareaStyled
                    placeholder={"Название шаблона"}
                    value={newTemplateForm.name}
                    onChange={({target: {value}}: any) => {
                        setNewTemplateForm({...newTemplateForm, name: value})
                    }}
                />
            </div>

            <input
                type="file"
                style={{display: "none"}}
                ref={newTemplateUploadRef}
                accept=".docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={({target: {files}}) => {
                    loadFile(files)
                }}
            />
            <button
                className="new withTitle secondary _wa"
                onClick={(e) => {
                    newTemplateUploadRef.current?.click()
                }}
            >
                <div
                    className="buttonsContainer _wa"
                    style={{justifyContent: "center"}}
                >
                    <Upload style={{fill: "#0E1F4D", width: "24px"}}/>
                    <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {
                            newTemplateForm._file ? `${newTemplateForm._file.name}` : `Выбрать файл`
                        }
                    </div>
                </div>
            </button>
            <button
                className="new withTitle _wa"
                style={{marginTop: "15px"}}
                disabled={newTemplateForm.$disabled || newTemplateForm.name.length == 0 || newTemplateForm._file === null}
                onClick={() => {
                    addNewTemplate()
                }}
            >
                Добавить
            </button>
        </div>
    }

    function makeReadyTemplatesView() {
        return <div>
            <hr className="newHR"/>
            <div className="H3_21" style={{marginTop: "30px", marginBottom: "25px", textAlign: "center"}}>
                Готовые шаблоны
            </div>
            {
                readyTemplates.map((templateSection: any) => {
                    return <div style={{marginTop: "10px"}}>
                        <div className="Subheader_17">
                            {
                                templateSection.section_name
                            }
                        </div>

                        <div className="Body_15" style={{marginTop: "10px"}}>
                            {
                                templateSection.section_description
                            }
                        </div>

                        {
                            makeReadyTemplatesSectionView(templateSection)
                        }
                        <hr className="newHR"/>
                    </div>
                })
            }
        </div>
    }

    function makeReadyTemplatesSectionView(templateSection: any) {
        return <div>
            {
                templateSection.templates.map((template: any) => {
                    return <div
                        className="templateViewContainer disabledHover"
                        style={{marginTop: "10px"}}
                    >
                        <div className="titleContainer">
                            <div>
                                <div className="commonLabel">
                                    {template.name}
                                </div>
                                <div className="commonSubtitle">
                                    {template.comment}
                                </div>
                            </div>

                            <button
                                className="new withTitle small"
                                onClick={async () => {
                                    await addReadyTemplate(template._id)
                                }}
                            >
                                Добавить
                            </button>
                        </div>
                    </div>
                })
            }
        </div>
    }


    return <Popup
        style={{zIndex: 999}}
        onClose={onClose}
    >
        <div>
            {
                makeLoadDocumentView()
            }
            {
                makeReadyTemplatesView()
            }
        </div>
    </Popup>
}

AddTemplatePopup = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(AddTemplatePopup)

export default AddTemplatePopup