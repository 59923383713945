import React, {useEffect, useState} from 'react'

import "styles/components/popup.sass"
import "pages/Styles/UIKit.sass"

import {useMediaQuery} from "@mui/material";
import {useRoute} from "react-router5";
import {connect} from "react-redux";
import IncomingWebhookIntegrationPopup from "./IncomingWebhookIntegrationPopup";
import AmoCRMIntegrationPopup from "./AmoCRMIntegrationPopup";
import {HelpView} from "../../Common/HelpView";

let IntegrationsView: any = function IntegrationsView(props: any) {
    const [realtyIntegrationPopup, setRealtyIntegrationPopup] = useState(false);
    const [litePMSIntegrationPopup, setLitePMSIntegrationPopup] = useState(false);
    const [amoIntegrationPopup, setAmoIntegrationPopup] = useState(false);

    function helpViewDescription(serviceName: string) : string {
        return `На каждую новую бронь в ${serviceName} будет создаваться черновик договора в ОкиДоки.` +
            `Все параметры брони и данные клиента из ${serviceName} будут занесены в договор.\n` +
            'По умолчанию с данной интеграцией работает только шаблон "Краткосрочный найм". Если вы настраиваете свой шаблон, то называйте поля так же, как они названы в нашем готовом шаблоне краткосрочного найма.\n'
    }

    return <>
        <div className={"templateViewContainer disabledHover"}>
            <div className="commonTitle" style={{textAlign: "left"}}>
                Интеграции с CRM
            </div>

            <div className="commonSubtitle" style={{textAlign: "left"}}>
                Интеграции позволяют передавать данные из других CRM систем и автоматически создавать и заполнять договоры
            </div>

            <hr className={"newHR"}/>

            <div className="row-data">
                <button
                    className="new withTitle small"
                    onClick={() => setRealtyIntegrationPopup(true)}
                >
                    Realty Calendar
                </button>
            </div>

            <div className="row-data">
                <button
                    className="new withTitle small"
                    onClick={() => setLitePMSIntegrationPopup(true)}
                >
                    Lite PMS
                </button>
            </div>

            <div className="row-data">
                <button
                    className="new withTitle small"
                    onClick={() => setAmoIntegrationPopup(true)}
                >
                    amoCRM
                </button>
            </div>
        </div>

        {
            realtyIntegrationPopup &&
            <IncomingWebhookIntegrationPopup
                close={() => {setRealtyIntegrationPopup(false)}}
                serviceName={"Realty Calendar"}
                helpView={
                    <HelpView
                        title={"Интеграция с Realty Calendar"}
                        subtitle={helpViewDescription("Realty Calendar") +
                            "\n" +
                            "Подробнее о том, как работает интеграция и как ее настроить, смотрите в нашем видео"}
                        videoId={"mQgx_BoDUtE"}
                        rutubeVideoId={"f9bff61d5f12e46579cba1f251f77cad"}
                    />
                }
                realtyConfig={props.$store.userSession.realty_integration}
                $store={props.$store}
                $commitToStore={props.$commitToStore}
            />
        }

        {
            litePMSIntegrationPopup &&
            <IncomingWebhookIntegrationPopup
                close={() => {setLitePMSIntegrationPopup(false)}}
                serviceName={"Lite PMS"}
                helpView={
                    <HelpView
                        title={"Интеграция с Lite PMS"}
                        subtitle={helpViewDescription("Lite PMS") +
                            "\n" +
                            "Подробнее о том, как настроить интеграцию смотрите в инструкции:"}
                        externalLink={"https://litepms.ru/welcome/help?id=77"}
                        externalLinkTitle={"Инструкция по подключению"}
                    />
                }
                realtyConfig={props.$store.userSession.realty_integration}
                $store={props.$store}
                $commitToStore={props.$commitToStore}
            />
        }

        {
            amoIntegrationPopup &&
            <AmoCRMIntegrationPopup
                close={() => {setAmoIntegrationPopup(false)}}
                amoConfig={props.$store.userSession.amo_integration}
                $store={props.$store}
                $commitToStore={props.$commitToStore}
            />
        }
    </>
}

IntegrationsView = connect(
    (store) => ({ $store: store }),
    (dispatch) => ({
        $commitToStore: (data: any) => dispatch({ ...data, type: 'S' })
    })
)(IntegrationsView)

export default IntegrationsView
