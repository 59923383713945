import {clientFIO, clientFirstName, clientLastName, clientMiddleName} from "./SystemEntity";
import {useRoute} from "react-router5";

export function systemEntityValueByKeyword(contract: any, keyword: string, entityId: string | null = null) {
    if (!contract) {
        return null
    }

    if (!contract.system_entities) {
        return null
    }

    let system_entity = contract.system_entities.filter((entity: any) =>
        entity.keyword == keyword || entity.keyword == entityId || entity.id == entityId
    )[0]

    if (system_entity && typeof system_entity.value !== 'undefined' && system_entity.value?.length > 0) {
        return system_entity.value
    } else {
        let fioEntities = [clientFirstName.id, clientMiddleName.id, clientLastName.id]
        if (fioEntities.includes(keyword) || (entityId && fioEntities.includes(entityId))) {
            let fio = contract.system_entities.filter((entity: any) =>
                entity.keyword == clientFIO.keyword
            )[0]?.value
            if (!fio) {
                return null
            }

            let components = fio.replace(/ +(?= )/g,'').trim().split(" ")
            if (components.length == 3) {
                if (keyword == clientFirstName.id || entityId == clientFirstName.id) {
                    return components[1]
                }
                if (keyword == clientLastName.id || entityId == clientLastName.id) {
                    return components[0]
                }
                if (keyword == clientMiddleName.id || entityId == clientMiddleName.id) {
                    return components[2]
                }
                return null
            }

            // if (components.length == 2) {
            //     if (keyword == clientFirstName.id || entityId == clientFirstName.id) {
            //         return components[1]
            //     }
            //     if (keyword == clientLastName.id || entityId == clientLastName.id) {
            //         return components[0]
            //     }
            //     return null
            // }
            //
            // if (components.length == 1) {
            //     if (keyword == clientLastName.id || entityId == clientLastName.id) {
            //         return components[0]
            //     }
            //     return null
            // }

            return null
        } else {
            return null
        }
    }
}

export function canEditSystemEntityById(contract: any, entityId: string, keyword: string | null = null) {
    if (!contract) {
        return true
    }
    if (!contract.system_entities) {
        return true
    }

    const is_first_signer = useRoute().route.params.is_first_signer ?? false

    if (is_first_signer) {
        // Для первого подписанта вообще не смотрим на editable в system_entities.
        // editable в system_entities только для второго подписанта. Возможно, надо добавить editable_for_first_signer,
        // чтобы дать возможность настраивать для первого подписанта
        return true
    }

    let system_entity = contract.system_entities.filter((entity: any) =>
        entity.id == entityId || entity.keyword == keyword || entity.keyword == entityId
    )[0]

    return system_entity?.editable ?? true
}

export function dateFromIsoToPlain(isoDate: string) {
    if (!isoDate) {
        return ''
    }
    const date = new Date(isoDate)

    if (date.toString() === "Invalid Date" || isoDate.length <= 10) {
        return isoDate
    }

    return date.toLocaleDateString("ru-RU")
}

export function contractSystemEntityIsHidden(contract: any, entityId: string): boolean {
    return contractSystemEntity(contract, entityId)?.hidden ?? true
}

export function contractSystemEntity(contract: any, entityId: string): any | undefined {
    return (contract?.system_entities ?? []).filter((entity: any) => entity.id == entityId || entity.keyword == entityId)[0]
}

export function entityById(entities: any[], entityId: string): any | undefined {
    return entities.filter((entity: any) => entity.id == entityId || entity.keyword == entityId)[0]
}