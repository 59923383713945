import {httpClient, nError} from "funcs"
import React, {useEffect, useState} from "react"
import {connect} from "react-redux"

import "styles/MUI-style.sass"

import {useRoute} from "react-router5"

import {validateMany} from "validate"
import notifyUser, {notifyAboutError} from "../../methods/notifyUser";
import {Backdrop, CircularProgress, useMediaQuery} from "@mui/material";
import Editor from "../../components/Editor";
import ContractFooter from "./ContractFooter";

import "pages/Styles/EditContractCommon.sass"
import "pages/Styles/EditContractEditor.sass"
import "pages/Styles/EditContractEntities.sass"
import NewContractPopup from "./NewContractPopup";
import Entities from "../Entity/Entities";
import ContractHeader from "./ContractHeader";
import useUpdateTemplate from "../../utils/hooks/template/useUpdateTemplate";
import {addValueToSpanTags, clearValueInSpanTags} from "../../methods/addValueToSpanTags";
import {ReactComponent as ArrowUp} from "pages/Icons/size16/ArrowUp.svg"
import {emptyEntities, validateContractBeforeSave} from "../../methods/emptyEntities";
import {scrollToElement} from "../../methods/scrollTo";
import {PageLoader} from "../Contracts/ContractList/PageLoader";
import {calculateFormula} from "../../methods/parseFormula";
import {clearFormattingForEntities} from "../../methods/clearFormattingForEntities";
import {getActualUser, updateLocalEntities} from "../../methods/updateLocalEntities";
import {contractSystemEntity, entityById, systemEntityValueByKeyword} from "../../methods/systemEntityValueByKeyword";
import {getPostfixForEntity, userFullname} from "../../methods/utils";
import {addSpanTagsToBody} from "../../methods/addSpanTagsToBody";
import {checkChangesInBody} from "../../methods/checkChangesInBody";
import {trackGoal} from "../../methods/trackGoal";
import {
    addEntities,
    clearedEntitiesTemplate, deleteEntities,
    getEntities, renameEntity, setOrderForEntity, updateEntities,
    updateEntity,
    updateEntityInList
} from "../../methods/workWithEntities";
import {arrayMove} from "../../methods/arrayMove";
import {updateEntityInEditor} from "../../methods/updateEntityInEditor";
import _ from "lodash";
import {defaultUserFormForContractCreating} from "../Auth/RegisterDefaultValues";
import {validateRegisterFormWhileCreatingContract} from "../../methods/registerFormValidation";
import {clientFIO, clientPhoneNumber, firstSigner} from "../../methods/SystemEntity";

let EditContractPage: any = function EditContractPage(props: any) {
    const $router = useRoute();

    const [template, setTemplate] = useState<any>(null)
    const [needUpdateFormulas, setNeedUpdateFormulas] = useState(false)
    const [needUpdateOrder, setNeedUpdateOrder] = useState<any>(null)
    const [contract, setContract] = useState<any>(null)
    const [contractForm, setContractForm] = useState({
        $disabled: false,
        contractName: "",
    });

    const [isChanging, setIsChanging] = useUpdateTemplate(template, updateTemplate)
    const [loading, setLoading] = useState(false);
    const [shouldSign, setShouldSign] = useState(true);
    const {templateId} = $router.route.params;

    async function loadTemplate() {
        setLoading(true)
        try {
            const {data: loadedTemplate} = await httpClient.get(`/templates/${templateId}`);

            loadedTemplate.system_entities = updateLocalEntities(loadedTemplate, props.$store.userSession, template == null)
            loadedTemplate.body = addSpanTagsToBody(loadedTemplate, loadedTemplate.body)
            loadedTemplate.body = addValueToSpanTags(loadedTemplate, loadedTemplate.body)
            setTemplate(loadedTemplate)
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            setLoading(false)
        }
    }

    async function saveContract(
        shouldSign: boolean | null = true
    ) {
        setContractForm({
            ...contractForm,
            $disabled: true,
        });

        try {
            const contractName = template.name

            let body = template.body

            if (!shouldSign) {
                template.system_entities = updateEntityInList(template.system_entities, 'Исполнитель', '')
            }

            body = clearFormattingForEntities(body)
            body = addValueToSpanTags(template, body)

            // Validate input data
            const validationErrors = validateMany([
                {
                    rule: "contractName",
                    entity: contractName,
                    displayFieldText: "Название шаблона",
                },
            ]);
            if (validationErrors.length) throw new Error(validationErrors.join("\n"));

            validateContractBeforeSave(template, shouldSign)

            trackGoal('new_contract')

            const {data: contract} = await httpClient.post("/contract", {
                entities: template.entities,
                system_entities: template.system_entities,
                template_id: template._id,
                body: body,
                name: contractName,
                should_sign: shouldSign ?? true,
                actual_user_card_id: template.actual_user_card_id
            }, {
                maxContentLength: 10000000,
                maxBodyLength: 10000000,
            })

            await clearEntities()

            setContract(contract);

            // Notify user
            notifyUser(props, "Документ сохранён");

            setTimeout(
                () => {
                    httpClient.get(`/get-balance`).then(data => {
                        props.$commitToStore({
                            balance: data.data.balance
                        })
                    })
                },
                300
            )
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            // Allow sending form again
            setContractForm({
                ...contractForm,
                $disabled: false,
            });
        }
    }

    function updateFormulas() {
        for (const entity of template.entities) {
            if (entity.type == "Формула") {
                updateFormula(entity.keyword)
            }
        }
        setNeedUpdateFormulas(false)
    }

    function updateFormula(keyword: string) {
        let entities = [...template.entities]
        const entityIndex = template.entities.findIndex(
            (e: any) => e.keyword === keyword
        );
        let entity = entities[entityIndex]
        entities[entityIndex].value = calculateFormula(template, entity)
        entities[entityIndex].postfix = getPostfixForEntity(entity)
        setTemplate({...template, entities})
        updateEntityInEditor(entity, entities)
    }

    async function updateTemplate(asNew: boolean, saveAsName: string = "") {
        // Fetch data from templateForm
        let {body} = template;

        if (body.length == 0) {
            return
        }

        // console.log("saving")
        setIsChanging("saving")

        try {
            // Checks
            if (!body) {
                throw new Error("Документ не может быть пустым");
            }

            body = addSpanTagsToBody(template, body)
            body = addValueToSpanTags(template, body)

            if (asNew) {
                if (saveAsName.length > 0) {
                    template.name = saveAsName;
                }

                template.entities.forEach((entity: any, i: number) => {
                    if (entity.type == "Фото") {
                        template.entities[i].value = ""
                        body = clearValueInSpanTags(body, entity)
                    }
                })
                template.body = body

                const response = await httpClient.post(`/template/${template._id}`, {
                    template,
                });
                const newTemplate = response.data;

                setTemplate(newTemplate)

                // Notify user
                notifyUser(props, "Документ сохранен как новый");

                $router.router.navigate(
                    "new-contract",
                    {act: "new", templateId: newTemplate._id},
                    {reload: true},
                );
            } else {
                let entities = [...template.entities]
                entities = entities.map((entity: any) => {
                    if (entity.type == "Фото") {
                        body = clearValueInSpanTags(body, entity)
                        return {...entity, value: ""}
                    } else {
                        return entity
                    }
                })

                // console.log("Updating body", body)
                // console.log("Updating entities", template.system_entities)
                await httpClient.put(`/template/${template._id}`, {
                    body: body,
                    name: template.name,
                    comment: template.comment,
                    entities: JSON.stringify(entities),
                    system_entities: JSON.stringify(template.system_entities),
                    guides: template.guides,
                    settings: JSON.stringify(template.settings),
                    actual_user_card_id: template.actual_user_card_id
                })
            }
        } catch (error) {
            notifyAboutError(props, error)
        } finally {
            // Allow sending form again
            setIsChanging('saved')
        }
    }

    function _updateEntity(isSystem: boolean, entity: any) {
        let updatedTemplate = updateEntity(template, isSystem, entity)
        setTemplate(updatedTemplate)
        setIsChanging('debouncing')
    }

    function _addEntities(isSystem: boolean, entities: any[]) {
        let updatedTemplate = addEntities(template, isSystem, entities)
        setTemplate(updatedTemplate)
        setIsChanging('debouncing')

        if (!isSystem && entities.length == 1) {
            navigator.clipboard.writeText('{{' + entities[0].keyword + '}}')
                .then(() => {
                    notifyUser(props, 'Метка поля скопирована', 'Теперь вставьте метку в текст договора')
                })
        }
    }

    function _deleteEntities(isSystem: boolean, keywords: string[]) {
        let updatedTemplate = deleteEntities(template, isSystem, keywords)
        setTemplate(updatedTemplate)
        setIsChanging('debouncing')
    }

    function _renameEntity(oldKeyword: string, newKeyword: string) {
        let updatedTemplate = renameEntity(template, oldKeyword, newKeyword)
        setTemplate(updatedTemplate)
        setIsChanging('debouncing')
    }

    function _setOrderForEntity(keyword: string, order: number) {
        let updatedTemplate = setOrderForEntity(template, keyword, order)
        setTemplate(updatedTemplate)
        setNeedUpdateOrder(null)
    }

    // Load template
    useEffect(() => {
        //console.log("useEffect loadTemplate")
        loadTemplate()
    }, [props.$store.userSession])

    useEffect(() => {
        updateLocalEntities(template, props.$store.userSession)
    }, [template?.actual_user_card_id])

    useEffect(() => {
        if (template && needUpdateFormulas) {
            updateFormulas()
        }
        if (template && needUpdateOrder) {
            _setOrderForEntity(needUpdateOrder.keyword, needUpdateOrder.order)
        }
    }, [template?.entities])

    const [scrolledFrom, setScrolledFrom] = useState<string | null>(null)
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);
        if (scrolledFrom) {
            window.removeEventListener('scroll', onScroll);
            window.addEventListener('scroll', onScroll, { passive: true });
            return () => window.removeEventListener('scroll', onScroll);
        } else {
            window.removeEventListener('scroll', onScroll)
        }
    }, [scrolledFrom]);

    useEffect(() => {
        if (scrolledFrom && Math.abs(offset - startOffset) > 500) {
            setScrolledFrom(null)
        }
    }, [offset])

    // Rerender function
    function getBody() {
        return template.body ?? "";
    }

    function errorMessage(): string | null {
        return null
        // Временно отключаю плашку ошибки, кажется она бесполезна
        // let missingEntities = missingEntitiesInBodyError()
        // if (missingEntities) {
        //     return missingEntities
        // }
        //
        // return emptyEntitiesError(template, props.$store)
    }

    async function clearEntities() {
        setTemplate(clearedEntitiesTemplate(template))
        await updateTemplate(false)
    }

    async function makeMassTemplate(data: any) {
        try {
            const {makeMassTemplate} = data;

            await httpClient.put(`/templates/${templateId}/mass`, data);

            props.$commitToStore({
                notification: {
                    title: makeMassTemplate ? "Шаблон готов к массовому подписанию" : "Массовое подписание остановлено",
                    content: null
                }
            })
            location.reload()
        } catch (error) {
            const $error = nError(error);
            console.log({error})
            props.$commitToStore({
                notification: $error.notification,
            });
        }
    }

    function toggleGuideExpanded(guide: any) {
        let guides = template.guides
        for (let g of guides) {
            if (g == guide) {
                g.closed = !g.closed
            }
        }

        setTemplate({
            ...template,
            guides: guides
        })
        setIsChanging('debouncing')
    }

    // Render
    return (
        <div className="NewContractPage_actNew">
            {
                loading && !template &&
                <PageLoader />
            }

            {
                template &&
                <div>
                    <div>
                        <ContractHeader
                            prevRoute={$router.previousRoute}
                            template={template}
                            isChanging={isChanging}
                            saveDisabled={contractForm.$disabled}
                            saveContract={(shouldSign: boolean | null) => {
                                setShouldSign(shouldSign ?? true)
                                saveContract(shouldSign)
                            }}
                            saveNewName={(newName: string) => {
                                setTemplate({...template, name: newName})
                                setIsChanging("debouncing")
                            }}
                            saveNewComment={(newComment: string) => {
                                setTemplate({...template, comment: newComment})
                                setIsChanging("debouncing")
                            }}
                            saveTemplateAsNew={(newName: string) => {
                                updateTemplate(true, newName)
                            }}
                            clear={clearEntities}
                            makeMassTemplate={makeMassTemplate}
                            errorMessage={errorMessage()}
                            updateTemplateSettings={(settings: any) => {
                                let shouldReload = settings.first_signer_title != template.first_signer_title ||
                                    settings.target_user_title != template.target_user_title
                                setTemplate(
                                    {
                                        ...template,
                                        instructions: settings.instructions,
                                        first_signer_title: settings.first_signer_title,
                                        target_user_title: settings.target_user_title,
                                        need_confirmation: settings.need_confirmation,

                                    }
                                )
                                if (shouldReload) {
                                    setTimeout(loadTemplate, 500)
                                }
                            }}
                            reloadTemplate={async () => {
                                await loadTemplate()
                            }}
                            updateTemplate={async () => {
                                await updateTemplate(false)
                            }}
                            $store={props.$store}
                        />
                    </div>
                    <div className="editContractConstructor">
                        <section>
                            <div id="editor">
                                <Editor
                                    readonly={false}
                                    body={getBody()}
                                    onBlur={(newValue) => {
                                        for (const entity of template.entities) {
                                            updateEntityInEditor(entity, template.entities)
                                        }
                                        for (const entity of template.system_entities) {
                                            updateEntityInEditor(entity)
                                        }
                                    }}
                                    onChange={(value) => {
                                        let updatedTemplate = checkChangesInBody(props, template)
                                        let newBody = addSpanTagsToBody(updatedTemplate, value)
                                        setTemplate({
                                            ...updatedTemplate,
                                            body: newBody
                                        })

                                        setIsChanging('debouncing')
                                    }}
                                />
                            </div>
                            <div className="editContractFooterContainer">
                                <ContractFooter
                                    template={template}
                                    showTip={true}
                                    $store={props.$store}
                                    generateStamp={true}
                                />
                            </div>
                        </section>

                        <aside>
                            <div>
                                <Entities
                                    contract={contract}
                                    template={template}
                                    updateEntity={(isSystem: boolean, entity: any) => {
                                        setNeedUpdateFormulas(true)
                                        _updateEntity(isSystem, entity)
                                    }}
                                    addEntities={(isSystem: boolean, entities: any[]) => {
                                        setNeedUpdateFormulas(true)
                                        _addEntities(isSystem, entities)
                                    }}
                                    deleteEntities={(isSystem: boolean, keywords: string[]) => {
                                        setNeedUpdateFormulas(true)
                                        _deleteEntities(isSystem, keywords)
                                    }}
                                    renameEntity={_renameEntity}
                                    setOrderForEntity={(keyword: string, order: number) => {
                                        setNeedUpdateOrder({keyword: keyword, order: order})
                                    }}
                                    didTapScroll={(keyword: string) => {
                                        setTimeout(
                                            () => {
                                                setScrolledFrom(keyword)
                                                setStartOffset(window.scrollY)
                                                setOffset(window.scrollY)
                                            },
                                            1000
                                        )
                                    }}
                                    toggleGuideExpanded={toggleGuideExpanded}
                                    updateActualUserCardId={(actual_user_card_id: string | null) => {
                                        setTemplate({...template, actual_user_card_id: actual_user_card_id})
                                        setIsChanging('debouncing')
                                    }}
                                    updateContractSettings={(settings: any) => {
                                        setTemplate({...template, settings})
                                        setIsChanging('debouncing')
                                    }}
                                    $store={props.$store}
                                />
                            </div>
                        </aside>
                    </div>
                </div>
            }

            {
                scrolledFrom &&
                <div
                    className="scrollView"
                    onClick={() => {
                        let kw = `entityView.${scrolledFrom}`;
                        scrollToElement(kw)
                        setScrolledFrom(null)
                    }}
                >
                    <div>
                        Вернуться к редактированию Поля
                    </div>
                    <ArrowUp className="smallIcon" style={{fill: "white"}}/>
                </div>
            }
            {
                contract &&
                <NewContractPopup
                    contractId={contract._id}
                    shouldSign={shouldSign}
                    settings={template.settings}
                    templateId={template._id}
                    clientPhoneNumber={systemEntityValueByKeyword(contract, clientPhoneNumber.keyword) ?? ""}
                    close={() => {
                        $router.router.navigate("new-contract", {act: "list"}, {reload: true})
                    }}
                />
            }
            {
                <Backdrop
                    sx={{ backgroundColor: 'rgba(0,0,0,0.2)', zIndex: 999 }}
                    open={contractForm.$disabled}
                >
                    <CircularProgress sx={{color: "#1FA3EE"}} />
                </Backdrop>
            }
        </div>
    );
};

EditContractPage = connect((store) => ({ $store: store }), (dispatch) => ({ $commitToStore: (data: any) => dispatch({ ...data, type: 'S' }) }))(EditContractPage)

export default EditContractPage
